import { message } from 'antd';
import axios, { AxiosResponse } from 'axios';
import {
  all,
  call,
  fork,
  put,
  StrictEffect,
  takeLatest,
} from 'redux-saga/effects';

import {
  fetchVisualizationDataFailure,
  fetchVisualizationDataSuccess,
} from './visualizationAction';
import { fetchVisualizationDataApi } from './visualizationApi';
import {
  FETCH_VISUALIZATION_DATA,
  FetchVisualizationDataProps,
} from './visualizationType';

const fetchAsync = async (data:FetchVisualizationDataProps) => await fetchVisualizationDataApi(data);

function* fetch(params: any) {
    const { payload } = params;

    try {
        const response: AxiosResponse = yield call(
            fetchAsync,
            payload

        );
        if (response) {
            
            yield put(fetchVisualizationDataSuccess(response.data));
        }

    } catch (error) {
        if (axios.isAxiosError(error)) {
            const messages = error?.response?.data?.data?.message ?? "Something went wrong";
            yield put(fetchVisualizationDataFailure());
            message.error(messages);
        } else {
            yield put(fetchVisualizationDataFailure());

        }
    }
}

function* watchFetchVisualization() {
    yield takeLatest(FETCH_VISUALIZATION_DATA, fetch);
}

export default function* TradingVisualizationSaga(): Generator<StrictEffect> {
    yield all([fork(watchFetchVisualization)]);

}