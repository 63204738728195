import { Auth } from 'aws-amplify';
import axios from 'axios';

import config from '../../../Config/config';
import {
  FetchTradingAccListProps,
  FetchTradingSingleTransactionListProps,
  UpdateTradingSingle,
} from './TradingAccountTypes';

axios.defaults.baseURL = `${config.dpApi}`;

const getToken = async () => {
    return (await Auth.currentSession()).getIdToken().getJwtToken();
};

export const uploadTradingAccFileApi = async (data: any) => {
    return axios.post(`trading/client/file/upload`, data, {
        headers: {
            Authorization: `Bearer ${await getToken()}`,
        },
    });
}

export const fetchTradingAccListApi = async (data: FetchTradingAccListProps) => {
    return axios.get(`trading/client/list`, {
        params: data,
        headers: {
            Authorization: `Bearer ${await getToken()}`,
        },
    });
}

export const fetchTradingAccSingleApi = async (id: string) => {
    return axios.get(`trading/client/detail/${id}`, {
        headers: {
            Authorization: `Bearer ${await getToken()}`,
        },
    });
}

export const updateTradingAccSingleApi = async ({ id, data }: { id: string, data: UpdateTradingSingle }) => {
    return axios.patch(`trading/client/tag/update/${id}`, data, {
        headers: {
            Authorization: `Bearer ${await getToken()}`,
        },
    });
}

export const fetchTradingAccSingleTransactionApi = async (data: FetchTradingSingleTransactionListProps) => {
    return axios.get(`trading/transactions/by/client/${data.client_code}`, {
        params: data.filterData,
        headers: {
            Authorization: `Bearer ${await getToken()}`,
        },
    });
}