import { Reducer } from 'react';

import {
  FETCH_FLOORSHEET_TRANSACTION_LIST,
  FETCH_FLOORSHEET_TRANSACTION_LIST_FAILURE,
  FETCH_FLOORSHEET_TRANSACTION_LIST_SUCCESS,
} from './FloorsheetTransactionTypes';

const INIT_STATE = {
    floorsheetTransactionListLoading: false,
    floorsheetTransactionList: [],
    totalFloorsheetTransactionRecords: 0

};

export const tradingFloorsheetTransaction: Reducer<any, any> = (state = INIT_STATE, action) => {

    switch (action.type) {
        case FETCH_FLOORSHEET_TRANSACTION_LIST:
            return {
                ...state,
                floorsheetTransactionListLoading: true,
                floorsheetTransactionList: [],

            }

        case FETCH_FLOORSHEET_TRANSACTION_LIST_SUCCESS:
            return {
                ...state,
                floorsheetTransactionListLoading: false,
                floorsheetTransactionList: action.payload.results,
                totalFloorsheetTransactionRecords: action.payload.count
            }

        case FETCH_FLOORSHEET_TRANSACTION_LIST_FAILURE:
            return {
                ...state,
                floorsheetTransactionListLoading: false,
                floorsheetTransactionList: [],
                totalFloorsheetTransactionRecords: 0
            }

        default:
            return { ...state };
    }
}