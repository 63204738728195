import { Reducer } from 'react';

import {
  DELETE_FLOORSHEET,
  DELETE_FLOORSHEET_FAILURE,
  DELETE_FLOORSHEET_SUCCESS,
  FETCH_FLOORSHEET_LIST,
  FETCH_FLOORSHEET_LIST_FAILURE,
  FETCH_FLOORSHEET_LIST_SUCCESS,
  RESET_UPLOAD_FLOORSHEET_VAL,
  UPLOAD_FLOORSHEET_FILE,
  UPLOAD_FLOORSHEET_FILE_FAILURE,
  UPLOAD_FLOORSHEET_FILE_SUCCESS,
} from './FloorsheetTypes';

const INIT_STATE = {
    uploadFileLoading: false,
    uploadFileError: false,
    uploadFileSuccess: false,
    uploadFileSuccessData: {},
    floorsheetListLoading: false,
    floorsheetList: [],
    totalFloorsheetListRecords: 0,
    deleteFloorsheetLoading: false,
    deleteFloorsheetSuccess: false

};


export const tradingFloorsheet: Reducer<any, any> = (state = INIT_STATE, action) => {
    switch (action.type) {
        case UPLOAD_FLOORSHEET_FILE:
            return {
                ...state,
                uploadFileLoading: true,
                uploadFileError: false,
                uploadFileSuccess: false,


            }
        case UPLOAD_FLOORSHEET_FILE_SUCCESS:
            return {
                ...state,
                uploadFileLoading: false,
                uploadFileError: false,
                uploadFileSuccess: true,
                uploadFileSuccessData: action.payload

            }

        case UPLOAD_FLOORSHEET_FILE_FAILURE:
            return {
                ...state,
                uploadFileLoading: false,
                uploadFileError: true,
                uploadFileSuccess: false,

            }

        case RESET_UPLOAD_FLOORSHEET_VAL:
            return {
                ...state,
                uploadFileLoading: false,
                uploadFileError: false,
                uploadFileSuccess: false,
            }

        case FETCH_FLOORSHEET_LIST:
            return {
                ...state,
                floorsheetListLoading: true,
                floorsheetList: [],
            }

        case FETCH_FLOORSHEET_LIST_SUCCESS:
            return {
                ...state,
                floorsheetListLoading: false,
                floorsheetList: action.payload.results,
                totalFloorsheetListRecords: action.payload.count

            }

        case FETCH_FLOORSHEET_LIST_FAILURE:
            return {
                ...state,
                floorsheetListLoading: false,
                floorsheetList: [],

            }
        case DELETE_FLOORSHEET:
            return {
                ...state,
                deleteFloorsheetLoading: true,
                deleteFloorsheetSuccess: false
            }
        case DELETE_FLOORSHEET_SUCCESS:
            return {
                ...state,
                deleteFloorsheetLoading: false,
                deleteFloorsheetSuccess: true
            }

        case DELETE_FLOORSHEET_FAILURE:
            return {
                ...state,
                deleteFloorsheetLoading: false,
                deleteFloorsheetSuccess: false

            }

        default:
            return { ...state };
    }
}