import { Reducer } from 'react';

import {
  CREATE_ALERT,
  CREATE_ALERT_FAILURE,
  CREATE_ALERT_SUCCESS,
  DELETE_ALERT,
  DELETE_ALERT_FAILURE,
  DELETE_ALERT_SUCCESS,
  FETCH_ALERT_DETAIL,
  FETCH_ALERT_DETAILS_FAILURE,
  FETCH_ALERT_DETAILS_SUCCESS,
  FETCH_ALERT_LIST,
  FETCH_ALERT_LIST_FAILURE,
  FETCH_ALERT_LIST_SUCCESS,
  FETCH_NEPSE_LIST,
  FETCH_NEPSE_LIST_FAILURE,
  FETCH_NEPSE_LIST_SUCCESS,
  FETCH_NEPSE_TICKER_DATA,
  FETCH_NEPSE_TICKER_DATA_FAILURE,
  FETCH_NEPSE_TICKER_DATA_SUCCESS,
  UPDATE_ALERT,
  UPDATE_ALERT_FAILURE,
  UPDATE_ALERT_SUCCESS,
} from './alertTypes';

const INIT_STATE = {
    nepseLoading: false,
    nepseList: [],
    tickerDataLoading: false,
    tickerData: {},
    alertListLoading: false,
    alertList: [],
    totalAlertListRecords: 0,
    success: false,
    loading: false,
    alertDetail: {},
    alertDetailLoading: false

};

export const tradingAlert: Reducer<any, any> = (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_NEPSE_LIST:
            return {
                ...state,
                nepseLoading: true,
                nepseList: []
            }
        case FETCH_NEPSE_LIST_SUCCESS:
            return {
                ...state,
                nepseLoading: false,
                nepseList: action.payload
            }
        case FETCH_NEPSE_LIST_FAILURE:
            return {
                ...state,
                nepseLoading: false,
                nepseList: []
            }

        case FETCH_NEPSE_TICKER_DATA:
            return {
                ...state,
                tickerDataLoading: true,
                tickerData: {}
            }

        case FETCH_NEPSE_TICKER_DATA_SUCCESS:
            return {
                ...state,
                tickerDataLoading: false,
                tickerData: action.payload
            }
        case FETCH_NEPSE_TICKER_DATA_FAILURE:
            return {
                ...state,
                tickerDataLoading: false,
                tickerData: {}
            }

        case FETCH_ALERT_LIST:
            return {
                ...state,
                alertListLoading: true,
                alertList: [],
                totalAlertListRecords: 0,
                success: false

            }

        case FETCH_ALERT_LIST_SUCCESS:
            return {
                ...state,
                alertListLoading: false,
                alertList: action.payload.results,
                totalAlertListRecords: action.payload.count,
            }
        case FETCH_ALERT_LIST_FAILURE:
            return {
                ...state,
                alertListLoading: false,
                alertList: [],
                totalAlertListRecords: 0,
            }
        case CREATE_ALERT:
        case DELETE_ALERT:
        case UPDATE_ALERT:
            return {
                ...state,
                success: false,
                loading: true
            }
        case CREATE_ALERT_SUCCESS:
        case DELETE_ALERT_SUCCESS:
        case UPDATE_ALERT_SUCCESS:
            return {
                ...state,
                success: true,
                loading: false

            }
        case CREATE_ALERT_FAILURE:
        case DELETE_ALERT_FAILURE:
        case UPDATE_ALERT_FAILURE:
            return {
                ...state,
                success: false,
                loading: false

            }

        case FETCH_ALERT_DETAIL:
            return {
                ...state,
                alertDetail: {},
                alertDetailLoading: true
            }
        case FETCH_ALERT_DETAILS_SUCCESS:
            return {
                ...state,
                alertDetail: action.payload,
                alertDetailLoading: false
            }
        case FETCH_ALERT_DETAILS_FAILURE:
            return {
                ...state,
                alertDetail: {},
                alertDetailLoading: false
            }
        default:
            return { ...state };

    }
}