import React from 'react';

import { useSelector } from 'react-redux';

import { LineChart } from './LineChart';

interface QuantityProps {
    showExportBtn: boolean
}

export const Quantity = ({ showExportBtn }: QuantityProps) => {

    const {
        visualizationData

    } = useSelector((state: any) => state.tradingVisualizationReducer);



    return (
        <div>
            <LineChart showExportBtn={showExportBtn} visualizationData={visualizationData?.transaction_quantity} title='QTY' height={180} />

        </div>
    )
}
