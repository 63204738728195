import { message } from 'antd';
import axios, { AxiosResponse } from 'axios';
import {
  all,
  call,
  fork,
  put,
  StrictEffect,
  takeLatest,
} from 'redux-saga/effects';

import {
  createTagsFailure,
  createTagsSuccess,
  deleteTagsFailure,
  deleteTagsSuccess,
  fetchTagsListFailure,
  fetchTagsListSuccess,
  updateTagsFailure,
  updateTagsSuccess,
} from './TagsAction';
import {
  createTagsApi,
  deleteTagsApi,
  fetchTagsListApi,
  updateTagsApi,
} from './TagsApi';
import {
  CREATE_TAGS,
  CreateTagsProps,
  DELETE_TAGS,
  FETCH_TAGS_LIST,
  FetchTagsListProps,
  UPDATE_TAGS,
} from './TagsType';

const fetchTagsListAsync = async (data: FetchTagsListProps) => await fetchTagsListApi(data);

function* fetchTagsList(params: any) {
    const { payload } = params

    try {
        const response: AxiosResponse = yield call(
            fetchTagsListAsync,
            payload

        );
        if (response) {

            yield put(fetchTagsListSuccess(response.data.data));
        }

    } catch (error) {
        if (axios.isAxiosError(error)) {
            const messages = error?.response?.data?.error ?? "Something went wrong";
            yield put(fetchTagsListFailure());
            message.error(messages);
        } else {
            yield put(fetchTagsListFailure());

        }
    }
}

function* watchFetchTagsList() {
    yield takeLatest(FETCH_TAGS_LIST, fetchTagsList);
}


const deleteTagsAsync = async (id: string) => await deleteTagsApi(id);

function* deleteTags(params: any) {
    const { payload } = params;

    try {
        const response: AxiosResponse = yield call(
            deleteTagsAsync,
            payload
        );
        if (response) {
            message.success(response.data.message)
            yield put(deleteTagsSuccess());
        }

    } catch (error) {
        if (axios.isAxiosError(error)) {
            const messages = error?.response?.data?.error ?? "Something went wrong";
            yield put(deleteTagsFailure());
            message.error(messages);
        } else {
            yield put(deleteTagsFailure());

        }


    }
}

function* watchDeleteTags() {
    yield takeLatest(DELETE_TAGS, deleteTags);
}


const createTagsAsync = async (data: CreateTagsProps) => await createTagsApi(data);

function* createTags(params: any) {
    const { payload } = params;

    try {
        const response: AxiosResponse = yield call(
            createTagsAsync,
            payload
        );
        if (response) {
            message.success(response.data.message)
            yield put(createTagsSuccess());
        }

    } catch (error) {
        if (axios.isAxiosError(error)) {
            const messages = error?.response?.data?.error ?? "Something went wrong";
            yield put(createTagsFailure());
            message.error(messages);
        } else {
            yield put(createTagsFailure());

        }


    }
}

function* watchCreateAlert() {
    yield takeLatest(CREATE_TAGS, createTags);
}

const updateTagsAsync = async ({ id, data }: { id: string, data: CreateTagsProps }) => await updateTagsApi({ id, data });

function* updateTags(params: any) {
    const { payload } = params;

    try {
        const response: AxiosResponse = yield call(
            updateTagsAsync,
            payload
        );
        if (response) {
            message.success(response.data.message)
            yield put(updateTagsSuccess());
        }

    } catch (error) {
        if (axios.isAxiosError(error)) {
            const messages = error?.response?.data?.error ?? "Something went wrong";
            yield put(updateTagsFailure());
            message.error(messages);
        } else {
            yield put(updateTagsFailure());

        }


    }
}

function* watchUpdateTags() {
    yield takeLatest(UPDATE_TAGS, updateTags);
}



export default function* TradingTagsSaga(): Generator<StrictEffect> {

    yield all([fork(watchFetchTagsList)]);
    yield all([fork(watchDeleteTags)]);
    yield all([fork(watchCreateAlert)]);
    yield all([fork(watchUpdateTags)]);

}