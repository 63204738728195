import { Reducer } from 'react';

import {
  FETCH_TRADING_ACC_LIST,
  FETCH_TRADING_ACC_LIST_FAILURE,
  FETCH_TRADING_ACC_LIST_SUCCESS,
  FETCH_TRADING_ACC_SINGLE,
  FETCH_TRADING_ACC_SINGLE_FAILURE,
  FETCH_TRADING_ACC_SINGLE_SUCCESS,
  FETCH_TRADING_ACC_SINGLE_TRANSACTION,
  FETCH_TRADING_ACC_SINGLE_TRANSACTION_FAILURE,
  FETCH_TRADING_ACC_SINGLE_TRANSACTION_SUCCESS,
  RESET_UPLOAD_TRADING_ACC_VAL,
  UPDATE_TRADING_ACC_SINGLE,
  UPDATE_TRADING_ACC_SINGLE_FAILURE,
  UPDATE_TRADING_ACC_SINGLE_SUCCESS,
  UPLOAD_TRADING_ACC_FILE,
  UPLOAD_TRADING_ACC_FILE_FAILURE,
  UPLOAD_TRADING_ACC_FILE_SUCCESS,
} from './TradingAccountTypes';

const INIT_STATE = {
    uploadFileLoading: false,
    uploadFileError: false,
    uploadFileSuccess: false,
    uploadFileSuccessData: {},
    tradingAccListLoading: false,
    tradingAccList: [],
    tradingAccSingleLoading: false,
    tradingAccSingle: {},
    totalTradingAccListRecords: 0,
    tradingSingleTransactionLoading: false,
    tradingSingleTransactionList: [],
    totalTradingSingleTransactionRecords: 0,
    loading: false,
    success: false
};

export const tradingTradingAcc: Reducer<any, any> = (state = INIT_STATE, action) => {
    switch (action.type) {
        case UPLOAD_TRADING_ACC_FILE:
            return {
                ...state,
                uploadFileLoading: true,
                uploadFileError: false,
                uploadFileSuccess: false,


            }
        case UPLOAD_TRADING_ACC_FILE_SUCCESS:
            return {
                ...state,
                uploadFileLoading: false,
                uploadFileError: false,
                uploadFileSuccess: true,
                uploadFileSuccessData: action.payload

            }

        case UPLOAD_TRADING_ACC_FILE_FAILURE:
            return {
                ...state,
                uploadFileLoading: false,
                uploadFileError: true,
                uploadFileSuccess: false,

            }

        case RESET_UPLOAD_TRADING_ACC_VAL:
            return {
                ...state,
                uploadFileLoading: false,
                uploadFileError: false
            }

        case FETCH_TRADING_ACC_LIST:
            return {
                ...state,
                tradingAccListLoading: true,
                tradingAccList: []
            }

        case FETCH_TRADING_ACC_LIST_SUCCESS:
            return {
                ...state,
                tradingAccListLoading: false,
                tradingAccList: action.payload.results,
                totalTradingAccListRecords: action.payload.count
            }

        case FETCH_TRADING_ACC_LIST_FAILURE:
            return {
                ...state,
                tradingAccListLoading: false,
                tradingAccList: []
            }

        case FETCH_TRADING_ACC_SINGLE:
            return {
                ...state,
                tradingAccSingleLoading: true,
                tradingAccSingle: {},
                success: false
            }

        case FETCH_TRADING_ACC_SINGLE_SUCCESS:
            return {
                ...state,
                tradingAccSingleLoading: false,
                tradingAccSingle: action.payload
            }

        case FETCH_TRADING_ACC_SINGLE_FAILURE:
            return {
                ...state,
                tradingAccSingleLoading: false,
                tradingAccSingle: {}
            }

        case FETCH_TRADING_ACC_SINGLE_TRANSACTION:
            return {
                ...state,
                tradingSingleTransactionLoading: true,
                tradingSingleTransactionList: [],
                totalTradingSingleTransactionRecords: 0
            }

        case FETCH_TRADING_ACC_SINGLE_TRANSACTION_SUCCESS:
            return {
                ...state,
                tradingSingleTransactionLoading: false,
                tradingSingleTransactionList: action.payload.results,
                totalTradingSingleTransactionRecords: action.payload.count
            }

        case FETCH_TRADING_ACC_SINGLE_TRANSACTION_FAILURE:
            return {
                ...state,
                tradingSingleTransactionLoading: false,
                tradingSingleTransactionList: [],
                totalTradingSingleTransactionRecords: 0
            }
        case UPDATE_TRADING_ACC_SINGLE:
            return {
                ...state,
                loading: true,
                success: false
            }
        case UPDATE_TRADING_ACC_SINGLE_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true
            }
        case UPDATE_TRADING_ACC_SINGLE_FAILURE:
            return {
                ...state,
                loading: false,
                success: false
            }

        default:
            return { ...state };
    }
}