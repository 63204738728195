import React from 'react';

import { useSelector } from 'react-redux';

import { LineChart } from './LineChart';

interface TurnOverProps {
    showExportBtn: boolean
}

export const TurnOver = ({ showExportBtn }: TurnOverProps) => {
    const {
        visualizationData

    } = useSelector((state: any) => state.tradingVisualizationReducer);

    return (
        <LineChart showExportBtn={showExportBtn} visualizationData={visualizationData?.turnover_data} title='Turnover' height={180} />

    )
}
