import {
  CREATE_TAGS,
  CREATE_TAGS_FAILURE,
  CREATE_TAGS_SUCCESS,
  CreateTagsProps,
  DELETE_TAGS,
  DELETE_TAGS_FAILURE,
  DELETE_TAGS_SUCCESS,
  FETCH_TAGS_LIST,
  FETCH_TAGS_LIST_FAILURE,
  FETCH_TAGS_LIST_SUCCESS,
  FetchTagsListProps,
  UPDATE_TAGS,
  UPDATE_TAGS_FAILURE,
  UPDATE_TAGS_SUCCESS,
} from './TagsType';

export const fetchTagsList = (data: FetchTagsListProps) => ({
    type: FETCH_TAGS_LIST,
    payload: data
})

export const fetchTagsListSuccess = (data: any) => ({
    type: FETCH_TAGS_LIST_SUCCESS,
    payload: data
})

export const fetchTagsListFailure = () => ({
    type: FETCH_TAGS_LIST_FAILURE
})

export const createTags = (data: CreateTagsProps) => ({
    type: CREATE_TAGS,
    payload: data
})

export const createTagsSuccess = () => ({
    type: CREATE_TAGS_SUCCESS,
})

export const createTagsFailure = () => ({
    type: CREATE_TAGS_FAILURE
})

export const deleteTags = (id: string) => ({
    type: DELETE_TAGS,
    payload: id
})

export const deleteTagsSuccess = () => ({
    type: DELETE_TAGS_SUCCESS
})

export const deleteTagsFailure = () => ({
    type: DELETE_TAGS_FAILURE
})

export const updateTags = ({ id, data }: { id: string, data: CreateTagsProps }) => ({
    type: UPDATE_TAGS,
    payload: { id, data }
})

export const updateTagsSuccess = () => ({
    type: UPDATE_TAGS_SUCCESS
})

export const updateTagsFailure = () => ({
    type: UPDATE_TAGS_FAILURE
})