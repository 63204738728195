import { message } from 'antd';
import axios, { AxiosResponse } from 'axios';
import {
  all,
  call,
  fork,
  put,
  StrictEffect,
  takeLatest,
} from 'redux-saga/effects';

import {
  createAlertFailure,
  createAlertSuccess,
  deleteAlertFailure,
  deleteAlertSuccess,
  fetchAlertDetailFailure,
  fetchAlertDetailSuccess,
  fetchAlertListFailure,
  fetchAlertListSuccess,
  fetchNepseListFailure,
  fetchNepseListSuccess,
  fetchNepseTickerDataFailure,
  fetchNepseTickerDataSuccess,
  updateAlertFailure,
  updateAlertSuccess,
} from './alertAction';
import {
  createAlertApi,
  deleteAlertApi,
  fetchAlertDetailApi,
  fetchAlertListApi,
  fetchNepseListApi,
  fetchNepseTickerDataApi,
  updateAlertApi,
} from './alertApi';
import {
  AlertCreateProps,
  CREATE_ALERT,
  DELETE_ALERT,
  FETCH_ALERT_DETAIL,
  FETCH_ALERT_LIST,
  FETCH_NEPSE_LIST,
  FETCH_NEPSE_TICKER_DATA,
  FetchAlertListProps,
  UPDATE_ALERT,
} from './alertTypes';

const fetchNepseListAsync = async () => await fetchNepseListApi();

function* fetchNepseList(params: any) {
    // const { payload } = params;

    try {
        const response: AxiosResponse = yield call(
            fetchNepseListAsync,

        );
        if (response) {

            yield put(fetchNepseListSuccess(response.data.response));
        }

    } catch (error) {
        if (axios.isAxiosError(error)) {
            const messages = error?.response?.data?.data?.message ?? "Something went wrong";
            yield put(fetchNepseListFailure());
            message.error(messages);
        } else {
            yield put(fetchNepseListFailure());

        }
    }
}

function* watchFetchNepseList() {
    yield takeLatest(FETCH_NEPSE_LIST, fetchNepseList);
}

const fetchNepseTickerDataAsync = async (symbol: string) => await fetchNepseTickerDataApi(symbol);

function* fetchNepseTickerData(params: any) {
    const { payload } = params;

    try {
        const response: AxiosResponse = yield call(
            fetchNepseTickerDataAsync,
            payload

        );
        if (response) {

            yield put(fetchNepseTickerDataSuccess(response.data.response));
        }

    } catch (error) {
        if (axios.isAxiosError(error)) {
            const messages = error?.response?.data?.data?.message ?? "Something went wrong";
            yield put(fetchNepseTickerDataFailure());
            message.error(messages);
        } else {
            yield put(fetchNepseTickerDataFailure());

        }
    }
}

function* watchFetchNepseTickerData() {
    yield takeLatest(FETCH_NEPSE_TICKER_DATA, fetchNepseTickerData);
}


const fetchAlertListAsync = async (data: FetchAlertListProps) => await fetchAlertListApi(data);

function* fetchAlertList(params: any) {
    const { payload } = params

    try {
        const response: AxiosResponse = yield call(
            fetchAlertListAsync,
            payload

        );
        if (response) {

            yield put(fetchAlertListSuccess(response.data.data));
        }

    } catch (error) {
        if (axios.isAxiosError(error)) {
            const messages = error?.response?.data?.error ?? "Something went wrong";
            yield put(fetchAlertListFailure());
            message.error(messages);
        } else {
            yield put(fetchAlertListFailure());

        }
    }
}

function* watchFetchAlertList() {
    yield takeLatest(FETCH_ALERT_LIST, fetchAlertList);
}

const fetchAlertDetailAsync = async (data: string) => await fetchAlertDetailApi(data);

function* fetchAlertDetail(params: any) {
    const { payload } = params

    try {
        const response: AxiosResponse = yield call(
            fetchAlertDetailAsync,
            payload

        );
        if (response) {

            yield put(fetchAlertDetailSuccess(response.data.data));
        }

    } catch (error) {
        if (axios.isAxiosError(error)) {
            const messages = error?.response?.data?.error ?? "Something went wrong";
            yield put(fetchAlertDetailFailure());
            message.error(messages);
        } else {
            yield put(fetchAlertDetailFailure());

        }
    }
}

function* watchFetchAlertDetail() {
    yield takeLatest(FETCH_ALERT_DETAIL, fetchAlertDetail);
}





const deleteAlertAsync = async (id: string) => await deleteAlertApi(id);

function* deleteAlert(params: any) {
    const { payload } = params;

    try {
        const response: AxiosResponse = yield call(
            deleteAlertAsync,
            payload
        );
        if (response) {
            message.success(response.data.message)
            yield put(deleteAlertSuccess());
        }

    } catch (error) {
        if (axios.isAxiosError(error)) {
            const messages = error?.response?.data?.error ?? "Something went wrong";
            yield put(deleteAlertFailure());
            message.error(messages);
        } else {
            yield put(deleteAlertFailure());

        }


    }
}

function* watchDeleteAlert() {
    yield takeLatest(DELETE_ALERT, deleteAlert);
}

const createAlertAsync = async (data: AlertCreateProps) => await createAlertApi(data);

function* createAlert(params: any) {
    const { payload } = params;

    try {
        const response: AxiosResponse = yield call(
            createAlertAsync,
            payload
        );
        if (response) {
            message.success(response.data.message)
            yield put(createAlertSuccess());
        }

    } catch (error) {
        if (axios.isAxiosError(error)) {
            const messages = error?.response?.data?.error ?? "Something went wrong";
            yield put(createAlertFailure());
            message.error(messages);
        } else {
            yield put(createAlertFailure());

        }


    }
}

function* watchCreateAlert() {
    yield takeLatest(CREATE_ALERT, createAlert);
}


const updateAlertAsync = async ({ id, data }: { id: string, data: AlertCreateProps }) => await updateAlertApi({ id, data });

function* updateAlert(params: any) {
    const { payload } = params;

    try {
        const response: AxiosResponse = yield call(
            updateAlertAsync,
            payload
        );
        if (response) {
            message.success(response.data.message)
            yield put(updateAlertSuccess());
        }

    } catch (error) {
        if (axios.isAxiosError(error)) {
            const messages = error?.response?.data?.error ?? "Something went wrong";
            yield put(updateAlertFailure());
            message.error(messages);
        } else {
            yield put(updateAlertFailure());

        }


    }
}

function* watchUpdateAlert() {
    yield takeLatest(UPDATE_ALERT, updateAlert);
}



export default function* TradingAlertSaga(): Generator<StrictEffect> {
    yield all([fork(watchFetchNepseList)]);
    yield all([fork(watchFetchNepseTickerData)]);
    yield all([fork(watchFetchAlertList)]);
    yield all([fork(watchDeleteAlert)]);
    yield all([fork(watchCreateAlert)]);
    yield all([fork(watchFetchAlertDetail)]);
    yield all([fork(watchUpdateAlert)]);
}