import {
  CREATE_GROUP,
  CREATE_GROUP_FAILURE,
  CREATE_GROUP_SUCCESS,
  CreateGroupProps,
  DELETE_GROUP,
  DELETE_GROUP_FAILURE,
  DELETE_GROUP_SUCCESS,
  FETCH_GROUP__DETAIL_LIST_FAILURE,
  FETCH_GROUP_DETAIL,
  FETCH_GROUP_DETAIL_FAILURE,
  FETCH_GROUP_DETAIL_LIST,
  FETCH_GROUP_DETAIL_LIST_SUCCESS,
  FETCH_GROUP_DETAIL_SUCCESS,
  FETCH_GROUP_LIST,
  FETCH_GROUP_LIST_FAILURE,
  FETCH_GROUP_LIST_SUCCESS,
  UPDATE_GROUP,
  UPDATE_GROUP_FAILURE,
  UPDATE_GROUP_SUCCESS,
} from './groupTypes';

export const fetchGroupList = (data:any) => ({
    type: FETCH_GROUP_LIST,
    payload: data
})

export const fetchGroupListSuccess = (data: any) => ({
    type: FETCH_GROUP_LIST_SUCCESS,
    payload: data
})

export const fetchGroupListFailure = () => ({
    type: FETCH_GROUP_LIST_FAILURE
})

export const fetchGroupDetailList = (id: string) => ({
    type: FETCH_GROUP_DETAIL_LIST,
    payload: id
})

export const fetchGroupDetailListSuccess = (data: any) => ({
    type: FETCH_GROUP_DETAIL_LIST_SUCCESS,
    payload: data
})

export const fetchGroupDetailListFailure = () => ({
    type: FETCH_GROUP__DETAIL_LIST_FAILURE
})

export const fetchGroupDetail = (id: string) => ({
    type: FETCH_GROUP_DETAIL,
    payload: id
})

export const fetchGroupDetailSuccess = (data: any) => ({
    type: FETCH_GROUP_DETAIL_SUCCESS,
    payload: data
})

export const fetchGroupDetailFailure = () => ({
    type: FETCH_GROUP_DETAIL_FAILURE
})

export const createGroup = (data: CreateGroupProps) => ({
    type: CREATE_GROUP,
    payload: data
})

export const createGroupSuccess = () => ({
    type: CREATE_GROUP_SUCCESS
})

export const createGroupFailure = () => ({
    type: CREATE_GROUP_FAILURE
})

export const updateGroup = (id: string, data: CreateGroupProps) => ({
    type: UPDATE_GROUP,
    payload: { id, data }
})

export const updateGroupSuccess = () => ({
    type: UPDATE_GROUP_SUCCESS
})

export const updateGroupFailure = () => ({
    type: UPDATE_GROUP_FAILURE
})


export const deleteGroup = (id: string) => ({
    type: DELETE_GROUP,
    payload: id
})

export const deleteGroupSuccess = () => ({
    type: DELETE_GROUP_SUCCESS
})

export const deleteGroupFailure = () => ({
    type: DELETE_GROUP_FAILURE
})