import React from 'react';

import {
  Col,
  Row,
  Typography,
} from 'antd';
import { useSelector } from 'react-redux';
import { getRandomColor } from 'Utils/getRandomColor';

import { DoughnutChart } from './DoughnutChart';

interface SectorWiseProps {
    showExportBtn: boolean
}

export const SectorWise = ({ showExportBtn }: SectorWiseProps) => {


    const {
        visualizationData

    } = useSelector((state: any) => state.tradingVisualizationReducer);

    const chartBuyData = visualizationData?.sector_data ? Object.keys(visualizationData?.sector_data).map((key) => {
        return {
            data: Number(visualizationData.sector_data[key]?.buy),
            labels: key,
            color: getRandomColor()

        }
    }) : []


    const chartSellData = visualizationData?.sector_data ? Object.keys(visualizationData?.sector_data).map((key) => {
        return {
            data: Number(visualizationData.sector_data[key]?.sell),
            labels: key,
            color: getRandomColor()

        }
    }) : []


    return (
        <div className='sector-wise'>
            <Typography.Title level={4}>Sector Wise</Typography.Title>
            <Row gutter={[32, 32]} align={"middle"} justify={"center"}>
                <Col md={12}>
                    <DoughnutChart chartData={chartBuyData} showExportBtn={showExportBtn} title='Buy' />
                </Col>
                <Col md={12}>
                    <DoughnutChart chartData={chartSellData} showExportBtn={showExportBtn} title='Sell' />
                </Col>
            </Row>
        </div>
    )
}
