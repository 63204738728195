import { message } from 'antd';
import axios, { AxiosResponse } from 'axios';
import {
  all,
  call,
  fork,
  put,
  StrictEffect,
  takeLatest,
} from 'redux-saga/effects';

import {
  deleteFloorsheetFailure,
  deleteFloorsheetSuccess,
  fetchFloorsheetListFailure,
  fetchFloorsheetListSuccess,
  uploadFloorsheetFileFailure,
  uploadFloorsheetFileSuccess,
} from './FloorsheetAction';
import {
  deleteFloorsheetApi,
  fetchFloorsheetListApi,
  uploadFloorsheetFileApi,
} from './FloorsheetApi';
import {
  DELETE_FLOORSHEET,
  FETCH_FLOORSHEET_LIST,
  FetchFloorsheetListProps,
  UPLOAD_FLOORSHEET_FILE,
} from './FloorsheetTypes';

//upload dpa file

const uploadFileAsync = async (data: any) => await uploadFloorsheetFileApi(data);

function* uploadFloorsheetFile(params: any) {
    const { payload } = params;
    try {
        const response: AxiosResponse = yield call(
            uploadFileAsync,
            payload
        );
        if (response) {
            yield put(uploadFloorsheetFileSuccess(response.data.data));
        }

    } catch (error) {
        if (axios.isAxiosError(error)) {
            const messages = error?.response?.data?.error ?? "Something went wrong";
            yield put(uploadFloorsheetFileFailure());
            message.error(messages);
        } else {
            yield put(uploadFloorsheetFileFailure());

        }


    }
}

function* watchUploadFloorsheetFile() {
    yield takeLatest(UPLOAD_FLOORSHEET_FILE, uploadFloorsheetFile);
}


const fetchFloorsheetListAsync = async (data: FetchFloorsheetListProps) => await fetchFloorsheetListApi(data);

function* fetchFloorsheetList(params: any) {
    const { payload } = params;

    try {
        const response: AxiosResponse = yield call(
            fetchFloorsheetListAsync,
            payload
        );
        if (response) {
            yield put(fetchFloorsheetListSuccess(response.data.data));
        }

    } catch (error) {
        if (axios.isAxiosError(error)) {
            const messages = error?.response?.data?.error ?? "Something went wrong";
            yield put(fetchFloorsheetListFailure());
            message.error(messages);
        } else {
            yield put(fetchFloorsheetListFailure());

        }


    }
}

function* watchFetchFloorsheetList() {
    yield takeLatest(FETCH_FLOORSHEET_LIST, fetchFloorsheetList);
}

const deleteFloorsheetAsync = async (id: string) => await deleteFloorsheetApi(id);

function* deleteFloorsheet(params: any) {
    const { payload } = params;

    try {
        const response: AxiosResponse = yield call(
            deleteFloorsheetAsync,
            payload
        );
        if (response) {
            yield put(deleteFloorsheetSuccess());
        }

    } catch (error) {
        if (axios.isAxiosError(error)) {
            const messages = error?.response?.data?.error ?? "Something went wrong";
            yield put(deleteFloorsheetFailure());
            message.error(messages);
        } else {
            yield put(deleteFloorsheetFailure());

        }


    }
}

function* watchDeleteFloorsheet() {
    yield takeLatest(DELETE_FLOORSHEET, deleteFloorsheet);
}

export default function* TradingFloorsheetSaga(): Generator<StrictEffect> {
    yield all([fork(watchUploadFloorsheetFile)]);
    yield all([fork(watchFetchFloorsheetList)]);
    yield all([fork(watchDeleteFloorsheet)]);
}