import React, {
  useRef,
  useState,
} from 'react';

import {
  Button,
  Col,
  Row,
  Typography,
} from 'antd';
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import jsPDF from 'jspdf';
import { Line } from 'react-chartjs-2';

interface LineChartProps {
    title: string
    height: number
    visualizationData?: {
        date: string,
        buy: string,
        sell: string
    }[]
    showExportBtn:boolean
}

export const LineChart = ({ title, height, visualizationData, showExportBtn }: LineChartProps) => {

    const chartRef = useRef<any>(null);
    const [indexValue, setIndexValue] = useState<number[]>([0, 1])

    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend
    )

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            }
        },
        scales: {
            y: {
                grid: {
                    display: false
                }
            }
        }
    };

    const handleExportPDF = () => {
        const chart = chartRef.current;
        if (chart) {
            const canvas = chart.canvas;
            const pdf = new jsPDF();
            const pdfWidth = pdf.internal.pageSize.width - 20;
            const pdfHeight = (canvas.height * pdfWidth) / canvas.width;
            pdf.text(title, 10, 10);
            pdf.addImage(canvas.toDataURL('image/png'), 'PNG', 10, 20, pdf.internal.pageSize.width - 20, pdfHeight);
            pdf.save('chart.pdf');
        }
    };

    const handleClick = (index: number) => {
        const chart = chartRef.current;
        if (chart) {
            const dataset = chart.data.datasets[index];
            dataset.hidden = !dataset.hidden;

            chart.update();

            setIndexValue((prevVal) => {
                if (prevVal.includes(index)) {
                    return prevVal.filter((i) => i !== index);
                } else {
                    return [...prevVal, index];
                }
            });
        }
    }

    const chartData = [{
        label: 'Buy',
        data: visualizationData?.map(item => item.buy),
        borderColor: '#1CC67B',
        backgroundColor: 'transparent',
        pointBackgroundColor: '#1CC67B',
    },
    {
        label: 'Sell',
        data: visualizationData?.map(item => item.sell),
        borderColor: '#FF895B',
        backgroundColor: 'transparent',
        pointBackgroundColor: '#FF895B',
    }
    ]

    return (
        <div>
            <Row gutter={[16, 16]} align={"middle"} justify={"space-between"}>
                <Col>
                    <Typography.Title level={4}>{title}</Typography.Title>
                </Col>
                <Col>
                    {showExportBtn ? <Button onClick={handleExportPDF}>Export</Button> : null}
                    <div className='d-flex align-items-center' style={{ marginTop: 16 }}>
                        {chartData.map((item, index) => {
                            return (
                                <div key={index} className='d-flex align-items-center' style={{ marginRight: 12, cursor: "pointer" }} onClick={() => handleClick(index)}>
                                    <div style={{
                                        backgroundColor: item.borderColor,
                                        height: 16,
                                        width: 16,
                                        marginRight: 8
                                    }} />
                                    <Typography.Text delete={!indexValue.includes(index)}> {item.label} </Typography.Text>
                                </div>
                            )
                        })}


                    </div>
                </Col>
            </Row>
            <Line
                ref={chartRef}
                options={options}
                height={height}
                data={{
                    labels: visualizationData?.map(item => item.date),
                    datasets: chartData
                }}
            />
        </div>
    );
}