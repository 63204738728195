import {
  FETCH_TRADING_ACC_LIST,
  FETCH_TRADING_ACC_LIST_FAILURE,
  FETCH_TRADING_ACC_LIST_SUCCESS,
  FETCH_TRADING_ACC_SINGLE,
  FETCH_TRADING_ACC_SINGLE_FAILURE,
  FETCH_TRADING_ACC_SINGLE_SUCCESS,
  FETCH_TRADING_ACC_SINGLE_TRANSACTION,
  FETCH_TRADING_ACC_SINGLE_TRANSACTION_FAILURE,
  FETCH_TRADING_ACC_SINGLE_TRANSACTION_SUCCESS,
  FetchTradingAccListProps,
  FetchTradingSingleTransactionListProps,
  RESET_UPLOAD_TRADING_ACC_VAL,
  UPDATE_TRADING_ACC_SINGLE,
  UPDATE_TRADING_ACC_SINGLE_FAILURE,
  UPDATE_TRADING_ACC_SINGLE_SUCCESS,
  UpdateTradingSingle,
  UPLOAD_TRADING_ACC_FILE,
  UPLOAD_TRADING_ACC_FILE_FAILURE,
  UPLOAD_TRADING_ACC_FILE_SUCCESS,
  UploadTradingSuccessData,
} from './TradingAccountTypes';

export const uploadTradingAccFile = (data: any) => ({
    type: UPLOAD_TRADING_ACC_FILE,
    payload: data
})

export const uploadTradingAccFileSuccess = (data: UploadTradingSuccessData) => ({
    type: UPLOAD_TRADING_ACC_FILE_SUCCESS,
    payload: data

})

export const uploadTradingAccFileFailure = () => ({
    type: UPLOAD_TRADING_ACC_FILE_FAILURE
})

export const resetUploadTradingAccFileStateValue = () => ({
    type: RESET_UPLOAD_TRADING_ACC_VAL
})

export const fetchTradingAccList = (data: FetchTradingAccListProps) => ({
    type: FETCH_TRADING_ACC_LIST,
    payload: data
})

export const fetchTradingAccListSuccess = (data: any) => ({
    type: FETCH_TRADING_ACC_LIST_SUCCESS,
    payload: data
})

export const fetchTradingAccListFailure = () => ({
    type: FETCH_TRADING_ACC_LIST_FAILURE
})

export const fetchTradingAccSingle = (data: string) => ({
    type: FETCH_TRADING_ACC_SINGLE,
    payload: data
})

export const fetchTradingAccSingleSuccess = (data: any) => ({
    type: FETCH_TRADING_ACC_SINGLE_SUCCESS,
    payload: data
})

export const fetchTradingAccSingleFailure = () => ({
    type: FETCH_TRADING_ACC_SINGLE_FAILURE
})

export const updateTradingAccSingle = ({ id, data }: { id: string, data: UpdateTradingSingle }) => ({
    type: UPDATE_TRADING_ACC_SINGLE,
    payload: { id, data }
})

export const updateTradingAccSingleSuccess = () => ({
    type: UPDATE_TRADING_ACC_SINGLE_SUCCESS,
})

export const updateTradingAccSingleFailure = () => ({
    type: UPDATE_TRADING_ACC_SINGLE_FAILURE
})

export const fetchTradingSingleTransactionList = (data: FetchTradingSingleTransactionListProps) => ({
    type: FETCH_TRADING_ACC_SINGLE_TRANSACTION,
    payload: data
})

export const fetchTradingSingleTransactionListSuccess = (data: any) => ({
    type: FETCH_TRADING_ACC_SINGLE_TRANSACTION_SUCCESS,
    payload: data
})

export const fetchTradingSingleTransactionListFailure = () => ({
    type: FETCH_TRADING_ACC_SINGLE_TRANSACTION_FAILURE,
})

