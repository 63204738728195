import React, {
  Dispatch,
  SetStateAction,
} from 'react';

import {
  Button,
  Col,
  Form,
  Input,
  Row,
} from 'antd';
import { AntFormItem } from 'Components/AntForm/AntForm';
import { DatePicker } from 'Components/DatePicker';
import dayjs from 'dayjs';

import { CloseCircleOutlined } from '@ant-design/icons';

import { FloorsheetListFilterProps } from '../Floorsheet';
import { ImportComponent } from './ImportComponent';

interface FilterComponentProps {
  setFilterData: Dispatch<SetStateAction<FloorsheetListFilterProps>>;
  filterData: FloorsheetListFilterProps;

}

export const FilterComponent = ({
  filterData,
  setFilterData,
}: FilterComponentProps) => {
  const [formFilter] = Form.useForm();


  const handleFinish = (data: { filename: string }) => {

    setFilterData((prevData) => ({
      ...prevData,
      search: data.filename
    }))
  }


  const handleClearFilter = () => {
    setFilterData((prevData) => ({
      ...prevData,
      search: "",
      floorsheet_date: "",
      page: 1
    }))
    formFilter.setFieldValue("filename", "")
  }



  return (
    <div>
      <Row gutter={[20, 20]} align="middle">
        <Col lg={12}>
          <Form form={formFilter} onFinish={handleFinish}>
            <Row gutter={[16, 16]} align={"middle"}>
              <Col lg={12}>
                <AntFormItem name="filename" style={{ marginBottom: 0 }}>
                  <Input.Search placeholder='Search by filename' />
                </AntFormItem>
              </Col>
              <Col lg={12}>
                <Button htmlType="submit" type="primary" className="primary--alt" onClick={() => {

                }}>
                  Search
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>

        <Col lg={3} offset={6}>
          <ImportComponent filterData={filterData} />
        </Col>
        <Col lg={3}>
          <Button icon={<CloseCircleOutlined />} danger onClick={handleClearFilter} >Clear Filter</Button>
        </Col>
      </Row>
      <Row gutter={[20, 20]} align="middle" style={{ marginTop: 18 }} justify={"end"}>
        <Col>
          <DatePicker
          value={filterData?.floorsheet_date ? dayjs(filterData?.floorsheet_date ) : null }
            onChange={(date) => {
              if (date) {
                setFilterData((prevState) => ({
                  ...prevState,
                  floorsheet_date: dayjs(date?.toString()).format("YYYY-MM-DD"),
                  page: 1,
                }));
              } else {
                setFilterData((prevState) => ({
                  ...prevState,
                  floorsheet_date: "",
                }));
              }
            }}
          />
        </Col>
      </Row>
    </div>
  );
};
