import {
  FETCH_VISUALIZATION_DATA,
  FETCH_VISUALIZATION_DATA_FAILURE,
  FETCH_VISUALIZATION_DATA_SUCCESS,
  FetchVisualizationDataProps,
} from './visualizationType';

export const fetchVisualizationData = (data: FetchVisualizationDataProps) => ({
    type: FETCH_VISUALIZATION_DATA,
    payload: data
})

export const fetchVisualizationDataSuccess = (data: any) => ({
    type: FETCH_VISUALIZATION_DATA_SUCCESS,
    payload: data
})
export const fetchVisualizationDataFailure = () => ({
    type: FETCH_VISUALIZATION_DATA_FAILURE
})
