import './styles.scss';

import React, {
  useEffect,
  useState,
} from 'react';

import {
  Card,
  Col,
  Divider,
  Row,
  Typography,
} from 'antd';
import { StatsCard } from 'Components/StatsCard/StatsCard';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { fetchNepseList } from 'Redux/TradingRedux/alert/alertAction';
import {
  fetchVisualizationData,
} from 'Redux/TradingRedux/visualization/visualizationAction';
import {
  FetchVisualizationDataProps,
} from 'Redux/TradingRedux/visualization/visualizationType';

import { LowLevelFilter } from './Components/LowLevelFilter';
import { Quantity } from './Components/Quantity';
import { SectorWise } from './Components/SectorWise';
import { StockWise } from './Components/StockWise';
import { TopLevelFilter } from './Components/TopLevelFilter';
import { TotalTransaction } from './Components/TotalTransaction';
import { TurnOver } from './Components/TurnOver';
import { VisualizationPdf } from './VisualizationPdf';

export const Visualization = () => {

    const dispatch = useDispatch()

    const {
        visualizationDataLoading,
        visualizationData

    } = useSelector((state: any) => state.tradingVisualizationReducer);

    const [visualFilterData, setVisualFilterData] = useState<FetchVisualizationDataProps>({
        date_range: "last_30_days"
    })


    useEffect(() => {
        dispatch(fetchVisualizationData(visualFilterData))

    }, [dispatch, visualFilterData])
    useEffect(() => {
        dispatch(fetchNepseList())
    }, [dispatch])

    const stringFormat = (val: string) => {
        return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    }
    


    return (
        <div className='visualization' >
            {/* {
                visualizationDataLoading ? <Spin spinning={visualizationData} /> : 
            } */}

            <Card >
                <Row gutter={[16, 16]} align={"middle"} justify={"space-between"}>
                    <Col>
                        <Typography.Title level={3}>Transaction Overview</Typography.Title>
                    </Col>
                    <Col>
                        <TopLevelFilter visualFilterData={visualFilterData} setVisualFilterData={setVisualFilterData} />
                    </Col>
                </Row>
                <Row gutter={[16, 16]} align={"middle"} justify={"space-between"} className='stats'>
                    <Col span={5} className='turnover'>
                        <StatsCard
                            label={stringFormat(visualizationData?.total_turnover || 0)}
                            text="Share Turnover"
                        />
                    </Col>
                    <Col span={5} className='sales'>
                        <StatsCard
                            label={stringFormat(visualizationData?.sell_amount || 0)}

                            text="Total Sales Amount"
                        />
                    </Col>
                    <Col span={5} className='buy'>
                        <StatsCard
                            label={stringFormat(visualizationData?.buy_amount || 0)}

                            text="Total Buy Amount"
                        />
                    </Col>
                    <Col span={5} className='transaction'>
                        <StatsCard
                            label={stringFormat(visualizationData?.total_transactions || 0)}
                            text="Total Transaction"
                        />
                    </Col>
                </Row>
                <div>
                    <Divider orientation='left'>Filter</Divider>
                    <LowLevelFilter visualFilterData={visualFilterData} setVisualFilterData={setVisualFilterData} />
                    <Divider />
                </div>
                <Row gutter={[16, 38]} >
                    <Col lg={24}>
                        <TotalTransaction showExportBtn={true} />
                    </Col>
                    <Col lg={24}>
                        <Row gutter={[32, 32]}>
                            <Col md={12}>
                                <TurnOver showExportBtn={true} />
                            </Col>
                            <Col md={12}>
                                <Quantity showExportBtn={true} />
                            </Col>
                        </Row></Col>
                    <Col lg={24}>
                        <SectorWise showExportBtn={true} />
                    </Col>
                    <Col lg={24}>
                        <StockWise showExportBtn={true} /></Col>
                </Row>




            </Card>
            <VisualizationPdf visualFilterData={visualFilterData} setVisualFilterData={setVisualFilterData} />
        </div>

    )
}
