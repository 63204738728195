import React from 'react';

import {
  Col,
  Row,
  Table,
  Typography,
} from 'antd';
import { useSelector } from 'react-redux';

export const TableComponent = () => {

    const {
        groupDetailListData,
        groupDetailListLoading
    } = useSelector((state: any) => state.tradingGroupReducer);


    const columns = [
        {
            title: "User ID",
            dataIndex: "id",
            key: "id",
            render: (text: any, row: any, index: number) => (
                <Typography.Text>{text}</Typography.Text>
            ),
        },
        {
            title: "Client Name",
            dataIndex: "client_name",
            key: "client_name",
            render: (text: any, row: any, index: number) => (
                <Typography.Text>{text}</Typography.Text>
            ),
        },
        {
            title: "Client Code",
            dataIndex: "client_code",
            key: "client_code",
            render: (text: any, row: any, index: number) => (
                <Typography.Text
                >{text}</Typography.Text>
            ),
        },
        {
            title: "Mobile No.",
            dataIndex: "mobile",
            key: "mobile",
            render: (text: any, row: any, index: number) => (
                <Typography.Text>{text}</Typography.Text>
            ),
        },
        {
            title: "Email ID",
            dataIndex: "email",
            key: "email",
            render: (text: any, row: any, index: number) => (
                <Typography.Text>{text}</Typography.Text>
            ),
        },
        {
            title: "Landline No.",
            dataIndex: "landline",
            key: "landline",
            render: (text: any, row: any, index: number) => (
                <Typography.Text>{text}</Typography.Text>
            ),
        },
    ];

    return (
        <>
            <Row
                style={{
                    marginTop: 20,
                }}
            >
                <Col lg={24}>
                    <Table
                        rowKey="id"
                        columns={columns}
                        dataSource={groupDetailListData}
                        loading={groupDetailListLoading}
                    // pagination={{
                    //     current: Number(filterData.page),
                    //     total: parseInt(totalRecords),
                    //     onChange: (page: number) => {
                    //         setFilterData((prevState) => ({
                    //             ...prevState,
                    //             page: page,
                    //         }));
                    //     },
                    //     pageSize: filterData.limit,
                    //     showSizeChanger: false,
                    // }}
                    />
                </Col>
            </Row>
        </>
    );
};
