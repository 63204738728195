export const getRandomColor = () => {
    // Generate a random integer between 0 and 16777215 (0xFFFFFF)
    const randomInt = Math.floor(Math.random() * 16777216);
    // Convert the integer to a hexadecimal string
    let hexColor = randomInt.toString(16);
    // Pad with leading zeros if necessary to ensure it is 6 characters long
    while (hexColor.length < 6) {
        hexColor = '0' + hexColor;
    }
    // Add the # at the start of the string
    return '#' + hexColor;
}