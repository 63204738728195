import React, {
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  Button,
  Col,
  Form,
  Input,
  InputRef,
  Row,
  Table,
  Typography,
} from 'antd';
import TableActions from 'Components/TableAction/TableAction';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { useHistory } from 'react-router';
import {
  deleteAlert,
  fetchAlertList,
} from 'Redux/TradingRedux/alert/alertAction';

export const PriceAlertList = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const searchRef = useRef<InputRef>(null)

    //state
    const [filterData, setFilterData] = useState({
        limit: 10,
        search: ""
    })
    const [page, setPage] = useState(1)

    const {
        alertListLoading,
        alertList,
        totalAlertListRecords,
        success
    } = useSelector((state: any) => state.tradingAlertReducer);



    //table column
    const alertColumns = [
        {
            title: "S.N",
            dataIndex: "id",
            key: "id",
            render: (row: any, text: any, index: number) => {
                if (page && filterData.limit) {
                    return (
                        <Typography.Text>
                            {(page - 1) * filterData.limit + index + 1}
                        </Typography.Text>
                    );
                }
            },
        },
        {
            title: "Symbol",
            dataIndex: "symbol",
            key: "symbol",
            render: (text: any, row: any, index: number) => (
                <Typography.Text>{text}</Typography.Text>
            ),
        },
        {
            title: "Alert Name",
            dataIndex: "name",
            key: "name",
            render: (text: any, row: any, index: number) => (
                <Typography.Text
                >{text}</Typography.Text>
            ),
        },
        {
            title: "Group",
            dataIndex: "group",
            key: "group",
            render: (text: any, row: any, index: number) => (
                <Typography.Text
                >{text ? text : "-"}</Typography.Text>
            ),
        },
        {
            title: "Client",
            dataIndex: "client",
            key: "client",
            render: (text: any, row: any, index: number) => (
                <Typography.Text
                >{text? text : "-"}</Typography.Text>
            ),
        },
        {
            title: "Buying Price",
            children: [
                {
                    title: "Low Bid",
                    dataIndex: "low_bid",
                    key: "low_bid",
                    render: (text: any, row: any, index: number) => (
                        <Typography.Text>{text ? text : "-"}</Typography.Text>
                    ),
                },
                {
                    title: "High Bid",
                    dataIndex: "high_bid",
                    key: "high_bid",
                    render: (text: any, row: any, index: number) => (
                        <Typography.Text>{text ? text : "-"}</Typography.Text>
                    ),
                }
            ]
        },
        {
            title: "Selling Price",
            children: [
                {
                    title: "Take Profit",
                    dataIndex: "take_profit",
                    key: "take_profit",
                    render: (text: any, row: any, index: number) => (
                        <Typography.Text>{text ? text : "-"}</Typography.Text>
                    ),
                },
                {
                    title: "Stop Loss",
                    dataIndex: "stop_loss",
                    key: "stop_loss",
                    render: (text: any, row: any, index: number) => (
                        <Typography.Text>{text ? text : "-"}</Typography.Text>
                    ),
                }
            ]
        },
        {
            title: "Validity Date ",
            dataIndex: "validity_date",
            key: "validity_date",
            render: (text: any, row: any, index: number) => (
                <Typography.Text>{text}</Typography.Text>
            ),
        },
        {
            title: "",
            dataIndex: "id",
            key: "id",
            render: (text: any, row: any, index: number) => (
                <TableActions
                    noReport
                    onDelete={() => {
                        dispatch(deleteAlert(row.id))
                    }}
                    onEdit={() => history.push(`/trading-profile/price-alert/edit/${row.id}`)}

                    archieved={true}
                    showDeleteBtn={
                        true
                    }
                    showViewProfile={false}
                    adminEdit={
                        true
                    }

                    editTitle='Edit'
                    deleteTitle='Delete'

                />
            ),
        },
    ];



    useEffect(() => {
        dispatch(fetchAlertList(filterData))
    }, [filterData, dispatch, success])


    return (
        <>
            <div className='price-alert-list'>
                <Typography.Title level={3}>
                    Alerts
                </Typography.Title>
                <Row gutter={[16, 16]} justify={"space-between"} align={"middle"} className='header'>
                    <Col>
                        <Form>
                            <div className="d-flex align-items-center search">
                                <Input.Search ref={searchRef} />
                                <Button
                                    htmlType="submit"
                                    type="primary"
                                    className="primary--alt search-btn"
                                    onClick={() => {
                                        setPage(1)
                                        setFilterData((prevData) => ({
                                            ...prevData,
                                            search: searchRef?.current?.input?.value || "",
                                            offset: 0
                                        })
                                        )
                                    }}
                                >
                                    Search
                                </Button>
                            </div>
                        </Form>
                    </Col>
                    <Col>
                        <Button onClick={() => history.push('/trading-profile/price-alert/add/new')} type='primary'> Add New Alert </Button>
                    </Col>
                </Row>
                <div className='table-list'>
                    <Table
                        rowKey="id"
                        columns={alertColumns}
                        dataSource={alertList}
                        loading={alertListLoading}
                        pagination={{
                            current: Number(page),
                            total: parseInt(totalAlertListRecords),
                            onChange: (page: number) => {
                                setPage(page)
                                setFilterData((prevState) => ({
                                    ...prevState,
                                    offset: (page - 1) * filterData.limit
                                }));
                            },
                            showSizeChanger: true,
                            onShowSizeChange: (current, size) => {
                                setPage(page)
                                setFilterData((prev) => ({
                                    ...prev,
                                    limit: size
                                }))

                            },
                            pageSize: filterData.limit,
                        }}
                    />
                </div>

            </div>
        </>

    )
}
