import {
  DELETE_FLOORSHEET,
  DELETE_FLOORSHEET_FAILURE,
  DELETE_FLOORSHEET_SUCCESS,
  FETCH_FLOORSHEET_LIST,
  FETCH_FLOORSHEET_LIST_FAILURE,
  FETCH_FLOORSHEET_LIST_SUCCESS,
  RESET_UPLOAD_FLOORSHEET_VAL,
  UPLOAD_FLOORSHEET_FILE,
  UPLOAD_FLOORSHEET_FILE_FAILURE,
  UPLOAD_FLOORSHEET_FILE_SUCCESS,
} from './FloorsheetTypes';

export const uploadFloorsheetFile = (data: any) => ({
    type: UPLOAD_FLOORSHEET_FILE,
    payload: data
})

export const uploadFloorsheetFileSuccess = (data: any) => ({
    type: UPLOAD_FLOORSHEET_FILE_SUCCESS,
    payload: data

})

export const uploadFloorsheetFileFailure = () => ({
    type: UPLOAD_FLOORSHEET_FILE_FAILURE
})

export const resetUploadFloorsheetFileStateValue = () => ({
    type: RESET_UPLOAD_FLOORSHEET_VAL
})

export const fetchFloorsheetList = (data: any) => ({
    type: FETCH_FLOORSHEET_LIST,
    payload: data
})

export const fetchFloorsheetListSuccess = (data: any) => ({
    type: FETCH_FLOORSHEET_LIST_SUCCESS,
    payload: data
})

export const fetchFloorsheetListFailure = () => ({
    type: FETCH_FLOORSHEET_LIST_FAILURE
})

export const deleteFloorsheet = (id: string) => ({
    type: DELETE_FLOORSHEET,
    payload: id
})

export const deleteFloorsheetSuccess = () => ({
    type: DELETE_FLOORSHEET_SUCCESS
})

export const deleteFloorsheetFailure = () => ({
    type: DELETE_FLOORSHEET_FAILURE
})