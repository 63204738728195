import './styles.scss';

import React from 'react';

import { Card } from 'antd';

import { PriceAlertList } from './Components/PriceAlertList';

export const PriceAlert = () => {

  //state
  return (
    <Card className='price-alert'>

      <PriceAlertList />

    </Card>
  )
}
