import './styles.scss';

import React, {
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  Button,
  Card,
  Col,
  Form,
  Input,
  InputRef,
  Row,
  Table,
  Typography,
} from 'antd';
import TableActions from 'Components/TableAction/TableAction';
import dayjs from 'dayjs';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  deleteTags,
  fetchTagsList,
} from 'Redux/TradingRedux/Tags/TagsAction';

import { AddTags } from './Components/AddTags';

export const Tags = () => {
    const dispatch = useDispatch()
    const searchRef = useRef<InputRef>(null)

    const {
        tagsListLoading,
        tagsList,
        tagsListRecords,

    } = useSelector((state: any) => state.tradingTagsReducer);


    //state
    const [filterData, setFilterData] = useState({
        limit: 10,
        search: "",
        offset: 0
    })
    const [page, setPage] = useState(1)

    const [tagModal, setTagModal] = useState<{
        visible: boolean,
        tagsData?: {
            id?: string,
            name?: string,
            status?: boolean
        }
    }>({
        visible: false,
        tagsData: {}
    })

    useEffect(() => {
        dispatch(fetchTagsList(filterData))
    }, [dispatch, filterData])


    //table column
    const alertColumns = [
        {
            title: "S.N",
            dataIndex: "id",
            key: "id",
            render: (row: any, text: any, index: number) => {
                if (page && filterData.limit) {
                    return (
                        <Typography.Text>
                            {(page - 1) * filterData.limit + index + 1}
                        </Typography.Text>
                    );
                }
            },
        },

        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            render: (text: any, row: any, index: number) => (
                <Typography.Text
                >{text}</Typography.Text>
            ),
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (text: any, row: any, index: number) => (
                <Typography.Text
                >{text ? "Enable" : "Disable"}</Typography.Text>
            ),
        },

        {
            title: "Created Date ",
            dataIndex: "created_at",
            key: "created_at",
            render: (text: any, row: any, index: number) => (
                <Typography.Text>{dayjs(text).format("DD-MM-YYYY")}</Typography.Text>
            ),
        },
        {
            title: "",
            dataIndex: "id",
            key: "id",
            render: (text: any, row: any, index: number) => (
                <TableActions
                    noReport
                    onDelete={() => {
                        dispatch(deleteTags(row.id))
                    }}
                    onEdit={() => setTagModal({
                        visible: true,
                        tagsData: row
                    })}

                    archieved={true}
                    showDeleteBtn={
                        true
                    }
                    showViewProfile={false}
                    adminEdit={
                        true
                    }

                    editTitle='Edit'
                    deleteTitle='Delete'

                />
            ),
        },
    ];

    return (
        <>


            <Card className='tags'>
                <Typography.Title level={3}>
                    Tags
                </Typography.Title>
                <div className='tags-list'>
                    <Row gutter={[16, 16]} justify={"space-between"} align={"middle"} className='header'>
                        <Col>
                            <Form>
                                <div className="d-flex align-items-center search">
                                    <Input.Search ref={searchRef} placeholder='Search by name' />
                                    <Button
                                        htmlType="submit"
                                        type="primary"
                                        className="primary--alt search-btn"
                                        onClick={() => {
                                            setPage(1)
                                            setFilterData((prevData) => ({
                                                ...prevData,
                                                search: searchRef?.current?.input?.value || "",
                                                offset: 0
                                            })
                                            )
                                        }}
                                    >
                                        Search
                                    </Button>
                                </div>
                            </Form>
                        </Col>
                        <Col>
                            <Button onClick={() => setTagModal({
                                visible: true
                            })} type='primary'> Add New Tag </Button>
                        </Col>
                    </Row>
                    <div className='table-list'>
                        <Table
                            rowKey="id"
                            columns={alertColumns}
                            dataSource={tagsList}
                            loading={tagsListLoading}
                            pagination={{
                                current: Number(page),
                                total: parseInt(tagsListRecords),
                                onChange: (page: number) => {
                                    setPage(page)
                                    setFilterData((prevState) => ({
                                        ...prevState,
                                        offset: (page - 1) * filterData.limit
                                    }));
                                },
                                showSizeChanger: true,
                                onShowSizeChange: (current, size) => {
                                    setPage(page)
                                    setFilterData((prev) => ({
                                        ...prev,
                                        limit: size
                                    }))

                                },
                                pageSize: filterData.limit,
                            }}
                        />
                    </div>

                </div>
            </Card>
            <AddTags tagModal={tagModal} setTagModal={setTagModal} filterData={filterData} />
        </>
    )
}
