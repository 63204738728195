import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';

import {
  Button,
  Card,
  Checkbox,
  Col,
  Row,
  Select,
  Typography,
} from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import Modal from 'Components/Modal';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { useParams } from 'react-router';
import {
  fetchGroupDetailList,
  updateGroup,
} from 'Redux/TradingRedux/GroupManagement/groupAction';
import {
  fetchTradingAccList,
} from 'Redux/TradingRedux/TradingAccount/TradingAccountAction';

interface AddMembersModalProps {
    addMembersModal: boolean,
    setAddMembersModal: Dispatch<SetStateAction<boolean>>
}

export const AddMembersModal = ({ addMembersModal, setAddMembersModal }: AddMembersModalProps) => {
    const dispatch = useDispatch()
    const {
        groupDetailListData,
        groupDetailListLoading,
        loading,
        success
    } = useSelector((state: any) => state.tradingGroupReducer);

    const {
        tradingAccListLoading,
        tradingAccList,
        totalTradingAccListRecords
    } = useSelector((state: any) => state.tradingTradingAccReducer);


    const { id } = useParams<{ id: string }>()


    const [filterData, setFilterData] = useState({
        limit: 6,
        search: ""
    });

    const [page, setPage] = useState(1)

    const [membersData, setMembersData] = useState<{
        id: string,
        client_name: string,
        client_code: string
    }[]>([])
    const [checkedItems, setCheckedItems] = useState<string[]>([])

    useEffect(() => {
        dispatch(fetchTradingAccList(filterData))

    }, [dispatch, filterData])


    useEffect(() => {
        if (groupDetailListData.length > 0) {
            setMembersData(groupDetailListData)
            const items = groupDetailListData.map((item: { id: string }) => item.id)
            setCheckedItems(items)
        }
    }, [groupDetailListData])

    useEffect(() => {
        if (id && success) {
            dispatch(fetchGroupDetailList(id))
            setAddMembersModal(false)
        }
    }, [success, dispatch, id])


    const handleSelect = (val: string) => {
        const selectedItem = tradingAccList.find((item: { id: string }) => item.id === val);
        if (selectedItem) {
            setMembersData((prevData) => [
                ...prevData,
                selectedItem
            ])
        }
    };

    const handleCheckbox = (id: string) => {
        const isData = checkedItems.indexOf(id)
        if (isData === -1) {
            setCheckedItems((prevData) => [
                ...prevData,
                id
            ])

        } else {
            const filterData = checkedItems.filter((item) => item !== id)
            setCheckedItems(filterData)
        }
    }

    const handleSelectAll = (e: CheckboxChangeEvent) => {
        if (e.target.checked) {
            const items = membersData.map((item) => item.id)
            setCheckedItems(items)
        } else {
            setCheckedItems([])
        }

    }

    const handleMembers = () => {
        const postData = {
            members: checkedItems
        }
        dispatch(updateGroup(id, postData))

    }


    return (
        <Modal visible={addMembersModal} width={"50vw"} className='add-members-modal' onCancel={() => setAddMembersModal(false)} maskClosable={false}>
            <div>
                <Typography.Title className='title' level={5} >Group Members</Typography.Title>
                <Row gutter={[16, 16]} justify={"space-between"} className='search-members'>
                    <Col span={24}>
                        {/* <Input.Search /> */}
                        <Select
                            filterOption={
                                false
                            }
                            mode="multiple"
                            allowClear
                            style={{ width: '100%', height: 'auto' }}
                            placeholder="Please select"
                            showSearch={true}
                            // searchValue={filterData.search}
                            defaultValue={
                                membersData.map((item) => {
                                    return item.client_name

                                })
                            }
                            loading={tradingAccListLoading}
                            defaultActiveFirstOption={false}
                            onSearch={(value: string) => {
                                setFilterData((prevData) => ({
                                    ...prevData,
                                    search: value
                                }))
                            }}

                            onSelect={handleSelect}
                        >

                            {
                                tradingAccList.length > 0 && tradingAccList.map((item: { client_name: string, id: string }) => {
                                    return (
                                        <Select.Option key={item.id} value={item.id}> {item.client_name} </Select.Option>
                                    )
                                })
                            }

                        </Select>

                    </Col>
                </Row>
                <div>
                    <div className='d-flex align-items-center justify-between count'>
                        <div className='d-flex align-items-center selected-count'>
                            <Typography> {checkedItems.length}  Selected </Typography>
                            <Checkbox onChange={handleSelectAll} >Select All</Checkbox>
                        </div>
                        {/* <Button icon={<DeleteIcon />} type='ghost' className='delete-btn' /> */}

                    </div>
                    <Card className='members-list'>
                        <Row gutter={[16, 32]}>
                            {membersData.length > 0 && membersData.map((item: { id: string, client_name: string, client_code: string }) => {
                                return (
                                    <Col key={item.id}>
                                        <Card className='members-card'>
                                            <div className='d-flex'>
                                                <Checkbox checked={checkedItems.includes(item.id)} onChange={() => handleCheckbox(item.id)} />
                                                <div>
                                                    <Typography className='name'> {item.client_name} </Typography>
                                                    <Typography className='id'> {item.client_code} </Typography>
                                                </div>
                                            </div>
                                        </Card>
                                    </Col>
                                )
                            })}

                        </Row>

                    </Card>
                    <Button type='primary' onClick={handleMembers} loading={loading} > Save </Button>

                </div>
            </div>
        </Modal>
    )
}
