export const FETCH_TAGS_LIST = "FETCH_TAGS_LIST"
export const FETCH_TAGS_LIST_SUCCESS = "FETCH_TAGS_LIST_SUCCESS"
export const FETCH_TAGS_LIST_FAILURE = "FETCH_TAGS_LIST_FAILURE"

export const DELETE_TAGS = "DELETE_TAGS"
export const DELETE_TAGS_SUCCESS = "DELETE_TAGS_SUCCESS"
export const DELETE_TAGS_FAILURE = "DELETE_TAGS_FAILURE"

export const CREATE_TAGS = "CREATE_TAGS"
export const CREATE_TAGS_SUCCESS = "CREATE_TAGS_SUCCESS"
export const CREATE_TAGS_FAILURE = "CREATE_TAGS_FAILURE"

export const UPDATE_TAGS = "UPDATE_TAGS"
export const UPDATE_TAGS_SUCCESS = "UPDATE_TAGS_SUCCESS"
export const UPDATE_TAGS_FAILURE = "UPDATE_TAGS_FAILURE"


export interface FetchTagsListProps {
    limit?: number;
    offset?: number;
    search?: string

}

export interface CreateTagsProps {
    name: string,
    status: string

}