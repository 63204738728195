import React from 'react';

import {
  Route,
  Switch,
} from 'react-router';
import { Dashboard } from 'Views/Dp/Dashboard/Dashboard';
import { Floorsheet } from 'Views/TradingProfile/Floorsheet/Floorsheet';
import {
  FloorsheetTransaction,
} from 'Views/TradingProfile/Floorsheet/FloorsheetTransaction/FloorsheetTransaction';
import {
  AddGroup,
} from 'Views/TradingProfile/GroupManagement/Components/AddGroup';
import {
  GroupManagement,
} from 'Views/TradingProfile/GroupManagement/GroupManagement';
import {
  SingleGroupList,
} from 'Views/TradingProfile/GroupManagement/SingleGroupList/SingleGroupList';
import { AddEditAlert } from 'Views/TradingProfile/PriceAlert/AddEdit';
import { PriceAlert } from 'Views/TradingProfile/PriceAlert/PriceAlert';
import { Tags } from 'Views/TradingProfile/Tags/Tags';
import {
  TradingAccount,
} from 'Views/TradingProfile/TradingAccount/TradingAccount';
import {
  TradingSingle,
} from 'Views/TradingProfile/TradingAccount/TradingSingle/TradingSingle';
import {
  Visualization,
} from 'Views/TradingProfile/Visualization/Visualization';

export const TradingRoutes = (props: any) => {
  return (
    <Switch>
      <Route path={`${props.match?.path}`} exact component={Dashboard} />
      <Route
        path={`${props.match?.path}/trading-account`}
        exact
        component={TradingAccount}
      />
      <Route
        path={`${props.match?.path}/trading-account/:id`}
        exact
        component={TradingSingle}
      />
      <Route
        path={`${props.match?.path}/floorsheet`}
        exact
        component={Floorsheet}
      />
      <Route
        path={`${props.match?.path}/floorsheet/transaction/:id?`}
        exact
        component={FloorsheetTransaction}
      />
      <Route
        path={`${props.match?.path}/group-management`}
        exact
        component={GroupManagement}
      />
            <Route
        path={`${props.match?.path}/group-management/add`}
        exact
        component={AddGroup}
      />
      <Route
        path={`${props.match?.path}/group-management/:id/list`}
        exact
        component={SingleGroupList}
      />
      <Route
        path={`${props.match?.path}/price-alert`}
        exact
        component={PriceAlert}
      />
      <Route
        path={`${props.match?.path}/price-alert/:formType/:id`}
        exact
        component={AddEditAlert}
      />
      <Route
        path={`${props.match?.path}/visualization`}
        exact
        component={Visualization}
      />
      <Route
        path={`${props.match?.path}/tags`}
        exact
        component={Tags}
      />
    </Switch>
  );
};
