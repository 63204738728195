import React, {
  Dispatch,
  SetStateAction,
} from 'react';

import {
  Button,
  Row,
  Space,
  Spin,
  Typography,
  Upload,
} from 'antd';
import Modal from 'Components/Modal';
import { DownloadIconDark } from 'Icons/DownloadIcon/DownloadIcon';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  uploadFloorsheetFile,
} from 'Redux/TradingRedux/Floorsheet/FloorsheetAction';
import { dummyRequest } from 'Utils/DummyCall';

interface ReuploadComponentProps {
    visible: {
        show: boolean,
        id: string
    },
    setVisible: Dispatch<SetStateAction<{
        show: boolean,
        id: string
    }>>
}

export const ReuploadComponent = ({ visible, setVisible }: ReuploadComponentProps) => {

    const {
        uploadFileLoading,
        uploadFileError,
        uploadFileSuccess,
        uploadFileSuccessData
    } = useSelector((state: any) => state.tradingFloorsheetReducer)

    const dispatch = useDispatch()


    const renderModalComp = () => {
        switch (true) {
            case uploadFileError:
                return (
                    <>
                        <Typography.Title type="danger" level={4}>
                            Import Failed!
                        </Typography.Title>
                        <Row>
                            <Button
                                style={{
                                    marginRight: 20,
                                }}
                                onClick={() => {
                                    //   dispatch(resetUploadFloorsheetFileStateValue());
                                    //   setShowImportModal({
                                    //     visible: false,
                                    //     key: "",
                                    //   });
                                }}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="primary"
                            // onClick={() => dispatch(resetUploadFloorsheetFileStateValue())}
                            >
                                Reupload File
                            </Button>
                        </Row>
                    </>
                );

            case uploadFileSuccess:
                return (
                    <>
                        <Typography.Title type="success" level={4}>
                            successfully imported
                        </Typography.Title>
                        <Typography.Text>
                            {" "}
                            Newly added account- {uploadFileSuccessData.total_created}{" "}
                        </Typography.Text>
                        <Typography.Text>
                            {" "}
                            Update account- {uploadFileSuccessData.total_updated}{" "}
                        </Typography.Text>
                    </>
                );

            default:
                return (
                    <Spin spinning={uploadFileLoading}>

                        <Upload
                            customRequest={dummyRequest}
                            beforeUpload={(file: any) => {
                                const formData = new FormData();

                                formData.append("file", file);
                                // formData.append("date", date);
                                dispatch(uploadFloorsheetFile({formData, id: visible.id}));
                            }}
                            accept=".xlsx, application/vnd.ms-excel"
                        >
                            <Space direction="vertical" align="center" size={"large"}>
                                <DownloadIconDark />
                                <Typography.Text>Upload .xls or .xlsx file</Typography.Text>
                            </Space>
                        </Upload>

                    </Spin>
                );
        }
    };

    return (
        <Modal
            visible={visible.show}
            width={"30vw"}
            onCancel={() => {
                //   dispatch(resetUploadCdscFileStateValue());
                //   setShowImportModal({
                //     visible: false,
                //     key: "",
                //   });

                setVisible({
                    show: false,
                    id: ""
                })
            }}
        >
            <Space
                direction="vertical"
                align="center"
                size={"large"}
                style={{
                    width: "100%",
                }}
            >
                {renderModalComp()}
            </Space>
        </Modal>
    )
}
