import React, {
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  Button,
  Col,
  Row,
  Typography,
} from 'antd';
import {
  ArcElement,
  Chart as ChartJS,
  Legend,
  Tooltip,
} from 'chart.js';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { Doughnut } from 'react-chartjs-2';

interface DoughnutChartProps {
    chartData: {
        data: number,
        color: string,
        labels: string
    }[],
    showExportBtn: boolean
    title: string
}

export const DoughnutChart = ({ chartData, showExportBtn, title }: DoughnutChartProps) => {

    const chartRef = useRef<any>(null);

    const [indexValue, setIndexValue] = useState<number[]>([])

    useEffect(() => {
        if (chartData.length > 0) {
            setIndexValue(chartData.map((item, index) => index))
        }
    }, [chartData])

    ChartJS.register(
        ArcElement,
        Tooltip,
        Legend
    )

    const options = {
        responsive: true,
        cutout: "65%",
        plugins: {
            legend: {
                display: false,

            }
        },


    } as const

    const handleClick = (index: number) => {
        const chart = chartRef.current
        if (chart) {

            const meta = chart.getDatasetMeta(0)
            const dataIndex = meta.data[index];

            dataIndex.hidden = !dataIndex.hidden;
            chart.update();

            setIndexValue((prevVal) => {
                if (prevVal.includes(index)) {
                    return prevVal.filter((i) => i !== index);
                } else {
                    return [...prevVal, index];
                }
            })
        }


    }

    const handleExportPDF = () => {
        const chart = document.getElementById("round-chart");

        if (chart) {
            html2canvas(chart).then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF();
                const imgProps = pdf.getImageProperties(imgData);
                const pdfWidth = pdf.internal.pageSize.width - 20;
                const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                let heightLeft = pdfHeight;
                let position = 30;
                pdf.text(title, 10, 10);
                pdf.addImage(imgData, 'PNG', 10, position, pdfWidth, pdfHeight);
                heightLeft -= pdf.internal.pageSize.height;



                while (heightLeft > 0) {
                    position = heightLeft - pdfHeight;
                    pdf.addPage();
                    pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, pdfHeight);
                    heightLeft -= pdf.internal.pageSize.height;
                    console.log("show height", heightLeft);
                }

                pdf.save('download.pdf');
                // setExportLoading(false)
            });
        }


    };

    return (
        <div className='round-chart'>
            <Row align={"middle"} justify={"space-between"}>
                <Col>
                    <Typography.Title level={5}>
                        {title}
                    </Typography.Title>
                </Col>
                {showExportBtn &&

                    <Col>
                        <Button onClick={handleExportPDF} >Export</Button>
                    </Col>
                }
            </Row>
            <div id="round-chart">
                <div className='doughnut-chart'>
                    <Doughnut className='chart' ref={chartRef} options={options} data={{
                        labels: chartData.map((item) => item.labels),
                        datasets: [{
                            // label: 'My First Dataset',
                            data: chartData.map((item) => item.data),
                            backgroundColor: chartData.map((item) => item.color),
                            hoverOffset: 4
                        }]
                    }} />


                </div>
                <div className='legends'>
                    {chartData.map((item, index) => {
                        return (
                            <div onClick={() => handleClick(index)} className='d-flex align-items-center legend-item'>
                                <div style={{
                                    marginRight: 8,
                                    height: 16,
                                    width: 16,
                                    backgroundColor: item.color
                                }} />  <Typography.Text delete={!indexValue.includes(index)}> {item.labels} </Typography.Text>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}
