import React, {
  Dispatch,
  SetStateAction,
  useEffect,
} from 'react';

import {
  Button,
  Form,
  Input,
  Select,
  Typography,
} from 'antd';
import Modal from 'Components/Modal';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  createTags,
  fetchTagsList,
  updateTags,
} from 'Redux/TradingRedux/Tags/TagsAction';
import {
  CreateTagsProps,
  FetchTagsListProps,
} from 'Redux/TradingRedux/Tags/TagsType';

interface AddTagsProps {
    tagModal: {
        visible: boolean
        tagsData?: {
            id?: string,
            name?: string
            status?: boolean
        }
    }
    setTagModal: Dispatch<SetStateAction<any>>
    filterData: FetchTagsListProps
}

export const AddTags = ({ tagModal, setTagModal, filterData }: AddTagsProps) => {
    const [form] = Form.useForm()
    const dispatch = useDispatch()

    const {
        loading,
        success
    } = useSelector((state: any) => state.tradingTagsReducer);

    useEffect(() => {
        if (success) {
            form.resetFields()
            setTagModal({
                visible: false
            })
            dispatch(fetchTagsList(filterData))
        }
    }, [success, setTagModal, filterData, dispatch, form])

    useEffect(() => {

        if (tagModal.tagsData && Object.keys(tagModal.tagsData)) {
            form.setFieldValue("name", tagModal.tagsData.name)
            form.setFieldValue("status", tagModal.tagsData.status)
        }

    }, [tagModal.tagsData, form])

    const handleSubmit = (data: CreateTagsProps) => {
        if (tagModal.tagsData && Object.keys(tagModal.tagsData) && tagModal.tagsData.id) {
            dispatch(updateTags({ id: tagModal.tagsData.id, data }))

        } else {

            dispatch(createTags(data))
        }
    }

    return (
        <Modal
            visible={tagModal.visible}
            width={"50vw"}
            onCancel={() => {
                form.resetFields()
                setTagModal({
                    visible: false,
                });
            }}
            maskClosable={false}
        >
            <div>
                <Typography.Title level={4} >  {tagModal.tagsData && Object.keys(tagModal.tagsData) ? "Edit" : "Add"}  Tags</Typography.Title>
                <Form form={form} onFinish={handleSubmit} layout="vertical" >


                    <Form.Item
                        label="Name"
                        name="name"

                        rules={[{ required: true, message: 'Tag name is required' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Status"
                        name="status"

                        rules={[{ required: true, message: 'Tag name is required' }]}
                    >
                        <Select
                            options={[
                                {
                                    label: "Enable",
                                    value: true
                                },
                                {
                                    label: "Disable",
                                    value: false
                                }
                            ]}

                        />
                    </Form.Item>
                    <Button htmlType="submit" type="primary" style={{
                        width: "100%"
                    }}
                        loading={loading}
                    >
                        {tagModal.tagsData && Object.keys(tagModal.tagsData) ? "Save" : "Add"}
                    </Button>

                </Form>
            </div>
        </Modal>
    )
}
