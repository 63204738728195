import React, {
  Dispatch,
  SetStateAction,
  useState,
} from 'react';

import {
  Button,
  Col,
  Row,
  Spin,
} from 'antd';
import { DatePicker } from 'Components/DatePicker';
import dayjs from 'dayjs';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import {
  FetchVisualizationDataProps,
} from 'Redux/TradingRedux/visualization/visualizationType';

interface TopLevelFilterProps {
    setVisualFilterData: Dispatch<SetStateAction<FetchVisualizationDataProps>>
    visualFilterData: FetchVisualizationDataProps
}

export const TopLevelFilter = ({ visualFilterData, setVisualFilterData }: TopLevelFilterProps) => {
    const [exportLoading, setExportLoading] = useState(false)

    const handleExportAll = async () => {

        const totalTransactionElement = document.getElementById("visualization_pdf");

        if (totalTransactionElement) {
            setExportLoading(true)

            html2canvas(totalTransactionElement).then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF();
                const imgProps = pdf.getImageProperties(imgData);
                const pdfWidth = pdf.internal.pageSize.width - 20;
                const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
                let heightLeft = pdfHeight;
                let position = 0;

                pdf.addImage(imgData, 'PNG', 10, position, pdfWidth, pdfHeight);
                heightLeft -= pdf.internal.pageSize.height;



                while (heightLeft > 0) {
                    position = heightLeft - pdfHeight;
                    pdf.addPage();
                    pdf.addImage(imgData, 'PNG', 0, position, pdfWidth, pdfHeight);
                    heightLeft -= pdf.internal.pageSize.height;
                    console.log("show height", heightLeft);
                }

                pdf.save('download.pdf');
                setExportLoading(false)
            });

        } else {
            console.error("Element with id 'total_transaction' not found.");
        }
    };

    return (
        <Row gutter={[16, 16]}>
            <Col>

                <Button
                    disabled={visualFilterData?.date_range === "yesterday"}
                    onClick={() => setVisualFilterData((prevData) => ({
                        ...prevData,
                        date_range: "yesterday"
                    }))}
                >
                    {" "}
                    Yesterday{" "}
                </Button>

            </Col>
            <Col>

                <Button
                    disabled={visualFilterData?.date_range === "last_7_days"}
                    onClick={() => setVisualFilterData((prevData) => ({
                        ...prevData,
                        date_range: "last_7_days"
                    }))}
                >
                    {" "}
                    Last 7 Days{" "}
                </Button>

            </Col>
            <Col>
                <Button
                    disabled={visualFilterData?.date_range === "last_30_days" ? true : false}
                    onClick={() => setVisualFilterData((prevData) => ({
                        ...prevData,
                        date_range: "last_30_days"
                    }))}
                >
                    {" "}
                    Last one month{" "}
                </Button>

            </Col>
            <Col>
                <DatePicker.RangePicker
                    // style={{ width: "100%" }}
                    value={visualFilterData.custom_date_after ? [
                        dayjs(visualFilterData.custom_date_after), dayjs(visualFilterData?.custom_date_before)
                    ] : null}
                    onChange={(dates) => {
                        setVisualFilterData((prevState) => ({
                            ...prevState,
                            date_range: "",
                            custom_date_after: dates
                                ? dayjs(dates?.[0]?.toString()).format("YYYY-MM-DD")
                                : "",
                            custom_date_before: dates
                                ? dayjs(dates?.[1]?.toString()).format("YYYY-MM-DD")
                                : "",
                            page: 1,
                        }));
                    }}
                />
            </Col>

            <Col>
                <Button disabled={exportLoading} onClick={handleExportAll}>  {exportLoading ? <Spin /> : "Export All"}  </Button>
            </Col>
        </Row>
    )
}
