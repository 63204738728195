import { Auth } from 'aws-amplify';
import axios from 'axios';
import config from 'Config/config';

import {
  CreateTagsProps,
  FetchTagsListProps,
} from './TagsType';

axios.defaults.baseURL = `${config.dpApi}`;

const getToken = async () => {
    return (await Auth.currentSession()).getIdToken().getJwtToken();
};


export const fetchTagsListApi = async (data: FetchTagsListProps) => {
    return axios.get(`/tags/tags/`, {
        params: data,
        headers: {
            Authorization: `Bearer ${await getToken()}`,
        },
    });
}

export const deleteTagsApi = async (id: string) => {
    return axios.delete(`tags/tags/${id}`, {
        headers: {
            Authorization: `Bearer ${await getToken()}`,
        },
    });
}

export const createTagsApi = async (data: CreateTagsProps) => {
    return axios.post(`tags/tags/`, data, {
        headers: {
            Authorization: `Bearer ${await getToken()}`,
        },
    });
}


export const updateTagsApi = async ({ id, data }: { id: string, data: CreateTagsProps }) => {
    return axios.patch(`tags/tags/${id}/`, data, {
        headers: {
            Authorization: `Bearer ${await getToken()}`,
        },
    });
}