import React, {
  Suspense,
  useEffect,
  useState,
} from 'react';

import {
  Button,
  Card,
  Col,
  Image,
  Row,
  Table,
  Typography,
} from 'antd';
import { Content } from 'antd/lib/layout/layout';
import defaultAvatar from 'Assets/images/defaultAvatar.png';
import MyContent from 'Components/Content/Content';
import { DatePicker } from 'Components/DatePicker';
import DescriptionCard from 'Components/DescriptionCard/DescriptionCard';
import Loading from 'Components/Loading/Loading';
import PageHolder from 'Components/PageHolder/PageHolder';
import PageLoading from 'Components/PageLoading/PageLoading';
import dayjs from 'dayjs';
import BackIcon from 'Icons/BackIcon/BackIcon';
import DeleteIcon from 'Icons/DeleteIcon/DeleteIcon';
import DownloadIcon, {
  DownloadIconDark,
} from 'Icons/DownloadIcon/DownloadIcon';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  useHistory,
  useParams,
} from 'react-router';
import {
  fetchTradingAccSingle,
  fetchTradingSingleTransactionList,
} from 'Redux/TradingRedux/TradingAccount/TradingAccountAction';

import { AddTags } from './components/AddTags';

export const TradingSingle = () => {

  const history = useHistory();
  const { id } = useParams<{ id: string }>()
  const dispatch = useDispatch()

  const {

    tradingAccSingleLoading,
    tradingAccSingle,
    tradingSingleTransactionLoading,
    tradingSingleTransactionList,
    totalTradingSingleTransactionRecords
  } = useSelector((state: any) => state.tradingTradingAccReducer);

  console.log("show val", tradingSingleTransactionList);



  const [filterData, setFilterData] = useState<{
    limit: number,
    custom_date_after: string | null
    custom_date_before: string | null
    date_range: string | null
  }>({
    limit: 10,
    custom_date_after: null,
    custom_date_before: null,
    date_range: null
  });

  const [page, setPage] = useState(1)



  const [showModal, setShowModal] = useState(false)





  const backHandler = () => {
    history.push("/trading-profile/trading-account/");
  };


  const userDetails = {
    email: tradingAccSingle?.email ? tradingAccSingle?.email : "--",
    client_type: tradingAccSingle?.client_type ? tradingAccSingle?.client_type : "--",
    created_on: tradingAccSingle?.created_on ? tradingAccSingle?.created_on : "--",
    place_of_issue: tradingAccSingle?.place_of_issue ? tradingAccSingle?.place_of_issue : "--",
    dob: tradingAccSingle?.dob_doi ? tradingAccSingle?.dob_doi : "--",
    mobile_no: tradingAccSingle?.mobile ? tradingAccSingle?.mobile : "--",
    landline: tradingAccSingle?.landline ? tradingAccSingle?.landline : "--",
    citizenship: tradingAccSingle?.citizenship_pan ? tradingAccSingle?.citizenship_pan : "--",
    temporary_address: tradingAccSingle?.temporary_address ? tradingAccSingle?.temporary_address : "--",
    permanent_address: tradingAccSingle?.permanent_address ? tradingAccSingle?.permanent_address : "--",
    client_risk_category: tradingAccSingle?.client_category_risk ? tradingAccSingle?.client_category_risk : "--",
    branch: tradingAccSingle?.branch_code ? tradingAccSingle?.branch_code : "--",
    tags: `${tradingAccSingle?.tag?.map((item: { name: string }) => item.name).join(', ')}`
  };


  const clientFloorsheetColumn = [
    {
      title: "Symbol",
      dataIndex: "stock_symbol",
      key: "stock_symbol",
      render: (text: any, row: any, index: number) => (
        <Typography.Text>{text}</Typography.Text>
      ),
    },
    {
      title: "QTY",
      dataIndex: "quantity",
      key: "quantity",
      render: (text: any, row: any, index: number) => (
        <Typography.Text>{text}</Typography.Text>
      ),
    },

    {
      title: "Rate",
      dataIndex: "rate",
      key: "rate",
      render: (text: any, row: any, index: number) => (
        <Typography.Text>{text}</Typography.Text>
      ),
    },
    {
      title: "Total Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text: any, row: any, index: number) => (
        <Typography.Text>{text}</Typography.Text>
      ),
    },
    {
      title: "Transaction Type",

      render: (text: any, row: any, index: number) => (
        <Typography.Text>{row.buyer_broking_firm_code === "17" ? "BUY" : row.seller_broking_firm_code === "17" ? "SELL" : ""}</Typography.Text>
      ),
    },
    {
      title: "Date",
      dataIndex: "floorsheet_date",
      key: "floorsheet_date",
      render: (text: any, row: any, index: number) => (
        <Typography.Text>{text}</Typography.Text>
      ),
    },
  ]



  useEffect(() => {
    if (id) {
      dispatch(fetchTradingAccSingle(id))
    }
  }, [id, dispatch])

  useEffect(() => {
    if (tradingAccSingle.client_code) {
      dispatch(fetchTradingSingleTransactionList({
        filterData,
        client_code: tradingAccSingle.client_code
      }))
    }
  }, [tradingAccSingle.client_code, dispatch, filterData])


  return (
    <>
      <MyContent>
        <Suspense fallback={<Loading fullScreen />}>
          <PageLoading loading={tradingAccSingleLoading}>
            <Content className="floorsheet-single">
              <Row gutter={[20, 20]} align="middle" justify="space-between">
                <Col>
                  <Row gutter={[20, 20]} align="middle">
                    <Col>
                      <Button
                        onClick={backHandler}
                        icon={
                          <BackIcon
                            style={{ marginRight: 10, paddingTop: 3 }}
                          />
                        }
                        type="default"
                      >
                        Back
                      </Button>
                    </Col>
                    <Col>
                      <Typography.Title level={4} style={{ marginBottom: 0, textTransform: "capitalize" }}>
                        {tradingAccSingle?.client_name ? tradingAccSingle?.client_name.toLowerCase() : "--"}
                      </Typography.Title>
                    </Col>
                  </Row>
                </Col>

                <Col>
                  <Row gutter={[20, 20]} align="middle">
                    <Col>
                      <Button
                        type="default"
                        icon={<DownloadIcon style={{ fill: "gray" }} />}
                      // onClick={() => dispatch(downloadCustomerInfo(id))}
                      // loading={downloadingCustomerInfo}
                      >
                        Download User Info
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <PageHolder
                loading={false}
                empty={false}
                style={{ marginTop: 40 }}
              >
                <Row gutter={[60, 40]}>
                  <Col lg={24}>
                    <Card>
                      <Row
                        align="top"
                        justify="space-between"
                        style={{ marginBottom: "30px" }}
                      >
                        <Col>
                          <span
                            style={{
                              width: "120px",
                              height: "120px",
                              display: "block",
                              overflow: "hidden",
                              borderRadius: "100%",
                              border: "3px solid white",
                              background: "#f8f8f8",
                              boxShadow:
                                "-3px -3px 6px #ffffff, 3px 3px 6px #d3d3d3",
                            }}
                          >
                            <Image src={defaultAvatar} />
                          </span>
                          <div
                            style={{
                              marginBottom: 16
                            }}
                          >
                            <Typography.Title
                              level={3}
                              style={{ marginTop: 20, marginRight: 18, marginBottom: 8, textTransform: "capitalize" }}
                            >
                              {tradingAccSingle?.client_name ? tradingAccSingle?.client_name.toLowerCase() : "--"}
                            </Typography.Title>
                            <Typography.Text>{tradingAccSingle?.client_code ? tradingAccSingle?.client_code : "--"}  </Typography.Text>
                            {/* <Typography>  {tradingAccSingle?.tag.map((item: {name: string}) => item.name).join(", ")} </Typography> */}
                          </div>
                        </Col>
                        <Col span={12} push={8} >
                          <Button onClick={() => setShowModal(true)}> Add/Edit Tags </Button>

                        </Col>
                        <DescriptionCard data={userDetails} columns={4} />
                      </Row>
                    </Card>
                  </Col>{" "}
                </Row>
                <div className="client-floorsheet">
                  <Card>
                    <Row gutter={[16, 16]} align={"middle"} justify={"space-between"}>
                      <Col>
                        <Typography.Title level={4}>Client Floorsheet</Typography.Title>
                      </Col>
                      <Col>
                        <Button
                          icon={<DownloadIconDark />}
                          // onClick={() => setShowExportModal(true)}
                          className="export-btn"
                        >
                          <Typography.Text>{"Export"}</Typography.Text>
                        </Button>
                      </Col>
                    </Row>
                    <Row gutter={[20, 20]} align="middle" style={{ marginTop: 18 }} justify={"end"}>
                      <Col>

                        <Button
                          disabled={filterData.date_range === "last_7_days"}
                          onClick={() => setFilterData((prevState) => ({
                            ...prevState,
                            date_range: 'last_7_days'
                          }))}
                        >
                          {" "}
                          Last 7 Days{" "}
                        </Button>

                      </Col>
                      <Col>
                        <Button
                          disabled={filterData.date_range === "last_month"}
                          onClick={() => setFilterData((prevState) => ({
                            ...prevState,
                            date_range: 'last_month'
                          }))}
                        >
                          {" "}
                          Last one month{" "}
                        </Button>

                      </Col>
                      <Col>
                        <DatePicker.RangePicker
                          onChange={(dates) => {
                            if (dates) {
                              setFilterData((prevState) => ({
                                ...prevState,
                                date_range: null,
                                custom_date_before: dates
                                  ? dayjs(dates?.[0]?.toString()).format("YYYY-MM-DD")
                                  : "",
                                custom_date_after: dates
                                  ? dayjs(dates?.[1]?.toString()).format("YYYY-MM-DD")
                                  : "",
                                page: 1,
                              }));
                            } else {
                              setFilterData((prevState) => ({
                                ...prevState,
                                custom_date_before: null,
                                custom_date_after: null,
                              }));
                            }
                          }}
                        />
                      </Col>
                      <Col>
                        <Button type='ghost'
                          icon={<DownloadIconDark />}
                        // onClick={() => setShowExportModal(true)}
                        />
                      </Col>
                      <Col>
                        <Button type='ghost'
                          icon={<DeleteIcon />}
                        // onClick={() => setShowExportModal(true)}
                        />
                      </Col>
                      <Col md={24}>
                        <Table
                          rowKey="id"
                          columns={clientFloorsheetColumn}
                          dataSource={tradingSingleTransactionList}
                          loading={tradingSingleTransactionLoading}
                          pagination={{
                            current: Number(page),
                            total: parseInt(totalTradingSingleTransactionRecords),
                            onChange: (page: number) => {
                              setPage(page)
                              setFilterData((prevState) => ({
                                ...prevState,
                                offset: (page - 1) * filterData.limit
                              }));
                            },
                            showSizeChanger: true,
                            onShowSizeChange: (current, size) => {
                              setFilterData((prev) => ({
                                ...prev,
                                limit: size
                              }))

                            },
                          }}
                        />
                      </Col>
                    </Row>


                  </Card>
                </div>
              </PageHolder>

            </Content>
          </PageLoading>
        </Suspense>
      </MyContent>
      <AddTags setShowModal={setShowModal} showModal={showModal} />
    </>
  )
}
