import './styles.scss';

import React, {
  useEffect,
  useState,
} from 'react';

import {
  Card,
  Typography,
} from 'antd';
import { useDispatch } from 'react-redux';
import {
  fetchTradingAccList,
} from 'Redux/TradingRedux/TradingAccount/TradingAccountAction';

import { FilterComponent } from './Components/FilterComponent';
import { TableComponent } from './Components/TableComponent';

export interface TradingProfileListFilterProps {
  limit: number;
  date_range: "last_7_days" | "last_month" | null
  custom_date_after: string | null;
  custom_date_before: string | null;
  offset?: number
}

export const TradingAccount = () => {
  const dispatch = useDispatch();

  const [filterData, setFilterData] = useState<TradingProfileListFilterProps>({
    limit: 10,
    custom_date_after: null,
    custom_date_before: null,
    date_range: null
  });
  const [page, setPage] = useState<number>(1)



  useEffect(() => {

    dispatch(fetchTradingAccList(filterData))
  }, [filterData, dispatch])



  return (
    <Card>
      <Typography.Title level={3}>
        Accounts
      </Typography.Title>
      <FilterComponent filterData={filterData} setFilterData={setFilterData} page={page} setPage={setPage} />
      <TableComponent filterData={filterData} setFilterData={setFilterData} page={page} setPage={setPage} />
    </Card>
  );
};
