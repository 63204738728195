import React from 'react';

import {
  ReactComponent as Visualization,
} from 'Assets/svg/visualizationIcon.svg';

export const VisualizationIcon = () => {
  return (
    <Visualization />
  )
}
