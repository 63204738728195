import React from 'react';

import { useSelector } from 'react-redux';

import { LineChart } from './LineChart';

interface TotalTransactionProps {
    showExportBtn: boolean
}

export const TotalTransaction = ({ showExportBtn }: TotalTransactionProps) => {

    const {
        visualizationData

    } = useSelector((state: any) => state.tradingVisualizationReducer);


    return (
        <div className='total-transaction'>

            <LineChart showExportBtn={showExportBtn} visualizationData={visualizationData?.transaction_data}  title='Total Transaction' height={95} />

        </div>
    )
}
