import React, {
  useEffect,
  useState,
} from 'react';

import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  Select,
  Switch,
} from 'antd';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { useHistory } from 'react-router';
import { createGroup } from 'Redux/TradingRedux/GroupManagement/groupAction';
import {
  CreateGroupProps,
} from 'Redux/TradingRedux/GroupManagement/groupTypes';
import {
  fetchTradingAccList,
} from 'Redux/TradingRedux/TradingAccount/TradingAccountAction';
import { useDebouncedCallback } from 'use-debounce';

export const AddGroup = () => {
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const history = useHistory()


    const [filterData, setFilterData] = useState({
        limit: 100,
        search: ""
    });


    const {
        tradingAccListLoading,
        tradingAccList,
    } = useSelector((state: any) => state.tradingTradingAccReducer);

    const {
        loading,
        success
    } = useSelector((state: any) => state.tradingGroupReducer);




    const debounceSearch = useDebouncedCallback(
        (value) => {
            setFilterData((prevData) => ({
                ...prevData,
                search: value
            }))
        },
        500
    );

    useEffect(() => {
        dispatch(fetchTradingAccList(filterData))

    }, [dispatch, filterData])

    useEffect(() => {
        if (success) {

            history.push("/trading-profile/group-management")

        }
    }, [success, dispatch, history])


    const handleSubmit = (val: CreateGroupProps) => {
        let submitData = {
            name: val.name,
            email: val.email,
            contact: val.contact,
            members: val.members,
            type: val.type ? "Private" : 'Public'
        }

        dispatch(createGroup(submitData))

    }


    return (
        <Card>
            <Row gutter={[16, 16]}>
                <Col lg={16}>
                    <Form form={form} onFinish={handleSubmit} layout="vertical">
                        {/* <Row gutter={[16, 16]} > */}
                        <Col>
                            <Form.Item
                                label="Group Name"
                                name="name"
                                rules={[{ required: true, message: 'Group name is required' }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item
                                label="Group Property"
                                name="type"
                                // rules={[{ required: true, message: 'Please input your username!' }]}
                                valuePropName="checked"
                            >
                                <Switch checkedChildren="Private" unCheckedChildren="Public" />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item
                                label="Email Address"
                                name="email"
                                rules={[{ required: true, message: 'Email address is required' }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item
                                label="Contact Number"
                                name="contact"
                                rules={[{ required: true, message: 'Contact number is required' }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item
                                label="Add Trading Account ID"
                                name="members"
                                rules={[{ required: true, message: 'Members are required ' }]}
                            >
                                <Select
                                    filterOption={false}
                                    mode="multiple"
                                    allowClear
                                    style={{ width: '100%' }}
                                    placeholder="Please select"
                                    showSearch={true}
                                    loading={tradingAccListLoading}
                                    defaultActiveFirstOption={false}
                                    onSearch={(value: string) => {
                                        debounceSearch(value)
                                    }}

                                >

                                    {
                                        tradingAccList.length > 0 && tradingAccList.map((item: { client_name: string, id: string }) => {
                                            return (
                                                <Select.Option key={item.id} value={item.id}> {item.client_name} </Select.Option>
                                            )
                                        })
                                    }

                                </Select>
                            </Form.Item>
                        </Col>
                    </Form>
                </Col>
                <Col lg={8}>
                    <Row gutter={[16, 16]} justify={"end"}>
                        <Col lg={12}>
                            <Button htmlType="button" type="default" style={{
                                width: "100%"
                            }}
                                onClick={() => history.push("/trading-profile/group-management")}
                            >
                                Cancel
                            </Button>
                        </Col>
                        <Col lg={12}>
                            <Button htmlType="submit" type="primary" style={{
                                width: "100%"
                            }}
                                loading={loading}
                                onClick={() => form.submit()}
                            >
                                Add
                            </Button>
                        </Col>
                    </Row>


                </Col>

            </Row>
        </Card>
    )
}
