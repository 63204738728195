import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';

import {
  Button,
  Col,
  Form,
  Row,
  Select,
} from 'antd';
import { useWatch } from 'antd/lib/form/Form';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  fetchGroupDetailList,
  fetchGroupList,
} from 'Redux/TradingRedux/GroupManagement/groupAction';
import { fetchTagsList } from 'Redux/TradingRedux/Tags/TagsAction';
import {
  fetchTradingAccList,
} from 'Redux/TradingRedux/TradingAccount/TradingAccountAction';
import {
  fetchVisualizationData,
} from 'Redux/TradingRedux/visualization/visualizationAction';
import {
  FetchVisualizationDataProps,
} from 'Redux/TradingRedux/visualization/visualizationType';
import { useDebouncedCallback } from 'use-debounce';

interface LowLevelFilterProps {
    setVisualFilterData: Dispatch<SetStateAction<FetchVisualizationDataProps>>
    visualFilterData: FetchVisualizationDataProps
}

export const LowLevelFilter = ({ setVisualFilterData, visualFilterData }: LowLevelFilterProps) => {
    const [form] = Form.useForm()
    const dispatch = useDispatch()

    const {
        nepseList,
        nepseLoading,
        tickerDataLoading,
        tickerData,
        loading,
        success,
        alertDetail
    } = useSelector((state: any) => state.tradingAlertReducer);

    const {
        groupListData,
        groupListLoading
    } = useSelector((state: any) => state.tradingGroupReducer);

    const {
        tagsListLoading,
        tagsList,
        tagsListRecords,

    } = useSelector((state: any) => state.tradingTagsReducer);

    const {
        tradingAccListLoading,
        tradingAccList,
    } = useSelector((state: any) => state.tradingTradingAccReducer);

    const {
        groupDetailListData,
        groupDetailListLoading
    } = useSelector((state: any) => state.tradingGroupReducer);

    const [sectorOptions, setSectorOptions] = useState<any[]>([])
    const [symbolOptions, setSymbolOptions] = useState<any[]>([])
    const [individualOptions, setIndividualOptions] = useState<any[]>([])

    const [tagFilter, setTagFilter] = useState({
        limit: 100,
        search: ""
    })

    const [groupFilter, setGroupFilter] = useState({
        limit: 100,
        search: ""
    })
    const [tradingAccFilter, setTradingAccFilter] = useState({
        limit: 100,
        search: ""
    })

    const sectorVal = useWatch("sector", form)
    const groupVal = useWatch("group", form)

    const individualSearch = useDebouncedCallback(
        (value, key) => {
            if (key === "tags") {
                setTagFilter((prevData) => ({
                    ...prevData,
                    search: value
                }))
            }
            if (key === "group") {
                setGroupFilter((prevData) => ({
                    ...prevData,
                    search: value
                }))
            }

            if (key === "individual") {
                setTradingAccFilter((prevData) => ({
                    ...prevData,
                    search: value
                }))
            }

        },
        500
    );


    useEffect(() => {
        dispatch(fetchTagsList(tagFilter))
    }, [dispatch, tagFilter])

    useEffect(() => {
        dispatch(fetchGroupList(groupFilter))
    }, [dispatch, groupFilter])

    useEffect(() => {
        dispatch(fetchTradingAccList(tradingAccFilter))
    }, [dispatch, tradingAccFilter])


    useEffect(() => {
        if (nepseList.length > 0) {
            const setData = Array.from(new Set(nepseList.map((item: { sector: string }) => {
                return item.sector
            })))

            const data = setData.map((item,) => {
                return {
                    label: item,
                    value: item
                }
            })
            setSectorOptions(data)

        }
    }, [nepseList])


    useEffect(() => {
        if (sectorVal) {

            const filterSymbol = nepseList.filter((item: { sector: string }) => item.sector === sectorVal)
            setSymbolOptions(filterSymbol.map((item: { ticker_name: string, ticker: string }) => {
                return {
                    label: item.ticker_name,
                    value: item.ticker
                }
            }))

        }
    }, [sectorVal, nepseList])

    useEffect(() => {
        if (groupVal) {

            dispatch(fetchGroupDetailList(groupVal))
        }
    }, [groupVal, dispatch])


    const handleSubmit = (data: FetchVisualizationDataProps) => {

        let filterData: FetchVisualizationDataProps = {
            ...visualFilterData
        }

        if (data?.stock_symbol) {
            filterData.stock_symbol = data.stock_symbol
        }

        if (data?.tags) {
            filterData.tags = JSON.stringify(data.tags)
        }
        if (data?.group) {
            filterData.group = data.group
        }
        if(data?.individual) {
            filterData.individual = data.individual

        }
        if(data?.sector) {
            filterData.sector = data.sector

        }

        setVisualFilterData(filterData)

        dispatch(fetchVisualizationData(filterData))

    }


    return (
        <Form form={form} onFinish={handleSubmit} layout='vertical'>
            <Row gutter={[16, 30]} className='low-level-filter' align={"middle"}>
                <Col span={6}>
                    <Form.Item
                        label="Sector"
                        name="sector"
                    // rules={[{ required: true, message: 'Members are required ' }]}
                    >
                        <Select
                            filterOption={false}
                            style={{ width: "100%" }}
                            //   onChange={(val) => setUserId(val)}
                            showSearch
                            allowClear
                            defaultActiveFirstOption={true}
                            options={sectorOptions}
                        />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        label="Symbol"
                        name="stock_symbol"

                    >
                        <Select
                            value={visualFilterData?.stock_symbol}
                            style={{ width: "100%" }}
                            showSearch
                            loading={nepseLoading}
                            allowClear
                            options={sectorVal ? symbolOptions : nepseList?.map((item: { ticker_name: string, ticker: string }) => {
                                return {
                                    label: item.ticker_name,
                                    value: item.ticker
                                }
                            })}
                        />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        label="Group"
                        name="group"
                    >
                        <Select
                            filterOption={false}
                            style={{ width: "100%" }}
                            showSearch
                            allowClear
                            options={groupListData?.map((item: { id: string, name: string }) => {
                                return {
                                    label: item.name,
                                    value: item.id
                                }
                            })}
                            onSearch={(value: string) => {
                                individualSearch(value, "group")

                            }}
                        />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        label="Individual"
                        name="individual"
                    >
                        <Select
                            filterOption={false}
                            style={{ width: "100%" }}
                            showSearch
                            allowClear
                            options={groupVal ?

                                groupDetailListData?.map((item: { id: string, client_name: string }) => {
                                    return {
                                        label: item.client_name,
                                        value: item.id
                                    }
                                })
                                : tradingAccList?.map((item: { id: string, client_name: string }) => {
                                    return {
                                        label: item.client_name,
                                        value: item.id
                                    }
                                })}
                            onSearch={(value: string) => {
                                individualSearch(value, "individual")

                            }}
                        />
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        label="Tags"
                        name="tags"
                    >
                        <Select
                            filterOption={false}
                            style={{ width: "100%" }}
                            mode='multiple'
                            showSearch
                            allowClear
                            loading={tagsListLoading}
                            options={tagsList?.map((item: { id: string, name: string }) => {
                                return {
                                    label: item.name,
                                    value: item.id
                                }
                            })}
                            onSearch={(value: string) => {
                                individualSearch(value, "tags")

                            }}
                        />
                    </Form.Item>
                </Col>
                {/* <Col span={6}>
                    <Form.Item
                        label="Age"
                        name="age"
                    >
                        <Select
                            filterOption={false}
                            style={{ width: "100%" }}
                            showSearch
                            allowClear
                            defaultActiveFirstOption={true}
                            options={[
                                {
                                    label: "16-30",
                                    value: "16-30"
                                },
                                {
                                    label: "30-50",
                                    value: "30-50"
                                },
                                {
                                    label: "50 and more",
                                    value: "50 and more"
                                }
                            ]}
                        />
                    </Form.Item>
                </Col> */}
                <Col span={6}>
                    <Button type="primary" htmlType="submit"
                        className="primary--alt ">Filter</Button>
                </Col>
            </Row>
        </Form>
    )
}
