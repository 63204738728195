import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';

import {
  Col,
  Row,
  Table,
  Typography,
} from 'antd';
import TableActions from 'Components/TableAction/TableAction';
import dayjs from 'dayjs';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { useHistory } from 'react-router';
import {
  deleteFloorsheet,
  fetchFloorsheetList,
} from 'Redux/TradingRedux/Floorsheet/FloorsheetAction';

import { FloorsheetListFilterProps } from '../Floorsheet';
import { ReuploadComponent } from './ReuploadComponent';

interface FilterComponentProps {
  setFilterData: Dispatch<SetStateAction<FloorsheetListFilterProps>>;
  filterData: FloorsheetListFilterProps;
}

export const TableComponent = ({
  filterData,
  setFilterData,
}: FilterComponentProps) => {

  const history = useHistory()
  const dispatch = useDispatch()

  const {
    floorsheetListLoading,
    floorsheetList,
    totalFloorsheetListRecords,
    deleteFloorsheetSuccess
  } = useSelector((state: any) => state.tradingFloorsheetReducer);

  const [visible, setVisible] = useState({
    show: false,
    id: ""
  })

  const [page, setPage] = useState(1)


  const floorsheetColumns = [
    {
      title: "File Name",
      dataIndex: "floorsheet_file",
      key: "floorsheet_file",
      render: (text: any, row: any, index: number) => (
        <Typography.Text>{text.split("/").pop()}</Typography.Text>
      ),
    },
    {
      title: "Quantity",
      dataIndex: "total_quantity",
      key: "total_quantity",
      render: (text: any, row: any, index: number) => (
        <Typography.Text>{text}</Typography.Text>
      ),
    },
    {
      title: "Amount",
      dataIndex: "total_amount",
      key: "total_amount",
      render: (text: any, row: any, index: number) => (
        <Typography.Text>{text}</Typography.Text>
      ),
    },
    {
      title: "Transaction",
      dataIndex: "transaction",
      key: "transaction",
      render: (text: any, row: any, index: number) => (
        <Typography.Text>{text}</Typography.Text>
      ),
    },
    {
      title: "Date",
      dataIndex: "floorsheet_date",
      key: "floorsheet_date",
      render: (text: any, row: any, index: number) => (
        <Typography.Text>{dayjs(text).format("YYYY-MM-DD")}</Typography.Text>
      ),
    },
    {
      title: "",
      dataIndex: "id",
      key: "id",
      render: (text: any, row: any, index: number) => (
        <TableActions
          noReport
          onDelete={() => {
            dispatch(deleteFloorsheet(row.id))
          }}
          onEdit={() => console.log(row)}

          archieved={true}
          showDeleteBtn={
            true
          }
          showViewProfile={true}
          adminEdit={
            false
          }
          showReUpload={true}
          editTitle='Edit'
          deleteTitle='Delete'
          onReUpload={() => setVisible({
            show: true,
            id: row.id
          })}
          viewTitle='View'
          onViewDetails={() => history.push(`/trading-profile/floorsheet/transaction/${row.id}?date=${dayjs(row.floorsheet_date).format("YYYY-MM-DD")}`)}

        />
      ),
    },
  ];

  useEffect(() => {
    if (deleteFloorsheetSuccess) {
      dispatch(fetchFloorsheetList(filterData))

    }
  }, [deleteFloorsheetSuccess, dispatch, filterData])



  return (
    <>
      <Row
        style={{
          marginTop: 20,
        }}
      >
        <Col lg={24}>
          <Table
            rowKey="id"
            columns={floorsheetColumns}
            dataSource={floorsheetList}
            loading={floorsheetListLoading}
            pagination={{
              current: page,
              pageSize: filterData.limit,
              total: parseInt(totalFloorsheetListRecords),
              onChange: (page: number) => {
                setPage(page)
                setFilterData((prevState) => ({
                  ...prevState,
                  offset: (page - 1) * filterData.limit
                }));
              },
              showSizeChanger: true,
              onShowSizeChange: (current, size) => {
                setFilterData((prev) => ({
                  ...prev,
                  limit: size
                }))

              },
            }}
          />
        </Col>
      </Row>
      <ReuploadComponent visible={visible} setVisible={setVisible} />
    </>
  );
};
