import './styles.scss';

import React, {
  useEffect,
  useState,
} from 'react';

import {
  Card,
  Typography,
} from 'antd';
import { useDispatch } from 'react-redux';
import {
  fetchFloorsheetList,
} from 'Redux/TradingRedux/Floorsheet/FloorsheetAction';

import { FilterComponent } from './Components/FilterComponent';
import { TableComponent } from './Components/TableComponent';

export interface FloorsheetListFilterProps {
  limit: number;
  // page: number;
  floorsheet_date: string
}

export const Floorsheet = () => {
  const dispatch = useDispatch();

  const [filterData, setFilterData] = useState<FloorsheetListFilterProps>({
    limit: 10,
    floorsheet_date :""
    // page: 1,
    // payment_mode: "",
    // username: "",
    // transaction_date_after: "",
    // transaction_date_before: "",
  });

  useEffect(() => {
    dispatch(fetchFloorsheetList(filterData))
  }, [filterData, dispatch])



  return (
    <Card>
      <Typography.Title level={3}>
        Floorsheet
      </Typography.Title>
      <FilterComponent filterData={filterData} setFilterData={setFilterData} />
      <TableComponent filterData={filterData} setFilterData={setFilterData} />
    </Card>
  );
};
