import {
  AlertCreateProps,
  CREATE_ALERT,
  CREATE_ALERT_FAILURE,
  CREATE_ALERT_SUCCESS,
  DELETE_ALERT,
  DELETE_ALERT_FAILURE,
  DELETE_ALERT_SUCCESS,
  FETCH_ALERT_DETAIL,
  FETCH_ALERT_DETAILS_FAILURE,
  FETCH_ALERT_DETAILS_SUCCESS,
  FETCH_ALERT_LIST,
  FETCH_ALERT_LIST_FAILURE,
  FETCH_ALERT_LIST_SUCCESS,
  FETCH_NEPSE_LIST,
  FETCH_NEPSE_LIST_FAILURE,
  FETCH_NEPSE_LIST_SUCCESS,
  FETCH_NEPSE_TICKER_DATA,
  FETCH_NEPSE_TICKER_DATA_FAILURE,
  FETCH_NEPSE_TICKER_DATA_SUCCESS,
  FetchAlertListProps,
  UPDATE_ALERT,
  UPDATE_ALERT_FAILURE,
  UPDATE_ALERT_SUCCESS,
} from './alertTypes';

export const fetchNepseList = () => ({
    type: FETCH_NEPSE_LIST
})

export const fetchNepseListSuccess = (data: any) => ({
    type: FETCH_NEPSE_LIST_SUCCESS,
    payload: data
})
export const fetchNepseListFailure = () => ({
    type: FETCH_NEPSE_LIST_FAILURE
})

export const fetchNepseTickerData = (symbol: string) => ({
    type: FETCH_NEPSE_TICKER_DATA,
    payload: symbol
})

export const fetchNepseTickerDataSuccess = (data: any) => ({
    type: FETCH_NEPSE_TICKER_DATA_SUCCESS,
    payload: data
})
export const fetchNepseTickerDataFailure = () => ({
    type: FETCH_NEPSE_TICKER_DATA_FAILURE
})

export const fetchAlertList = (data: FetchAlertListProps) => ({
    type: FETCH_ALERT_LIST,
    payload: data
})

export const fetchAlertListSuccess = (data: any) => ({
    type: FETCH_ALERT_LIST_SUCCESS,
    payload: data
})
export const fetchAlertListFailure = () => ({
    type: FETCH_ALERT_LIST_FAILURE
})

export const fetchAlertDetail = (id: string) => ({
    type: FETCH_ALERT_DETAIL,
    payload: id
})

export const fetchAlertDetailSuccess = (data: any) => ({
    type: FETCH_ALERT_DETAILS_SUCCESS,
    payload: data
})

export const fetchAlertDetailFailure = () => ({
    type: FETCH_ALERT_DETAILS_FAILURE
})

export const deleteAlert = (id: string) => ({
    type: DELETE_ALERT,
    payload: id
})

export const deleteAlertSuccess = () => ({
    type: DELETE_ALERT_SUCCESS
})

export const deleteAlertFailure = () => ({
    type: DELETE_ALERT_FAILURE
})

export const createAlert = (data: AlertCreateProps) => ({
    type: CREATE_ALERT,
    payload: data
})

export const createAlertSuccess = () => ({
    type: CREATE_ALERT_SUCCESS
})

export const createAlertFailure = () => ({
    type: CREATE_ALERT_FAILURE
})

export const updateAlert = ({ id, data }: { id: string, data: AlertCreateProps }) => ({
    type: UPDATE_ALERT,
    payload: { id, data }
})

export const updateAlertSuccess = () => ({
    type: UPDATE_ALERT_SUCCESS
})

export const updateAlertFailure = () => ({
    type: UPDATE_ALERT_FAILURE
})