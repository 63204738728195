import React, {
  Dispatch,
  SetStateAction,
  useEffect,
} from 'react';

import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Switch,
} from 'antd';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { useParams } from 'react-router';
import {
  fetchGroupDetail,
  updateGroup,
} from 'Redux/TradingRedux/GroupManagement/groupAction';
import {
  CreateGroupProps,
} from 'Redux/TradingRedux/GroupManagement/groupTypes';

interface EditMembersProps {
    isEdit: boolean
    setIsEdit: Dispatch<SetStateAction<boolean>>
}

export const EditMembers = ({ isEdit, setIsEdit }: EditMembersProps) => {
    const [form] = Form.useForm()
    const dispatch = useDispatch()
    const { id } = useParams<{ id: string }>()


    const {
        groupDetailData,
        success,
        loading
    } = useSelector((state: any) => state.tradingGroupReducer);

    useEffect(() => {
        if (groupDetailData) {
            form.setFieldValue("name", groupDetailData?.name)
            form.setFieldValue("email", groupDetailData?.email)
            form.setFieldValue("contact", groupDetailData?.contact)
            form.setFieldValue("type", groupDetailData?.type === "Public" ? false : "Private")
        }
    }, [groupDetailData, form])

    useEffect(() => {
        if (id && success) {
            dispatch(fetchGroupDetail(id))
            setIsEdit(false)
        }
    }, [success, id, dispatch])

    const handleSubmit = (val: CreateGroupProps) => {
        let submitData = {
            name: val.name,
            email: val.email,
            contact: val.contact,
            type: val.type ? "Private" : 'Public'
        }

        dispatch(updateGroup(id, submitData))
    }

    return (
        <Row gutter={[16, 16]}>
            <Col lg={16}>
                <Form form={form} onFinish={handleSubmit} layout="vertical">
                    {/* <Row gutter={[16, 16]} > */}
                    <Col>
                        <Form.Item
                            label="Group Name"
                            name="name"
                            rules={[{ required: true, message: 'Group name is required' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            label="Group Property"
                            name="type"
                            // rules={[{ required: true, message: 'Please input your username!' }]}
                            valuePropName="checked"
                        >
                            <Switch checkedChildren="Private" unCheckedChildren="Public" />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            label="Email Address"
                            name="email"
                            rules={[{ required: true, message: 'Email address is required' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            label="Contact Number"
                            name="contact"
                            rules={[{ required: true, message: 'Contact number is required' }]}
                        >
                            <Input />
                        </Form.Item>
                    </Col>

                </Form>
            </Col>
            <Col lg={8}>
                <Row gutter={[16, 16]} justify={"end"}>
                    <Col lg={12}>
                        <Button htmlType="button" type="default" style={{
                            width: "100%"
                        }}
                            onClick={() => setIsEdit(false)}
                        >
                            Cancel
                        </Button>
                    </Col>
                    <Col lg={12}>
                        <Button htmlType="submit" type="primary" style={{
                            width: "100%"
                        }}
                            loading={loading}
                            onClick={() => form.submit()}
                        >
                            Save
                        </Button>
                    </Col>
                </Row>


            </Col>

        </Row>
    )
}
