import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';

import {
  Button,
  Form,
  Select,
} from 'antd';
import Modal from 'Components/Modal';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { useParams } from 'react-router';
import { fetchTagsList } from 'Redux/TradingRedux/Tags/TagsAction';
import {
  fetchTradingAccSingle,
  updateTradingAccSingle,
} from 'Redux/TradingRedux/TradingAccount/TradingAccountAction';
import { useDebouncedCallback } from 'use-debounce';

interface AddTagsProps {
    showModal: boolean,
    setShowModal: Dispatch<SetStateAction<boolean>>
}

export const AddTags = ({ showModal, setShowModal }: AddTagsProps) => {
    const dispatch = useDispatch()
    const { id } = useParams<{ id: string }>()
    const [form] = Form.useForm()

    const {
        tagsListLoading,
        tagsList,

    } = useSelector((state: any) => state.tradingTagsReducer);

    const {
        tradingAccSingle,
        loading,
        success
    } = useSelector((state: any) => state.tradingTradingAccReducer);

    const [tagsFilter, setTagsFilter] = useState({
        limit: 100,
        search: ''
    })

    const selectSearch = useDebouncedCallback(
        (value) => {
            setTagsFilter((prevData) => ({
                ...prevData,
                search: value
            }))
        },
        500
    );

    const handleSubmit = (val: any) => {
        dispatch(updateTradingAccSingle({ id: id, data: val }))
    }


    useEffect(() => {
        if (showModal) {

            dispatch(fetchTagsList(tagsFilter))
        }

    }, [dispatch, tagsFilter, showModal])

    useEffect(() => {
        if (showModal && tradingAccSingle?.tag?.length > 0) {
            form.setFieldValue("tag", tradingAccSingle?.tag.map((item: { id: string }) => item.id))

        } else {
            form.resetFields()
        }
    }, [tradingAccSingle?.tag, form, showModal])

    useEffect(() => {
        if (success && id) {
            form.resetFields()
            dispatch(fetchTradingAccSingle(id))
            setShowModal(false)

        }
    }, [success, dispatch, id, setShowModal, form])

    return (
        <Modal
            visible={showModal}
            width={"50vw"}
            onCancel={() => {
                setShowModal(false)
            }}
            maskClosable={false}

        >
            <div>

                <Form form={form} layout='vertical' onFinish={handleSubmit}>
                    <Form.Item name={"tag"} label="Tags">
                        <Select
                            filterOption={false}
                            mode='multiple'
                            style={{ width: "100%" }}
                            loading={tagsListLoading}
                            placeholder="Tags"
                            showSearch={true}
                            showArrow={true}
                            onSearch={(val) => selectSearch(val)}
                            options={
                                tagsList?.map((item: { name: string, id: string }) => {
                                    return {
                                        label: item.name,
                                        value: item.id
                                    }
                                })
                            } />
                    </Form.Item>
                    <Button loading={loading} type='primary' htmlType='submit'>Save</Button>
                </Form>

            </div>
        </Modal>
    )
}
