import './styles.scss';

import React, {
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  Button,
  Card,
  Col,
  Form,
  Input,
  InputRef,
  Row,
  Table,
  Typography,
} from 'antd';
import TableActions from 'Components/TableAction/TableAction';
import dayjs from 'dayjs';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  Link,
  useHistory,
} from 'react-router-dom';
import {
  deleteGroup,
  fetchGroupList,
} from 'Redux/TradingRedux/GroupManagement/groupAction';

export const GroupManagement = () => {
  const dispatch = useDispatch()
  const history = useHistory()



  const {
    groupListData,
    groupListLoading,
    groupListCount,
    success
  } = useSelector((state: any) => state.tradingGroupReducer);


  //state
  const [filterData, setFilterData] = useState({
    limit: 10,
    offset: 0,
    search: ""
  })
  const [page, setPage] = useState(1)

  const searchRef = useRef<InputRef>(null)
  const [isDelete, setIsDelete] = useState(false)



  useEffect(() => {
    dispatch(fetchGroupList(filterData))
  }, [dispatch, filterData,])

  useEffect(() => {
    if (success && isDelete) {
      dispatch(fetchGroupList(filterData))

    }
  }, [success, isDelete, dispatch, filterData])


  //table column
  const columns = [
    {
      title: "S.N",
      dataIndex: "id",
      key: "id",
      render: (row: any, text: any, index: number) => {
        if (page && filterData.limit) {
          return (
            <Typography.Text>
              {(page - 1) * filterData.limit + index + 1}
            </Typography.Text>
          );
        }
      },
    },

    {
      title: "Group Name",
      dataIndex: "name",
      key: "name",
      render: (text: any, row: any, index: number) => (
        <Link to={`/trading-profile/group-management/${row.id}/list`}>
          <Typography.Text
          >{text}</Typography.Text>
        </Link>

      ),
    },

    {
      title: "Members",
      dataIndex: "member_count",
      key: "member_count",
      render: (text: any, row: any, index: number) => (
        <Typography.Text
        >{text}</Typography.Text>
      ),
    },
    {
      title: "Created Date ",
      dataIndex: "created_at",
      key: "created_at",
      render: (text: any, row: any, index: number) => (
        <Typography.Text>{text ? dayjs(text).format("YYYY-MM-DD") : "-"}</Typography.Text>
      ),
    },
    {
      title: "",
      dataIndex: "id",
      key: "id",
      render: (text: any, row: any, index: number) => (
        <TableActions
          noReport
          onDelete={() => {
            dispatch(deleteGroup(row.id))
            setIsDelete(true)


          }}
          archieved={true}
          showDeleteBtn={
            true
          }
          showViewProfile={true}
          adminEdit={
            false
          }
          editTitle='Edit'
          deleteTitle='Delete'
          viewTitle='View'
          onViewDetails={() => {
            history.push(`/trading-profile/group-management/${row.id}/list`)
          }}

        />
      ),
    },
  ];



  return (
    <Card className='group-mgmt'>
      <Row gutter={[16, 16]} justify={"space-between"} align={"middle"} className='header'>
        <Col xs={24}>
          <Typography.Title level={3}>
            Groups
          </Typography.Title>
        </Col>
        <Col xs={24}>
          <Row gutter={[16, 16]} justify={"space-between"} align={"middle"}>
            <Col>
              <Form>
                <div className="d-flex align-items-center search">
                  <Input.Search ref={searchRef} placeholder='Search by name'/>
                  <Button
                    htmlType="submit"
                    type="primary"
                    className="primary--alt search-btn"
                    onClick={() => {
                      setPage(1)
                      setFilterData((prevData) => ({
                        ...prevData,
                        search: searchRef?.current?.input?.value || "",
                        offset: 0
                      })
                      )
                    }}
                  >
                    Search
                  </Button>
                </div>
              </Form>
            </Col>
            <Col>
              <Button type='primary' onClick={() => history.push(`/trading-profile/group-management/add`)}> Add New group </Button>
            </Col>
          </Row>
        </Col>

      </Row>
      <div>
        <div className='table-list'>
          <Table
            rowKey="id"
            columns={columns}
            dataSource={groupListData}
            loading={groupListLoading}
            pagination={{
              current: Number(page),
              total: parseInt(groupListCount),
              onChange: (page: number) => {
                setPage(page)
                setFilterData((prevState) => ({
                  ...prevState,
                  offset: (page - 1) * filterData.limit
                }));
              },
              showSizeChanger: true,
              onShowSizeChange: (current, size) => {
                setPage(page)
                setFilterData((prev) => ({
                  ...prev,
                  limit: size
                }))

              },
              pageSize: filterData.limit,
            }}
          />
        </div>
      </div>
    </Card>
  )
}
