import React, { useState } from 'react';

import {
  Button,
  Dropdown,
  Menu,
  Row,
  Space,
  Spin,
  Typography,
  Upload,
} from 'antd';
import { RcFile } from 'antd/lib/upload';
import Modal from 'Components/Modal';
import ArrowDownBoldIcon from 'Icons/ArrowDownBoldIcon/ArrowDownBoldIcon';
import { DownloadIconDark } from 'Icons/DownloadIcon/DownloadIcon';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  resetUploadCdscFileStateValue,
} from 'Redux/DpRedux/Transaction/TransactionAction';
import {
  resetUploadTradingAccFileStateValue,
  uploadTradingAccFile,
} from 'Redux/TradingRedux/TradingAccount/TradingAccountAction';
import { dummyRequest } from 'Utils/DummyCall';

import { TradingProfileListFilterProps } from '../TradingAccount';

const importType = [
  {
    key: "tradingAcc",
    type: "Import Trading Account",
  },
];

interface ImportComponentProps {
  filterData: TradingProfileListFilterProps;
}

export const ImportComponent = ({ filterData }: ImportComponentProps) => {
  const dispatch = useDispatch();

  const [showImportModal, setShowImportModal] = useState({
    visible: false,
    key: "",
  });


  const {
    uploadFileError,
    uploadFileSuccess,
    uploadFileLoading,
    uploadFileSuccessData
  } = useSelector((state: any) => state.tradingTradingAccReducer);

  const menuImport = (
    <Menu
      onClick={(e) => {
        setShowImportModal({
          visible: true,
          key: e.key,
        });
      }}
    >
      {importType.map((item: { key: string; type: string }) => {
        return (
          <Menu.Item key={item.key}>
            <Typography.Text type="secondary">{item.type}</Typography.Text>
          </Menu.Item>
        );
      })}
    </Menu>
  );

  const renderModalComp = () => {
    switch (true) {
      case uploadFileError:
        return (
          <>
            <Typography.Title type="danger" level={4}>
              Import Failed!
            </Typography.Title>
            <Row>
              <Button
                style={{
                  marginRight: 20,
                }}
                onClick={() => {
                  dispatch(resetUploadTradingAccFileStateValue());
                  setShowImportModal({
                    visible: false,
                    key: "",
                  });
                }}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                onClick={() => dispatch(resetUploadTradingAccFileStateValue())}
              >
                Reupload File
              </Button>
            </Row>
          </>
        );

      case uploadFileSuccess:
        return (
          <>
            <Typography.Title type="success" level={4}>
              successfully imported
            </Typography.Title>
            <Typography.Text>
              {" "}
              Newly added account- {uploadFileSuccessData.total_created}{" "}
            </Typography.Text>
            <Typography.Text>
              {" "}
              Update account- {uploadFileSuccessData.total_updated}{" "}
            </Typography.Text>
          </>
        );

      default:
        return (
          <Spin spinning={uploadFileLoading}>
            <Upload
              customRequest={dummyRequest}
              beforeUpload={(file: RcFile) => {
                const formData = new FormData();

                formData.append("file", file);
                dispatch(uploadTradingAccFile(formData));
              }}
              accept=".xlsx, application/vnd.ms-excel"
            >
              <Space direction="vertical" align="center" size={"large"}>
                <DownloadIconDark />
                <Typography.Text>Upload .xls or .xlsx file</Typography.Text>
              </Space>
            </Upload>
          </Spin>
        );
    }
  };

  return (
    <>
      <Dropdown trigger={["click"]} overlay={menuImport}>
        <Row align="middle" justify="space-between">
          <Typography.Text style={{ textTransform: "capitalize" }}>
            {"Import"}
          </Typography.Text>
          <ArrowDownBoldIcon />
        </Row>
      </Dropdown>
      <Modal
        visible={showImportModal.visible}
        width={"30vw"}
        onCancel={() => {
          dispatch(resetUploadCdscFileStateValue());
          setShowImportModal({
            visible: false,
            key: "",
          });
        }}
      >
        <Space
          direction="vertical"
          align="center"
          size={"large"}
          style={{
            width: "100%",
          }}
        >
          {renderModalComp()}
        </Space>
      </Modal>
    </>
  );
};
