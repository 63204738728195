import './styles.scss';

import React, { useEffect } from 'react';

import { Card } from 'antd';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import {
  fetchGroupDetail,
  fetchGroupDetailList,
} from 'Redux/TradingRedux/GroupManagement/groupAction';

import { HeaderComponent } from './Components/HeaderComponent';
import { TableComponent } from './Components/TableComponent';

export const SingleGroupList = () => {
    const { id } = useParams<{ id: string }>()
    const dispatch = useDispatch()


    useEffect(() => {
        if (id) {
            dispatch(fetchGroupDetailList(id))
            dispatch(fetchGroupDetail(id))
        }
    }, [id, dispatch])


    return (
        <Card className='single-group-list'>
            <HeaderComponent />
            <TableComponent />
        </Card>
    )
}
