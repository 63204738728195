import { message } from 'antd';
import axios, { AxiosResponse } from 'axios';
import {
  all,
  call,
  fork,
  put,
  StrictEffect,
  takeLatest,
} from 'redux-saga/effects';

import {
  fetchFloorsheetTransactionListFailure,
  fetchFloorsheetTransactionListSuccess,
} from './FloorsheetTransactionAction';
import { fetchFloorsheetTransactionListApi } from './FloorsheetTransactionApi';
import {
  FETCH_FLOORSHEET_TRANSACTION_LIST,
} from './FloorsheetTransactionTypes';

//fetch api list

const fetchFloorsheetTransactionListAsync = async (data: any) => await fetchFloorsheetTransactionListApi(data);

function* fetchFloorsheetTransactionList(params: any) {
    const { payload } = params;
    try {
        const response: AxiosResponse = yield call(
            fetchFloorsheetTransactionListAsync,
            payload
        );
        if (response) {
            
            yield put(fetchFloorsheetTransactionListSuccess(response.data.data));
        }

    } catch (error) {
        if (axios.isAxiosError(error)) {
            const messages = error?.response?.data?.error ?? "Something went wrong";
            yield put(fetchFloorsheetTransactionListFailure());
            message.error(messages);
        } else {
            yield put(fetchFloorsheetTransactionListFailure());

        }


    }
}

function* watchFetchFloorsheetTransactionList() {
    yield takeLatest(FETCH_FLOORSHEET_TRANSACTION_LIST, fetchFloorsheetTransactionList);
}

export default function* TradingFloorsheetTransactionSaga(): Generator<StrictEffect> {
    yield all([fork(watchFetchFloorsheetTransactionList)]);
    // yield all([fork(watchFetchTradingListAcc)]);
    // yield all([fork(watchFetchTradingSingleAcc)]);
}