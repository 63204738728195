import { Auth } from 'aws-amplify';
import axios from 'axios';
import config from 'Config/config';

import { CreateGroupProps } from './groupTypes';

axios.defaults.baseURL = `${config.dpApi}`;

const getToken = async () => {
    return (await Auth.currentSession()).getIdToken().getJwtToken();
};


export const fetchGroupListApi = async (data:any) => {
    return axios.get(`groups/list`, {
        params: data,
        headers: {
            Authorization: `Bearer ${await getToken()}`,
        },
    });
}

export const createGroupApi = async (data: CreateGroupProps) => {
    return axios.post(`groups/create`, data, {
        headers: {
            Authorization: `Bearer ${await getToken()}`,
        },
    });
}

export const updateGroupApi = async ({ id, data }: { id: string, data: CreateGroupProps }) => {
    return axios.patch(`groups/update/${id}`, data, {
        headers: {
            Authorization: `Bearer ${await getToken()}`,
        },
    });
}

export const fetchGroupDetailListApi = async (id: string) => {
    return axios.get(`groups/members/${id}`, {
        headers: {
            Authorization: `Bearer ${await getToken()}`,
        },
    });
}

export const fetchGroupDetailApi = async (id: string) => {
    return axios.get(`groups/details/${id}`, {
        headers: {
            Authorization: `Bearer ${await getToken()}`,
        },
    });
}

export const deleteGroupApi = async (id: string) => {
    return axios.delete(`groups/delete/${id}`, {
        headers: {
            Authorization: `Bearer ${await getToken()}`,
        },
    });
}

