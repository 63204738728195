export const FETCH_GROUP_LIST = "FETCH_GROUP_LIST"
export const FETCH_GROUP_LIST_SUCCESS = "FETCH_GROUP_LIST_SUCCESS"
export const FETCH_GROUP_LIST_FAILURE = "FETCH_GROUP_LIST_FAILURE"

export const FETCH_GROUP_DETAIL = "FETCH_GROUP_DETAIL"
export const FETCH_GROUP_DETAIL_SUCCESS = "FETCH_GROUP_DETAIL_SUCCESS"
export const FETCH_GROUP_DETAIL_FAILURE = "FETCH_GROUP__DETAIL_FAILURE"

export const FETCH_GROUP_DETAIL_LIST = "FETCH_GROUP_DETAIL_LIST"
export const FETCH_GROUP_DETAIL_LIST_SUCCESS = "FETCH_GROUP_DETAIL_LIST_SUCCESS"
export const FETCH_GROUP__DETAIL_LIST_FAILURE = "FETCH_GROUP__DETAIL_LIST_FAILURE"

export const CREATE_GROUP = "CREATE_GROUP"
export const CREATE_GROUP_SUCCESS = "CREATE_GROUP_SUCCESS"
export const CREATE_GROUP_FAILURE = "CREATE_GROUP_FAILURE"

export const UPDATE_GROUP = "UPDATE_GROUP"
export const UPDATE_GROUP_SUCCESS = "UPDATE_GROUP_SUCCESS"
export const UPDATE_GROUP_FAILURE = "UPDATE_GROUP_FAILURE"

export const DELETE_GROUP = "DELETE_GROUP"
export const DELETE_GROUP_SUCCESS = "DELETE_GROUP_SUCCESS"
export const DELETE_GROUP_FAILURE = "DELETE_GROUP_FAILURE"


export interface CreateGroupProps {
    name?: string,
    type?: string,
    email?: string,
    contact?: string,
    members?: string[]
}