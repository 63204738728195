import { message } from 'antd';
import axios, { AxiosResponse } from 'axios';
import {
  all,
  call,
  fork,
  put,
  StrictEffect,
  takeLatest,
} from 'redux-saga/effects';

import {
  fetchTradingAccListFailure,
  fetchTradingAccListSuccess,
  fetchTradingAccSingleFailure,
  fetchTradingAccSingleSuccess,
  fetchTradingSingleTransactionListFailure,
  fetchTradingSingleTransactionListSuccess,
  updateTradingAccSingleFailure,
  updateTradingAccSingleSuccess,
  uploadTradingAccFileFailure,
  uploadTradingAccFileSuccess,
} from './TradingAccountAction';
import {
  fetchTradingAccListApi,
  fetchTradingAccSingleApi,
  fetchTradingAccSingleTransactionApi,
  updateTradingAccSingleApi,
  uploadTradingAccFileApi,
} from './TradingAccountApi';
import {
  FETCH_TRADING_ACC_LIST,
  FETCH_TRADING_ACC_SINGLE,
  FETCH_TRADING_ACC_SINGLE_TRANSACTION,
  FetchTradingAccListProps,
  FetchTradingSingleTransactionListProps,
  UPDATE_TRADING_ACC_SINGLE,
  UpdateTradingSingle,
  UPLOAD_TRADING_ACC_FILE,
} from './TradingAccountTypes';

//upload dpa file

const uploadFileAsync = async (data: any) => await uploadTradingAccFileApi(data);

function* uploadTradingAccFile(params: any) {
    const { payload } = params;
    try {
        const response: AxiosResponse = yield call(
            uploadFileAsync,
            payload
        );
        if (response) {
            yield put(uploadTradingAccFileSuccess(response.data.data));
        }

    } catch (error) {
        if (axios.isAxiosError(error)) {
            const messages = error?.response?.data?.error ?? "Something went wrong";
            yield put(uploadTradingAccFileFailure());
            message.error(messages);
        } else {
            yield put(uploadTradingAccFileFailure());

        }


    }
}

function* watchUploadTradingAccFile() {
    yield takeLatest(UPLOAD_TRADING_ACC_FILE, uploadTradingAccFile);
}

//fetch api list

const fetchTradingAccListAsync = async (data: FetchTradingAccListProps) => await fetchTradingAccListApi(data);

function* fetchTradingListAcc(params: any) {
    const { payload } = params;
    try {
        const response: AxiosResponse = yield call(
            fetchTradingAccListAsync,
            payload
        );
        if (response) {
            yield put(fetchTradingAccListSuccess(response.data.data));
        }

    } catch (error) {
        if (axios.isAxiosError(error)) {
            const messages = error?.response?.data?.error ?? "Something went wrong";
            yield put(fetchTradingAccListFailure());
            message.error(messages);
        } else {
            yield put(fetchTradingAccListFailure());

        }


    }
}

function* watchFetchTradingListAcc() {
    yield takeLatest(FETCH_TRADING_ACC_LIST, fetchTradingListAcc);
}

//fetch api single

const fetchTradingAccSingleAsync = async (data: string) => await fetchTradingAccSingleApi(data);

function* fetchTradingSingleAcc(params: any) {
    const { payload } = params;
    try {
        const response: AxiosResponse = yield call(
            fetchTradingAccSingleAsync,
            payload
        );
        if (response) {
            yield put(fetchTradingAccSingleSuccess(response.data.data));
        }

    } catch (error) {
        if (axios.isAxiosError(error)) {
            const messages = error?.response?.data?.error ?? "Something went wrong";
            yield put(fetchTradingAccSingleFailure());
            message.error(messages);
        } else {
            yield put(fetchTradingAccSingleFailure());

        }

    }
}

function* watchFetchTradingSingleAcc() {
    yield takeLatest(FETCH_TRADING_ACC_SINGLE, fetchTradingSingleAcc);
}

//fetch api single transaction

const fetchTradingAccSingleTransactionAsync = async (data: FetchTradingSingleTransactionListProps) => await fetchTradingAccSingleTransactionApi(data);

function* fetchTradingSingleAccTransaction(params: any) {
    const { payload } = params;
    try {
        const response: AxiosResponse = yield call(
            fetchTradingAccSingleTransactionAsync,
            payload
        );
        if (response) {
            yield put(fetchTradingSingleTransactionListSuccess(response.data.data));
        }

    } catch (error) {
        if (axios.isAxiosError(error)) {
            const messages = error?.response?.data?.error ?? "Something went wrong";
            yield put(fetchTradingSingleTransactionListFailure());
            message.error(messages);
        } else {
            yield put(fetchTradingSingleTransactionListFailure());

        }

    }
}

function* watchFetchTradingSingleAccTransaction() {
    yield takeLatest(FETCH_TRADING_ACC_SINGLE_TRANSACTION, fetchTradingSingleAccTransaction);
}


const updateTradingAccSingleAsync = async ({ id, data }: { id: string, data: UpdateTradingSingle }) => await updateTradingAccSingleApi({ id, data });

function* updateTradingSingleAcc(params: any) {
    const { payload } = params;
    try {
        const response: AxiosResponse = yield call(
            updateTradingAccSingleAsync,
            payload
        );
        if (response) {
            message.success(response.data.message)
            yield put(updateTradingAccSingleSuccess());
        }

    } catch (error) {
        if (axios.isAxiosError(error)) {
            const messages = error?.response?.data?.error ?? "Something went wrong";
            yield put(updateTradingAccSingleFailure());
            message.error(messages);
        } else {
            yield put(updateTradingAccSingleFailure());

        }


    }
}

function* watchUpdateTradingSingleAcc() {
    yield takeLatest(UPDATE_TRADING_ACC_SINGLE, updateTradingSingleAcc);
}

export default function* TradingTradingAccSaga(): Generator<StrictEffect> {
    yield all([fork(watchUploadTradingAccFile)]);
    yield all([fork(watchFetchTradingListAcc)]);
    yield all([fork(watchFetchTradingSingleAcc)]);
    yield all([fork(watchFetchTradingSingleAccTransaction)]);
    yield all([fork(watchUpdateTradingSingleAcc)]);
}