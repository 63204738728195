import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';

import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Switch,
} from 'antd';
import { useWatch } from 'antd/lib/form/Form';
import { DatePicker } from 'Components/DatePicker';
import dayjs from 'dayjs';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  useHistory,
  useParams,
} from 'react-router';
import {
  createAlert,
  fetchAlertDetail,
  fetchNepseList,
  fetchNepseTickerData,
  updateAlert,
} from 'Redux/TradingRedux/alert/alertAction';
import { AlertCreateProps } from 'Redux/TradingRedux/alert/alertTypes';
import { fetchGroupList } from 'Redux/TradingRedux/GroupManagement/groupAction';
import {
  fetchTradingAccList,
} from 'Redux/TradingRedux/TradingAccount/TradingAccountAction';
import { useDebouncedCallback } from 'use-debounce';

interface AddEditAlertProps {
    setCreateStatus: Dispatch<SetStateAction<boolean>>

}

interface FromValues {
    alert_name: string
    symbol: string
    ltp: string
    alert_type: string
    user_type: string
    take_profit?: string
    stop_loss?: string
    lower_bid?: string
    higher_bid?: string
    message: string
    validity: string
    alert_property: string
    alert_pause: string
    alert_completed?: boolean
    members?: string
    individual?: string,
    buy_value: string
    sell_value: string
}

export const AddEditAlert = ({ setCreateStatus }: AddEditAlertProps) => {

    const dispatch = useDispatch()
    const history = useHistory()
    const [form] = Form.useForm<FromValues>()

    const { formType, id } = useParams<{ formType: string, id: string }>()


    const user_type = useWatch("user_type", form)
    const alert_type = useWatch("alert_type", form)
    const buy_value = useWatch("buy_value", form)
    const sell_value = useWatch("sell_value", form)
    const alert_property = useWatch("alert_property", form)
    const alert_pause = useWatch("alert_pause", form)

    const individualSearch = useDebouncedCallback(
        (value) => {
            setFilterData((prevData) => ({
                ...prevData,
                search: value
            }))
        },
        500
    );



    const {
        nepseList,
        nepseLoading,
        tickerDataLoading,
        tickerData,
        loading,
        success,
        alertDetail
    } = useSelector((state: any) => state.tradingAlertReducer);
    const {
        groupListData,
        groupListLoading
    } = useSelector((state: any) => state.tradingGroupReducer);

    const {
        tradingAccListLoading,
        tradingAccList,
    } = useSelector((state: any) => state.tradingTradingAccReducer);

    const [filterData, setFilterData] = useState({
        limit: 100,
        search: ""
    });

    useEffect(() => {
        dispatch(fetchNepseList())
        form.setFieldValue("user_type", "individual")
        form.setFieldValue("alert_type", "Buy")
    }, [dispatch, form])





    useEffect(() => {
        if (user_type === "individual") {
            dispatch(fetchTradingAccList(filterData))

        }
        if (user_type === "group") {

            dispatch(fetchGroupList(filterData))
        }
    }, [dispatch, user_type, filterData])

    useEffect(() => {
        if (id && formType === "edit") {
            dispatch(fetchAlertDetail(id))
        }
    }, [id, formType, dispatch])

    useEffect(() => {
        if (success) {
            history.push('/trading-profile/price-alert')
        }
    }, [success, history])




    useEffect(() => {
        if (formType === "edit" && Object.keys(alertDetail).length > 0) {
            console.log("show alert det", alertDetail);
            form.setFieldValue("alert_name", alertDetail?.name)
            form.setFieldValue("symbol", alertDetail?.symbol)
            form.setFieldValue("alert_type", alertDetail?.alert_type)
            form.setFieldValue("message", alertDetail?.message)
            form.setFieldValue("alert_property", alertDetail?.alert_property === "Private" ? true : false)
            form.setFieldValue("alert_pause", alertDetail?.pause_alert === "Private" ? true : false)
            form.setFieldValue("validity", dayjs(alertDetail?.validity))
            form.setFieldValue("alert_completed", alertDetail?.status)

            if (alertDetail?.client) {
                form.setFieldValue("individual", alertDetail?.client.id)
                form.setFieldValue("user_type", "individual")
            }
            if (alertDetail?.group) {
                form.setFieldValue("members", alertDetail?.group.id)
                form.setFieldValue("user_type", "group")
            }

            if (alertDetail?.high_bid) {
                form.setFieldValue("buy_value", false)
                form.setFieldValue("higher_bid", alertDetail?.high_bid)
            }
            if (alertDetail?.low_bid) {
                form.setFieldValue("buy_value", true)
                form.setFieldValue("lower_bid", alertDetail?.low_bid)
            }

            if (alertDetail?.stop_loss) {
                form.setFieldValue("sell_value", true)
                form.setFieldValue("stop_loss", alertDetail?.stop_loss)
            }
            if (alertDetail?.take_profit) {
                form.setFieldValue("sell_value", false)
                form.setFieldValue("take_profit", alertDetail?.take_profit)
            }


            dispatch(fetchNepseTickerData(alertDetail?.symbol))


        }
    }, [formType, alertDetail, dispatch, form])




    useEffect(() => {
        if (Object.keys(tickerData).length) {
            form.setFieldValue('ltp', tickerData.latest_price)
        }
    }, [tickerData, form])


    const handleSelect = (val: string) => {

        dispatch(fetchNepseTickerData(val))

    }

    const handleSubmit = (data: FromValues) => {

        let postData: AlertCreateProps = {
            name: data.alert_name,
            alert_type: data.alert_type,
            message: data.message,
            symbol: data.symbol,
            validity_date: dayjs(data.validity).format("YYYY-MM-DD"),
            alert_property: data.alert_property ? "Private" : "Public",
            pause_alert: data.alert_pause ? "Private" : "Public",
            status: data.alert_completed ? true : false
        }

        if (data.user_type === "individual") {
            postData = {
                ...postData,
                client: data?.individual || null,
            }
        } else {
            postData = {
                ...postData,
                group: data?.members || null,
            }
        }

        if (data.alert_type === "Buy") {
            if (buy_value) {
                postData = {
                    ...postData,
                    low_bid: data?.lower_bid || null,
                }
            } else {
                postData = {
                    ...postData,
                    high_bid: data?.higher_bid || null,
                }
            }

        } else {
            if (sell_value) {
                postData = {
                    ...postData,
                    stop_loss: data?.stop_loss || null,
                }
            } else {
                postData = {
                    ...postData,
                    take_profit: data?.take_profit || null,
                }
            }

        }

        if (formType === "edit") {
            dispatch(updateAlert({ id: id, data: postData }))
        } else {

            dispatch(createAlert(postData))
        }


    }


    return (
        <Card>
            <Row gutter={[16, 16]} >
                <Col lg={16}>
                    <Form form={form} onFinish={handleSubmit} layout="vertical" className='add-form'>
                        {/* <Row gutter={[16, 16]} > */}
                        <Col>
                            <Form.Item
                                label="User Type"
                                name="user_type"
                                rules={[{ required: true, message: 'Alert type is required' }]}
                            >
                                <Radio.Group size='large' className='radioGroup'>
                                    <Radio.Button className='radioBtn' value="individual"> Individual </Radio.Button>
                                    <Radio.Button className='radioBtn' value="group">Group</Radio.Button>
                                </Radio.Group>

                            </Form.Item>
                        </Col>
                        <Col>
                            {
                                user_type === "group" ?

                                    <Form.Item
                                        label="Group"
                                        name="members"
                                        rules={[{ required: true, message: 'Members are required ' }]}
                                    >
                                        <Select
                                            filterOption={false}
                                            allowClear
                                            style={{ width: '100%' }}
                                            placeholder="Please select"
                                            showSearch={true}
                                            // searchValue={filterData.search}
                                            loading={groupListLoading}
                                            defaultActiveFirstOption={false}
                                            options={
                                                groupListData.map((item: { name: string, id: string }) => {
                                                    return {
                                                        label: item.name,
                                                        value: item.id
                                                    }
                                                })
                                            }
                                            onSearch={(value: string) => {
                                                individualSearch(value)

                                            }}

                                        >



                                        </Select>
                                    </Form.Item>
                                    : <Form.Item
                                        label="Individual"
                                        name="individual"
                                        rules={[{ required: true, message: 'Members are required ' }]}
                                    >
                                        <Select
                                            filterOption={false}
                                            allowClear
                                            style={{ width: '100%' }}
                                            placeholder="Please select"
                                            showSearch={true}
                                            // searchValue={filterData.search}
                                            loading={tradingAccListLoading}
                                            defaultActiveFirstOption={false}
                                            options={
                                                tradingAccList.map((item: { client_name: string, id: string }) => {
                                                    return {
                                                        label: item.client_name,
                                                        value: item.id
                                                    }
                                                })
                                            }
                                            onSearch={(value: string) => {
                                                individualSearch(value)

                                            }}

                                        >



                                        </Select>
                                    </Form.Item>
                            }

                        </Col>
                        <Col>
                            <Form.Item
                                label="Alert Name"
                                name="alert_name"

                                rules={[{ required: true, message: 'Alert Name is required' }]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item
                                label="Symbol"
                                name="symbol"
                                rules={[{ required: true, message: 'Symbol is required' }]}
                            >
                                <Select
                                    allowClear
                                    showSearch
                                    style={{ width: '100%' }}
                                    placeholder="Please select"
                                    loading={nepseLoading}
                                    options={nepseList?.map((item: { ticker_name: string, ticker: string }) => {
                                        return {
                                            label: item.ticker_name,
                                            value: item.ticker
                                        }
                                    })}
                                    onChange={(val: string) => handleSelect(val)}

                                />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item
                                label="LTP"
                                name="ltp"
                                rules={[{ required: true, message: 'Alert Name is required' }]}
                            >
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item
                                label="Alert Type"
                                name="alert_type"
                                rules={[{ required: true, message: 'Alert type is required' }]}
                            >
                                <Radio.Group >
                                    <Radio.Button value="Buy">Buy</Radio.Button>
                                    <Radio.Button value="Sell"> Sell </Radio.Button>
                                </Radio.Group>

                            </Form.Item>
                        </Col>
                        <Col>
                            {
                                alert_type === "Buy" ?

                                    <>

                                        <Col>
                                            <Form.Item
                                                // label="Alert Property"
                                                name="buy_value"
                                                valuePropName="checked"
                                            >
                                                <Switch checked={buy_value ? true : false} checkedChildren="Lower bid" unCheckedChildren="higher" />

                                            </Form.Item>
                                        </Col>

                                        {buy_value ?

                                            <Form.Item
                                                label="Lower Bid"
                                                name="lower_bid"

                                                rules={[{ required: true, message: 'Value is required' }]}
                                            >
                                                <Input />
                                            </Form.Item>
                                            :

                                            <Form.Item
                                                label="Higher Bid"
                                                name="higher_bid"

                                                rules={[{ required: true, message: 'Value is required' }]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        }



                                    </>
                                    : <>
                                        <Col>
                                            <Form.Item
                                                // label="Alert Property"
                                                name="sell_value"
                                            >
                                                <Switch checked={sell_value ? true : false} checkedChildren="Stop Loss" unCheckedChildren="Take Profit" />

                                            </Form.Item>
                                        </Col>
                                        {
                                            sell_value ?

                                                <Form.Item
                                                    label="Stop Loss"
                                                    name="stop_loss"

                                                // rules={[{ required: true, message: 'Value is required' }]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                                : <Form.Item
                                                    label="Take Profit"
                                                    name="take_profit"

                                                // rules={[{ required: true, message: 'Value is required' }]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                        }


                                    </>
                            }

                        </Col>

                        <Col>
                            <Form.Item
                                label="Message"
                                name="message"
                                rules={[{ required: true, message: 'Message is required' }]}
                            >
                                <Input.TextArea />
                            </Form.Item>
                        </Col>

                        <Col>
                            <Form.Item
                                label="Validity Date"
                                name="validity"
                                rules={[{ required: true, message: 'Alert Name is required' }]}
                            >
                                <DatePicker />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item
                                label="Alert Property"
                                name="alert_property"
                            >
                                <Switch checked={alert_property ? true : false} checkedChildren="Private" unCheckedChildren="Public" />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item
                                label="Pause Alert"
                                name="alert_pause"
                            >
                                <Switch checked={alert_pause ? true : false} checkedChildren="On" unCheckedChildren="Off" />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item
                                label="Mark as Completed"
                                name="alert_completed"
                                valuePropName="checked"
                            >
                                <Checkbox />
                            </Form.Item>
                        </Col>
                    </Form>
                </Col>
                <Col lg={8}>
                    <Row gutter={[16, 16]} justify={"end"}>
                        <Col lg={12}>
                            <Button htmlType="button" type="default" style={{
                                width: "100%"
                            }}
                                onClick={() => {
                                    form.resetFields()
                                    history.push('/trading-profile/price-alert')
                                }}
                            >
                                Cancel
                            </Button>
                        </Col>
                        <Col lg={12}>
                            <Button htmlType="submit" type="primary" loading={loading} style={{
                                width: "100%"
                            }} onClick={() => form.submit()}>
                                {formType === "edit" ? "Save" : "Add"}
                            </Button>
                        </Col>
                    </Row>


                </Col>

            </Row>
        </Card>
    )
}
