export const FETCH_NEPSE_LIST = "FETCH_NEPSE_LIST"
export const FETCH_NEPSE_LIST_SUCCESS = "FETCH_NEPSE_LIST_SUCCESS"
export const FETCH_NEPSE_LIST_FAILURE = "FETCH_NEPSE_LIST_FAILURE"

export const FETCH_NEPSE_TICKER_DATA = "FETCH_NEPSE_TICKER_DATA"
export const FETCH_NEPSE_TICKER_DATA_SUCCESS = "FETCH_NEPSE_TICKER_DATA_SUCCESS"
export const FETCH_NEPSE_TICKER_DATA_FAILURE = "FETCH_NEPSE_TICKER_DATA_FAILURE"


export const FETCH_ALERT_LIST = "FETCH_ALERT_LIST"
export const FETCH_ALERT_LIST_SUCCESS = "FETCH_ALERT_LIST_SUCCESS"
export const FETCH_ALERT_LIST_FAILURE = "FETCH_ALERT_LIST_FAILURE"

export const FETCH_ALERT_DETAIL = "FETCH_ALERT_DETAIL"
export const FETCH_ALERT_DETAILS_SUCCESS = "FETCH_ALERT_DETAILS_SUCCESS"
export const FETCH_ALERT_DETAILS_FAILURE = "FETCH_ALERT_DETAILS_FAILURE"


export const DELETE_ALERT = "DELETE_ALERT"
export const DELETE_ALERT_SUCCESS = "DELETE_ALERT_SUCCESS"
export const DELETE_ALERT_FAILURE = "DELETE_ALERT_FAILURE"

export const CREATE_ALERT = "CREATE_ALERT"
export const CREATE_ALERT_SUCCESS = "CREATE_ALERT_SUCCESS"
export const CREATE_ALERT_FAILURE = "CREATE_ALERT_FAILURE"

export const UPDATE_ALERT = "UPDATE_ALERT"
export const UPDATE_ALERT_SUCCESS = "UPDATE_ALERT_SUCCESS"
export const UPDATE_ALERT_FAILURE = "UPDATE_ALERT_FAILURE"



export interface FetchAlertListProps {
    limit?: number;
    offset?: number;

}

export interface AlertCreateProps {
    name: string,
    alert_type: string,
    message: string,
    symbol: string,
    validity_date: string,
    alert_property: string,
    pause_alert: string,
    group?: string | null,
    client?: string | null,
    status: boolean,
    high_bid?: string | null,
    low_bid?: string | null,
    stop_loss?: string | null,
    take_profit?: string | null
}