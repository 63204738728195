import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';

import {
  Col,
  Row,
  Typography,
} from 'antd';
import CompanyLogo from 'Icons/CompanyLogo/CompanyLogo';
import { useSelector } from 'react-redux';
import {
  FetchVisualizationDataProps,
} from 'Redux/TradingRedux/visualization/visualizationType';

import { Quantity } from './Components/Quantity';
import { SectorWise } from './Components/SectorWise';
import { StockWise } from './Components/StockWise';
import { TotalTransaction } from './Components/TotalTransaction';
import { TurnOver } from './Components/TurnOver';

interface VisualizationPdfProps {
    setVisualFilterData: Dispatch<SetStateAction<FetchVisualizationDataProps>>
    visualFilterData: FetchVisualizationDataProps
}

interface FilterViewProps {
    date?: string
    group?: string
    user?: string
    tags?: string
    sector?: string
    symbol?: string
}

export const VisualizationPdf = ({ visualFilterData }: VisualizationPdfProps) => {

    const [filterView, setFilterView] = useState<FilterViewProps>({})


    const {
        tagsList,

    } = useSelector((state: any) => state.tradingTagsReducer);
    const {
        groupListData,
    } = useSelector((state: any) => state.tradingGroupReducer);

    const {
        tradingAccList,
    } = useSelector((state: any) => state.tradingTradingAccReducer);



    useEffect(() => {
        let filterName: FilterViewProps = {}
        if (groupListData && visualFilterData.group) {
            const groupName = groupListData.find((item: { id: string }) => item.id === visualFilterData.group)?.name || ""
            filterName.group = groupName
        }

        if (tradingAccList && visualFilterData?.individual) {
            const individualName = tradingAccList.find((item: { id: string }) => item.id === visualFilterData.individual)?.client_name || ""
            filterName.user = individualName
        }

        if (tagsList && visualFilterData?.tags) {
            const parsedTags = JSON.parse(visualFilterData?.tags)
            if (parsedTags.length > 0) {
                const filterTags = tagsList.filter((item: { id: string }) => parsedTags.includes(item.id))

                filterName.tags = filterTags.map((item: { name: string }) => item.name).join(", ")

            }
        }

        if (visualFilterData?.date_range) {
            filterName.date = visualFilterData?.date_range
        }
        if (!visualFilterData?.date_range && visualFilterData?.custom_date_after && visualFilterData.custom_date_before) {
            filterName.date = `From ${visualFilterData?.custom_date_before} to ${visualFilterData?.custom_date_after}`
        }

        if (visualFilterData?.sector) {
            filterName.sector = visualFilterData?.sector
        }
        if (visualFilterData?.stock_symbol) {
            filterName.symbol = visualFilterData?.stock_symbol
        }

        setFilterView(filterName)

    }, [visualFilterData, groupListData, tradingAccList, tagsList])

    console.log("fiter view", filterView);


    return (
        <div id='visualization_pdf' className='visualization_pdf' style={{
            display: 'block',

        }}>

            <div className='logo'>
                <CompanyLogo collapsed={false} />
            </div>
            <div className='filters'>
                <div className='d-flex align-items-center date'>
                    <Typography.Title level={5} className='label'>Date Range:</Typography.Title>
                    <Typography> {filterView?.date} </Typography>
                </div>
                <div className='d-flex align-items-center date'>
                    <Typography.Title level={5} className='label'>Filter Applied:</Typography.Title>
                    <Typography>
                        {filterView?.sector && `Sector-${filterView?.sector}, `} 
                        {filterView?.symbol && `Symbol-${filterView?.symbol}, `}
                        {filterView?.group && `Group-${filterView?.group}, `}
                        {filterView?.user && `User-${filterView?.user}, `}
                        {filterView?.tags && `Tags-${filterView?.tags}`}
                    </Typography>
                </div>
            </div>

            <TotalTransaction showExportBtn={false} />
            <Row gutter={[32, 32]}>
                <Col md={12}>
                    <TurnOver showExportBtn={false} />
                </Col>
                <Col md={12}>
                    <Quantity showExportBtn={false} />
                </Col>
            </Row>
            <SectorWise showExportBtn={false} />
            <StockWise showExportBtn={false} />
        </div>
    )
}
