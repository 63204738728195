import { Reducer } from 'react';

import {
  CREATE_TAGS,
  CREATE_TAGS_FAILURE,
  CREATE_TAGS_SUCCESS,
  DELETE_TAGS,
  DELETE_TAGS_FAILURE,
  DELETE_TAGS_SUCCESS,
  FETCH_TAGS_LIST,
  FETCH_TAGS_LIST_FAILURE,
  FETCH_TAGS_LIST_SUCCESS,
  UPDATE_TAGS,
  UPDATE_TAGS_FAILURE,
  UPDATE_TAGS_SUCCESS,
} from './TagsType';

const INIT_STATE = {
    tagsListLoading: false,
    tagsList: [],
    tagsListRecords: 0,
    loading: false,
    success: false

};

export const tradingTags: Reducer<any, any> = (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_TAGS_LIST:
            return {
                ...state,
                tagsListLoading: true,
                tagsList: [],
                success: false
            }

        case FETCH_TAGS_LIST_SUCCESS:
            return {
                ...state,
                tagsListLoading: false,
                tagsList: action.payload.results,
                tagsListRecords: action.payload.count,

            }
        case FETCH_TAGS_LIST_FAILURE:
            return {
                ...state,
                tagsListLoading: false,
                tagsList: [],
                tagsListRecords: 0
            }

        case CREATE_TAGS:
        case DELETE_TAGS:
        case UPDATE_TAGS:
            return {
                ...state,
                loading: true,
                success: false
            }
        case CREATE_TAGS_SUCCESS:
        case DELETE_TAGS_SUCCESS:
        case UPDATE_TAGS_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true
            }
        case CREATE_TAGS_FAILURE:
        case DELETE_TAGS_FAILURE:
        case UPDATE_TAGS_FAILURE:
            return {
                ...state,
                loading: false,
                success: false
            }
        default:
            return { ...state };

    }
}