import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  Button,
  Col,
  Form,
  Input,
  InputRef,
  Row,
} from 'antd';
import { AntFormItem } from 'Components/AntForm/AntForm';
import { DatePicker } from 'Components/DatePicker';
import dayjs from 'dayjs';
import queryString from 'querystring';
import { useHistory } from 'react-router';

import { FloorsheetTransactionListFilterProps } from '../FloorsheetTransaction';

interface FilterComponentProps {
  setFilterData: Dispatch<SetStateAction<FloorsheetTransactionListFilterProps>>;
  filterData: FloorsheetTransactionListFilterProps;
  page: number,
  setPage: Dispatch<SetStateAction<number>>

}



export const FilterComponent = ({
  filterData,
  setFilterData,
  page,
  setPage
}: FilterComponentProps) => {

  const [selectedDateRange, setSelecteDDateRange] = useState<any>(null)


  const history = useHistory()

  const parsed = queryString.parse(window.location.search);

  const searchRef = useRef<InputRef>(null)





  useEffect(() => {
    if (parsed["?date"]) {
      setSelecteDDateRange(parsed["?date"])

    }
  }, [parsed])



  return (
    <div>
  
      <Row gutter={[20, 20]} align="middle" justify={"space-between"}>
        <Col >
          <Form>
            <Row gutter={[20, 20]} align="middle">
              <Col>
                <AntFormItem name="username" style={{ marginBottom: 0 }}>
                  <Input.Search ref={searchRef} placeholder='Search by ID' />
                </AntFormItem>
              </Col>
              <Col>
                <Button htmlType='submit' type="primary" className="primary--alt"
                  onClick={() => {
                    setPage(1)
                    setFilterData((prevData) => ({
                      ...prevData,
                      search: searchRef.current?.input?.value,
                      offset: 0,
                    }))
                  }}>
                  Search
                </Button>
              </Col>

            </Row>
          </Form>
        </Col>
        <Col>
          <Row gutter={[20, 20]} align="middle">
            <Col>

              <Button
                disabled={filterData.date_range === "last_7_days"}
                onClick={() => {
                  setPage(1)
                  setFilterData((prevState) => ({
                    ...prevState,
                    date_range: 'last_7_days',
                    single_date: ""
                  }))
                }}
              >
                {" "}
                Last 7 Days{" "}
              </Button>

            </Col>
            <Col>
              <Button
                disabled={filterData.date_range === "last_month"}
                onClick={() => {
                  setPage(1)
                  setFilterData((prevState) => ({
                    ...prevState,
                    date_range: 'last_month',
                    single_date: ""
                  }))
                }}
              >
                {" "}
                Last one month{" "}
              </Button>

            </Col>
            <Col>
              <DatePicker value={selectedDateRange ? dayjs(selectedDateRange) : null} onChange={(date) => {
                if (!date) {
                  setPage(1)
                  setSelecteDDateRange(null)
                  setFilterData((prevState) => ({
                    ...prevState,
                    single_date: null,
                  }))
                  history.push('/trading-profile/floorsheet/transaction')
                } else {
                  setPage(1)
                  setSelecteDDateRange(dayjs(date))
                  setFilterData((prevState) => ({
                    ...prevState,
                    single_date: dayjs(date).format("YYYY-MM-DD"),
                    date_range: null,
                  }))
                }
              }} />
            </Col>
          </Row>
        </Col>

      </Row>

    </div>
  );
};
