import { Auth } from 'aws-amplify';
import axios from 'axios';
import config from 'Config/config';

import { FetchFloorsheetListProps } from './FloorsheetTypes';

axios.defaults.baseURL = `${config.dpApi}`;

const getToken = async () => {
    return (await Auth.currentSession()).getIdToken().getJwtToken();
};

export const uploadFloorsheetFileApi = async (data: any) => {    

    let url = ``

    if(data.id) {
       url = `trading/floorsheet/file/update/${data.id}` 
    }else {
        url = `trading/floorsheet/file/upload`
    }

    return axios.post(url, data.formData, {
        headers: {
            Authorization: `Bearer ${await getToken()}`,
        },
    });
}

export const fetchFloorsheetListApi = async (data: FetchFloorsheetListProps) => {
    return axios.get(`trading/floorsheet/list`, {
        params: data,
        headers: {
            Authorization: `Bearer ${await getToken()}`,
        },
    });
}

export const deleteFloorsheetApi = async (id: string) => {
    return axios.delete(`trading/floorsheet/delete/${id}`, {
        headers: {
            Authorization: `Bearer ${await getToken()}`,
        },
    });
}

