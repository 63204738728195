import './TableAction.scss';

import {
  Dropdown,
  Menu,
  Modal,
  Spin,
} from 'antd';
import ArrowDownBoldIcon from 'Icons/ArrowDownBoldIcon/ArrowDownBoldIcon';
import DeleteIcon from 'Icons/DeleteIcon/DeleteIcon';
import ProfileIcon from 'Icons/ProfileIcon/ProfileIcon';
import TickSquareIcon from 'Icons/TickSquareIcon/TickSquareIcon';
import { defaultFunction } from 'Redux/Constants';
import { useTheme } from 'styled-components';
import { ThemeVariables } from 'Themes/Theme';

import {
  ExclamationCircleOutlined,
  MoreOutlined,
} from '@ant-design/icons';

export interface TableActionProps {
  onDelete?: (e: any) => void;
  onEdit?: (e: any) => void;
  onReport?: (e: any) => void;
  onSuspend?: (e: any) => void;
  onUnSuspend?: (e: any) => void;
  onViewDetails?: (e: any) => void;
  onReUpload?: (e: any) => void;
  noReport?: boolean;
  module?: string;
  hidden?: boolean;
  download?: boolean;
  onDownload?: (e: any) => void;
  onArchieve?: (e: any) => void;
  archieved?: boolean;
  showDeleteBtn?: boolean;
  adminEdit?: boolean;
  showSuspend?: boolean;
  showUnSuspend?: boolean;
  showViewProfile?: boolean;
  downloadLoader?: boolean;
  editTitle?: string
  deleteTitle?: string,
  showReUpload?: boolean,
  viewTitle?: string
}

export const TableActions: React.FC<TableActionProps> = ({
  onEdit = defaultFunction,
  onDelete = defaultFunction,
  onReport = defaultFunction,
  onDownload = defaultFunction,
  onSuspend = defaultFunction,
  onUnSuspend = defaultFunction,
  download,
  noReport,
  module,
  archieved,
  hidden,
  onArchieve = defaultFunction,
  onViewDetails = defaultFunction,
  showDeleteBtn = true,
  adminEdit = false,
  showSuspend = false,
  showUnSuspend = false,
  showViewProfile = true,
  downloadLoader = false,
  editTitle,
  deleteTitle,
  showReUpload = false,
  onReUpload,
  viewTitle
}) => {
  const theme: ThemeVariables = useTheme();
  const confirm = () => {
    Modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure want to delete?",
      okText: "Confirm Delete",
      okButtonProps: { type: "primary", danger: true },
      cancelText: "Cancel",
      onOk: onDelete,
    });
  };

  const Archiveconfirm = () => {
    Modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure want to archive?",
      okText: "Confirm Archive",
      okButtonProps: { type: "primary", danger: true },
      cancelText: "Cancel",
      onOk: onArchieve,
    });
  };

  const Suspendconfirm = () => {
    Modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure want to block this user?",
      okText: "Block",
      okButtonProps: { type: "primary", danger: true },
      cancelText: "Cancel",
      onOk: onSuspend,
    });
  };

  const UnSuspendconfirm = () => {
    Modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure want to un block this user?",
      okText: "UnBlock",
      okButtonProps: { type: "primary", danger: true },
      cancelText: "Cancel",
      onOk: onSuspend,
    });
  };

  const OverlayContent = () => (
    <Menu>
      {download && (
        <Spin spinning={downloadLoader}>
          <Menu.Item onClick={onDownload} icon={<TickSquareIcon />} key="1">
            Download User Info
          </Menu.Item>
        </Spin>
      )}

      {!archieved && (
        <Menu.Item
          onClick={() => Archiveconfirm()}
          icon={<DeleteIcon />}
          key="2"
        >
          Archive User
        </Menu.Item>
      )}

      {adminEdit && (
        <Menu.Item onClick={onEdit} icon={<ProfileIcon />} key="3">
          {editTitle ? editTitle : "Edit User"}
        </Menu.Item>
      )}

      {showDeleteBtn && (
        <Menu.Item onClick={() => confirm()} icon={<DeleteIcon />} key="4">
          {deleteTitle ? deleteTitle : "Delete User"}
        </Menu.Item>
      )}
      {showViewProfile && (
        <Menu.Item onClick={onViewDetails} icon={<ProfileIcon />} key="5">
          {viewTitle ? viewTitle : "View Profile"}
        </Menu.Item>
      )}

      {showSuspend && (
        <Menu.Item onClick={Suspendconfirm} icon={<ProfileIcon />} key="5">
          Block User
        </Menu.Item>
      )}

      {showUnSuspend && (
        <Menu.Item onClick={UnSuspendconfirm} icon={<ProfileIcon />} key="5">
          UnBlock User
        </Menu.Item>
      )}
      {showReUpload && (
        <Menu.Item onClick={onReUpload} icon={
          <ArrowDownBoldIcon style={{ marginRight: 10, width: 15, height: 16 }} />
        } key="5">
          Re Upload
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <>
      {/* <OverlayContent /> */}
      <Dropdown
        className="no-height table--action"
        disabled={hidden}
        overlay={OverlayContent}
        placement="bottomLeft"
        trigger={["click"]}
      >
        <MoreOutlined rotate={90} style={{ fontSize: 24 }} />
      </Dropdown>
    </>
  );
};

export default TableActions;
