import dayjs, { Dayjs } from 'dayjs';

// const diffInMs   = new Date(endDate) - new Date(startDate)
// const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

export function disabledDate(currentDate: Dayjs) {
  return currentDate > dayjs() || currentDate < dayjs().subtract(90, "day");
}

export function disabledDateValidation(current: Dayjs) {
  // Can not select days before today and today
  const startingDate = dayjs("1943-04-14");
  const endingDate = dayjs();

  return (startingDate.isBefore(current) && endingDate.isAfter(current));

}

export const disableFutureDates = (current: Dayjs): boolean => {
  // Disable dates after today
  return current && current > dayjs().endOf('day');
};

export function scheduleKycDateValidation(current:Dayjs) {
  return current && (current.isBefore(dayjs()) || current.day() === 6)
}

const range = (start: number, end: number) => {
  const result = [];
  for (let i = start; i < end; i++) {
    result.push(i);
  }
  result.splice(10, 7)
  return result 
};

export function scheduleKycTimeValidation() {
  return (
    {
      disabledHours: () => range(0, 24)
    }
  )
}