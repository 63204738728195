import { Auth } from 'aws-amplify';
import axios from 'axios';

import config from '../../../Config/config';

axios.defaults.baseURL = `${config.dpApi}`;

const getToken = async () => {
    return (await Auth.currentSession()).getIdToken().getJwtToken();
};

export const fetchFloorsheetTransactionListApi = async (data: any) => {
    let url = ``
    if(data.id) {
        url = `trading/transactions/by/floorsheet/${data.id}`
    }else {
        url = `trading/transactions/list`
    }    
    return axios.get(url, {
        params: data.filterData,
        headers: {
            Authorization: `Bearer ${await getToken()}`,
        },
    });
}