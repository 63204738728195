import React, {
  Dispatch,
  SetStateAction,
} from 'react';

import {
  Col,
  Row,
  Table,
  Typography,
} from 'antd';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { TradingProfileListFilterProps } from '../TradingAccount';

interface FilterComponentProps {
  setFilterData: Dispatch<SetStateAction<TradingProfileListFilterProps>>;
  filterData: TradingProfileListFilterProps;
  page: number,
  setPage: Dispatch<SetStateAction<number>>
}

export const TableComponent = ({
  filterData,
  setFilterData,
  page,
  setPage
}: FilterComponentProps) => {


  const {
    tradingAccListLoading,
    tradingAccList,
    totalTradingAccListRecords
  } = useSelector((state: any) => state.tradingTradingAccReducer);




  const floorsheetColumns = [
    {
      title: "S.N",

      render: (row: any, text: any, index: number) => {
        if (page && filterData.limit) {
          return (
            <Typography.Text>
              {(page - 1) * filterData.limit + index + 1}
            </Typography.Text>
          );
        }
      },
    },
    {
      title: "Client Name",
      dataIndex: "client_name",
      key: "client_name",
      render: (text: any, row: any, index: number) => (
        <Link to={`/trading-profile/trading-account/${row.id}`}>
          <Typography.Text>{text}</Typography.Text>
        </Link>
      ),
    },
    {
      title: "Client Code",
      dataIndex: "client_code",
      key: "client_code",
      render: (text: any, row: any, index: number) => (
        <Typography.Text >{text}</Typography.Text>
      ),
    },
    {
      title: "Mobile No.",
      dataIndex: "mobile",
      key: "mobile",
      render: (text: any, row: any, index: number) => (
        <Typography.Text>{text ? text : "--"}</Typography.Text>
      ),
    },
    {
      title: "Email ID",
      dataIndex: "email",
      key: "email",
      render: (text: any, row: any, index: number) => (
        <Typography.Text>{text ? text : "--"}</Typography.Text>
      ),
    },
    {
      title: "Landline No.",
      dataIndex: "landline",
      key: "landline",
      render: (text: any, row: any, index: number) => (
        <Typography.Text>{text ? text : "--"}</Typography.Text>
      ),
    },
  ];



  return (
    <>
      <Row
        style={{
          marginTop: 20,
        }}
      >
        <Col lg={24}>
          <Table
            rowKey="id"
            columns={floorsheetColumns}
            dataSource={tradingAccList}
            loading={tradingAccListLoading}
            pagination={{
              current: page,
              total: parseInt(totalTradingAccListRecords),
              pageSize: filterData.limit,
              onChange: (page: number) => {
                setPage(page)
                setFilterData((prevState) => ({
                  ...prevState,
                  offset : (page - 1) * filterData.limit
                }));
              },
              showSizeChanger: true,
              onShowSizeChange :  (current, size) => {
                setFilterData((prev) => ({
                  ...prev,
                  limit: size
                }))
                
              },
            }}
          />
        </Col>
      </Row>
    </>
  );
};
