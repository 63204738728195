import { message } from 'antd';
import axios, { AxiosResponse } from 'axios';
import {
  all,
  call,
  fork,
  put,
  StrictEffect,
  takeLatest,
} from 'redux-saga/effects';

import {
  createGroupFailure,
  createGroupSuccess,
  deleteGroupFailure,
  deleteGroupSuccess,
  fetchGroupDetailFailure,
  fetchGroupDetailListFailure,
  fetchGroupDetailListSuccess,
  fetchGroupDetailSuccess,
  fetchGroupListFailure,
  fetchGroupListSuccess,
  updateGroupFailure,
  updateGroupSuccess,
} from './groupAction';
import {
  createGroupApi,
  deleteGroupApi,
  fetchGroupDetailApi,
  fetchGroupDetailListApi,
  fetchGroupListApi,
  updateGroupApi,
} from './groupApi';
import {
  CREATE_GROUP,
  CreateGroupProps,
  DELETE_GROUP,
  FETCH_GROUP_DETAIL,
  FETCH_GROUP_DETAIL_LIST,
  FETCH_GROUP_LIST,
  UPDATE_GROUP,
} from './groupTypes';

const fetchGroupListAsync = async (data:any) => await fetchGroupListApi(data);

function* fetchGroupList(params: any) {
    const { payload } = params;

    try {
        const response: AxiosResponse = yield call(
            fetchGroupListAsync,
            payload

        );
        if (response) {
            yield put(fetchGroupListSuccess(response.data.data));
        }

    } catch (error) {
        if (axios.isAxiosError(error)) {
            const messages = error?.response?.data?.error ?? "Something went wrong";
            yield put(fetchGroupListFailure());
            message.error(messages);
        } else {
            yield put(fetchGroupListFailure());

        }
    }
}

function* watchFetchGroupList() {
    yield takeLatest(FETCH_GROUP_LIST, fetchGroupList);
}


const fetchGroupDetailListAsync = async (id: string) => await fetchGroupDetailListApi(id);

function* fetchGroupDetailList(params: any) {
    const { payload } = params;

    try {
        const response: AxiosResponse = yield call(
            fetchGroupDetailListAsync,
            payload

        );
        if (response) {
            yield put(fetchGroupDetailListSuccess(response.data.data));
        }

    } catch (error) {
        if (axios.isAxiosError(error)) {
            const messages = error?.response?.data?.error ?? "Something went wrong";
            yield put(fetchGroupDetailListFailure());
            message.error(messages);
        } else {
            yield put(fetchGroupDetailListFailure());

        }
    }
}

function* watchFetchGroupDetailList() {
    yield takeLatest(FETCH_GROUP_DETAIL_LIST, fetchGroupDetailList);
}

const fetchGroupDetailAsync = async (id: string) => await fetchGroupDetailApi(id);

function* fetchGroupDetail(params: any) {
    const { payload } = params;

    try {
        const response: AxiosResponse = yield call(
            fetchGroupDetailAsync,
            payload

        );
        if (response) {
            yield put(fetchGroupDetailSuccess(response.data.data));
        }

    } catch (error) {
        if (axios.isAxiosError(error)) {
            const messages = error?.response?.data?.error ?? "Something went wrong";
            yield put(fetchGroupDetailFailure());
            message.error(messages);
        } else {
            yield put(fetchGroupDetailFailure());

        }
    }
}

function* watchFetchGroupDetail() {
    yield takeLatest(FETCH_GROUP_DETAIL, fetchGroupDetail);
}


const createGroupAsync = async (data: CreateGroupProps) => await createGroupApi(data);

function* createGroup(params: any) {
    const { payload } = params;

    try {
        const response: AxiosResponse = yield call(
            createGroupAsync,
            payload
        );
        if (response) {
            message.success(response.data.message)
            yield put(createGroupSuccess());
        }

    } catch (error) {
        if (axios.isAxiosError(error)) {
            const messages = error?.response?.data?.error ?? "Something went wrong";
            yield put(createGroupFailure());
            message.error(messages);
        } else {
            yield put(createGroupFailure());

        }


    }
}

function* watchCreateGroup() {
    yield takeLatest(CREATE_GROUP, createGroup);
}


const updateGroupAsync = async ({ id, data }: { id: string, data: CreateGroupProps }) => await updateGroupApi({ id, data });

function* updateGroup(params: any) {
    const { payload } = params;

    try {
        const response: AxiosResponse = yield call(
            updateGroupAsync,
            payload
        );
        if (response) {
            message.success(response.data.message)
            yield put(updateGroupSuccess());
        }

    } catch (error) {
        if (axios.isAxiosError(error)) {
            const messages = error?.response?.data?.error ?? "Something went wrong";
            yield put(updateGroupFailure());
            message.error(messages);
        } else {
            yield put(updateGroupFailure());

        }


    }
}

function* watchUpdateGroup() {
    yield takeLatest(UPDATE_GROUP, updateGroup);
}



const deleteGroupAsync = async (id: string) => await deleteGroupApi(id);

function* deleteGroup(params: any) {
    const { payload } = params;

    try {
        const response: AxiosResponse = yield call(
            deleteGroupAsync,
            payload
        );
        if (response) {
            message.success(response.data.message)
            yield put(deleteGroupSuccess());
        }

    } catch (error) {
        if (axios.isAxiosError(error)) {
            const messages = error?.response?.data?.error ?? "Something went wrong";
            yield put(deleteGroupFailure());
            message.error(messages);
        } else {
            yield put(deleteGroupFailure());

        }


    }
}

function* watchDeleteGroup() {
    yield takeLatest(DELETE_GROUP, deleteGroup);
}

export default function* TradingGroupSaga(): Generator<StrictEffect> {
    yield all([fork(watchFetchGroupList)]);
    yield all([fork(watchDeleteGroup)]);
    yield all([fork(watchCreateGroup)]);
    yield all([fork(watchFetchGroupDetailList)]);
    yield all([fork(watchFetchGroupDetail)]);
    yield all([fork(watchUpdateGroup)]);
}