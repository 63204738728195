import React, { useState } from 'react';

import {
  Button,
  Col,
  Modal,
  Row,
  Typography,
} from 'antd';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  useHistory,
  useParams,
} from 'react-router';
import { deleteGroup } from 'Redux/TradingRedux/GroupManagement/groupAction';

import { ExclamationCircleOutlined } from '@ant-design/icons';

import { AddMembersModal } from './AddMembersModal';
import { EditMembers } from './EditMembers';

export const HeaderComponent = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const {
        groupDetailData,
        success
    } = useSelector((state: any) => state.tradingGroupReducer);
    const { id } = useParams<{ id: string }>()


    const [addMembersModal, setAddMembersModal] = useState(false)
    const [isEdit, setIsEdit] = useState(false)


    const showModal = () => { //show delete modal
        Modal.confirm({
            title: "Confirm",
            icon: <ExclamationCircleOutlined />,
            content: "Are you sure want to delete?",
            okText: "Confirm Delete",
            okButtonProps: { type: "primary", danger: true },
            cancelText: "Cancel",
            onOk: () => {
                dispatch(deleteGroup(id))
                history.push('/trading-profile/group-management')

            },
        });
    }

    return (
        <>
            <div className='header'>
                {
                    isEdit ? <EditMembers isEdit={isEdit} setIsEdit={setIsEdit} /> :
                        <>
                            <Row gutter={[16, 16]} justify={"space-between"} align={"middle"}>
                                <Col>
                                    <Typography.Title level={5}>{groupDetailData?.name}</Typography.Title>
                                </Col>
                                <Col>
                                    <Row gutter={[16, 16]}>
                                        <Col>
                                            <Button onClick={() => setAddMembersModal(true)}> Update Members </Button>
                                        </Col>
                                        <Col>
                                            <Button danger onClick={showModal}> Delete </Button>
                                        </Col>
                                        <Col>
                                            <Button onClick={() => setIsEdit(true)}> Edit </Button>
                                        </Col>
                                    </Row>
                                </Col>

                            </Row>
                            <Row gutter={[16, 16]}>
                                <Col>
                                    <Typography.Title level={5}> Email: {groupDetailData?.email}</Typography.Title>
                                </Col>
                                <Col>
                                    <Typography.Title level={5}> Contact: {groupDetailData?.contact}</Typography.Title>
                                </Col>
                            </Row>
                        </>
                }


            </div>
            <AddMembersModal addMembersModal={addMembersModal} setAddMembersModal={setAddMembersModal} />
        </>
    )
}
