import React, {
  Dispatch,
  SetStateAction,
  useState,
} from 'react';

import {
  Button,
  Col,
  Form,
  Input,
  Row,
} from 'antd';
import { DatePicker } from 'Components/DatePicker';
import dayjs from 'dayjs';

import { CloseCircleOutlined } from '@ant-design/icons';

import { TradingProfileListFilterProps } from '../TradingAccount';
import { ImportComponent } from './ImportComponent';

interface FilterComponentProps {
  setFilterData: Dispatch<SetStateAction<TradingProfileListFilterProps>>;
  filterData: TradingProfileListFilterProps;
  page: number,
  setPage: Dispatch<SetStateAction<number>>


}

export const FilterComponent = ({
  filterData,
  setFilterData,
  page,
  setPage
}: FilterComponentProps) => {


  const [searchVal, setSearchVal] = useState("")

  const handleClearFilter = () => {
    setPage(1)
    setFilterData((prevData) => ({
      ...prevData,
      search: "",
      offset: 0,
      date_range: null,
      custom_date_after: null,
      custom_date_before: null,
    }))
    setSearchVal("")
  }

  return (
    <div>
      <Row gutter={[20, 20]} align="middle">
        <Col lg={18}>
          <Form>
            <Row gutter={[16, 16]} align={"middle"}>
              <Col lg={8}>
                <Input.Search value={searchVal} onChange={(e) => setSearchVal(e.target.value)} placeholder='Search username or id'
                />
              </Col>
              <Col lg={6}>
                <Button htmlType='submit' type="primary" className="primary--alt" onClick={() => {
                  setPage(1)
                  setFilterData((prevData) => ({
                    ...prevData,
                    search: searchVal,
                    offset: 0
                  }))
                }
                }>
                  Search
                </Button>
              </Col>

            </Row>

          </Form>
        </Col>

        <Col lg={3}>
          <ImportComponent filterData={filterData} />
        </Col>
        <Col lg={3}>
          <Button icon={<CloseCircleOutlined />} danger onClick={handleClearFilter} >Clear Filter</Button>
        </Col>
      </Row>
      <Row gutter={[20, 20]} align="middle" style={{ marginTop: 18 }} justify={"end"}>
        <Col>

          <Button
            disabled={filterData.date_range === "last_7_days"}
            onClick={() => {
              setPage(1)
              setFilterData((prevState) => ({
                ...prevState,
                date_range: 'last_7_days'
              }))
            }}
          >
            {" "}
            Last 7 Days{" "}
          </Button>

        </Col>
        <Col>
          <Button
            disabled={filterData.date_range === "last_month"}
            onClick={() => {
              setPage(1)
              setFilterData((prevState) => ({
                ...prevState,
                date_range: 'last_month'
              }))
            }}
          >
            {" "}
            Last one month{" "}
          </Button>

        </Col>
        <Col>
          <DatePicker.RangePicker
            // style={{ width: "100%" }}
            value={[filterData.custom_date_before ? dayjs(filterData.custom_date_before) : null, filterData.custom_date_before ? dayjs(filterData.custom_date_before) : null]}
            onChange={(dates) => {
              if (dates) {
                setPage(1)
                setFilterData((prevState) => ({
                  ...prevState,
                  date_range: null,
                  custom_date_before: dates
                    ? dayjs(dates?.[0]?.toString()).format("YYYY-MM-DD")
                    : "",
                  custom_date_after: dates
                    ? dayjs(dates?.[1]?.toString()).format("YYYY-MM-DD")
                    : "",
                }));
              } else {
                setPage(1)
                setFilterData((prevState) => ({
                  ...prevState,
                  custom_date_before: null,
                  custom_date_after: null,
                }));
              }
            }}
          />
        </Col>
      </Row>
    </div>
  );
};
