import { all } from 'redux-saga/effects';
import AdminSagas from 'Redux/AdminRedux/AdminSagas';
import AuthSagas from 'Redux/AuthRedux/AuthSagas';
import BatchFileSagas from 'Redux/BatchFileRedux(downlodFiles)/BatchFileSaga';
import DashboardSaga from 'Redux/DashboardRedux/DashboardSaga';
import AccountSagas from 'Redux/DpRedux/Accounts/AccountSaga';
import DpSagas from 'Redux/DpRedux/Customer/DpSagas';
import DpDashboardReportSagas from 'Redux/DpRedux/Dashboard/DashboardSaga';
import DpReportsSagas from 'Redux/DpRedux/Reports/ReportsSaga';
import DpTransactionSagas from 'Redux/DpRedux/Transaction/TransactionSaga';
import KycSagas from 'Redux/KycRedux/KycSagas';
import TradingAlertSaga from 'Redux/TradingRedux/alert/alertSaga';
import TradingFloorsheetSaga
  from 'Redux/TradingRedux/Floorsheet/FloorsheetSaga';
import TradingFloorsheetTransactionSaga
  from 'Redux/TradingRedux/FloorsheetTransaction/FloorsheetTransactionSaga';
import TradingGroupSaga from 'Redux/TradingRedux/GroupManagement/groupSaga';
import TradingTagsSaga from 'Redux/TradingRedux/Tags/TagsSaga';
import TradingTradingAccSaga
  from 'Redux/TradingRedux/TradingAccount/TradingAccountSaga';
import TradingVisualizationSaga
  from 'Redux/TradingRedux/visualization/visualizationSaga';
import UploadKycSaga from 'Redux/UploadKycsFileRedux/UploadKycSaga';
import UserSagas from 'Redux/UserRedux/UserSagas';

export default function* rootSagas() {
  yield all([
    AuthSagas(),
    UserSagas(),
    KycSagas(),
    AdminSagas(),
    BatchFileSagas(),
    UploadKycSaga(),
    DashboardSaga(),
    DpSagas(),
    AccountSagas(),
    DpReportsSagas(),
    DpDashboardReportSagas(),
    DpTransactionSagas(),
    TradingTradingAccSaga(),
    TradingFloorsheetSaga(),
    TradingFloorsheetTransactionSaga(),
    TradingGroupSaga(),
    TradingAlertSaga(),
    TradingTagsSaga(),
    TradingVisualizationSaga()

  ]);
}
