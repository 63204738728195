export const FETCH_VISUALIZATION_DATA = "FETCH_VISUALIZATION_DATA"
export const FETCH_VISUALIZATION_DATA_SUCCESS = "FETCH_VISUALIZATION_DATA_SUCCESS"
export const FETCH_VISUALIZATION_DATA_FAILURE = "FETCH_VISUALIZATION_DATA_FAILURE"


export interface FetchVisualizationDataProps {
    date_range?: string
    custom_date?: string
    single_date?: string
    tags?:string
    amount?:string
    stock_symbol?: string
    custom_date_before?:string
    custom_date_after?:string
    group?:string
    individual?:string
    sector?:string
}