export const UPLOAD_TRADING_ACC_FILE = "UPLOAD_TRADING_ACC_FILE";
export const UPLOAD_TRADING_ACC_FILE_SUCCESS = "UPLOAD_TRADING_ACC_FILE_SUCCESS";
export const UPLOAD_TRADING_ACC_FILE_FAILURE = "UPLOAD_TRADING_ACC_FILE_FAILURE";
export const RESET_UPLOAD_TRADING_ACC_VAL = "RESET_UPLOAD_TRADING_ACC_VAL"

export const FETCH_TRADING_ACC_LIST = "FETCH_TRADING_ACC_LIST"
export const FETCH_TRADING_ACC_LIST_SUCCESS = "FETCH_TRADING_ACC_LIST_SUCCESS"
export const FETCH_TRADING_ACC_LIST_FAILURE = "FETCH_TRADING_ACC_LIST_FAILURE"

export const FETCH_TRADING_ACC_SINGLE = "FETCH_TRADING_ACC_SINGLE"
export const FETCH_TRADING_ACC_SINGLE_SUCCESS = "FETCH_TRADING_ACC_SINGLE_SUCCESS"
export const FETCH_TRADING_ACC_SINGLE_FAILURE = "FETCH_TRADING_ACC_SINGLE_FAILURE"

export const UPDATE_TRADING_ACC_SINGLE = "UPDATE_TRADING_ACC_SINGLE"
export const UPDATE_TRADING_ACC_SINGLE_SUCCESS = "UPDATE_TRADING_ACC_SINGLE_SUCCESS"
export const UPDATE_TRADING_ACC_SINGLE_FAILURE = "UPDATE_TRADING_ACC_SINGLE_FAILURE"

export const FETCH_TRADING_ACC_SINGLE_TRANSACTION = "FETCH_TRADING_ACC_SINGLE_TRANSACTION"
export const FETCH_TRADING_ACC_SINGLE_TRANSACTION_SUCCESS = "FETCH_TRADING_ACC_SINGLE_TRANSACTION_SUCCESS"
export const FETCH_TRADING_ACC_SINGLE_TRANSACTION_FAILURE = "FETCH_TRADING_ACC_SINGLE_TRANSACTION_FAILURE"



export interface UploadTradingSuccessData {
    total_created: number,
    total_updated: number

}

export interface FetchTradingAccListProps {
    limit?: number;
    offset?: number;
    username?: string
}

export interface FetchTradingSingleTransactionListProps {
    client_code?: string,
    filterData?: FetchTradingAccListProps
}

export interface UpdateTradingSingle {
    tag: string[]
}