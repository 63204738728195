import { Reducer } from 'react';

import {
  FETCH_VISUALIZATION_DATA,
  FETCH_VISUALIZATION_DATA_FAILURE,
  FETCH_VISUALIZATION_DATA_SUCCESS,
} from './visualizationType';

const INIT_STATE = {
    visualizationData: {},
    visualizationDataLoading: false

};

export const tradingVisualization: Reducer<any, any> = (state = INIT_STATE, action) => {
    switch (action.type) {

        case FETCH_VISUALIZATION_DATA:
            return {
                ...state,
                visualizationData: {},
                visualizationDataLoading: true
            }
        case FETCH_VISUALIZATION_DATA_SUCCESS:
            return {
                ...state,
                visualizationData: action.payload,
                visualizationDataLoading: false
            }
        case FETCH_VISUALIZATION_DATA_FAILURE:
            return {
                ...state,
                visualizationData: {},
                visualizationDataLoading: true
            }

        default:
            return { ...state };

    }
}