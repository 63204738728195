import React, {
  useEffect,
  useState,
} from 'react';

import {
  Button,
  Card,
  Col,
  Dropdown,
  Menu,
  Row,
  Space,
  Spin,
  Typography,
  Upload,
} from 'antd';
import { DatePicker } from 'Components/DatePicker';
import Modal from 'Components/Modal';
import dayjs from 'dayjs';
import ArrowDownBoldIcon from 'Icons/ArrowDownBoldIcon/ArrowDownBoldIcon';
import { DownloadIconDark } from 'Icons/DownloadIcon/DownloadIcon';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  fetchFloorsheetList,
  resetUploadFloorsheetFileStateValue,
  uploadFloorsheetFile,
} from 'Redux/TradingRedux/Floorsheet/FloorsheetAction';
import { disableFutureDates } from 'Utils/dateValidations';
import { dummyRequest } from 'Utils/DummyCall';

import { FloorsheetListFilterProps } from '../Floorsheet';

const importType = [
  {
    key: "floorsheet",
    type: "Import Floorsheet",
  },
];

interface ImportComponentProps {
  filterData: FloorsheetListFilterProps;
}

export const ImportComponent = ({ filterData }: ImportComponentProps) => {
  const dispatch = useDispatch();

  const [showImportModal, setShowImportModal] = useState({
    visible: false,
    key: "",
  });

  const [date, setDate] = useState("")
  const [dateError, setDateError] = useState("")


  const {
    uploadFileLoading,
    uploadFileError,
    uploadFileSuccess,
    uploadFileSuccessData
  } = useSelector((state: any) => state.tradingFloorsheetReducer)


  useEffect(() => {
    if (uploadFileSuccess) {

      dispatch(fetchFloorsheetList(filterData))
    }

  }, [uploadFileSuccess, dispatch, filterData])

  const menuImport = (
    <Menu
      onClick={(e) => {
        setShowImportModal({
          visible: true,
          key: e.key,
        });
      }}
    >
      {importType.map((item: { key: string; type: string }) => {
        return (
          <Menu.Item key={item.key}>
            <Typography.Text type="secondary">{item.type}</Typography.Text>
          </Menu.Item>
        );
      })}
    </Menu>
  );

  const renderModalComp = () => {
    switch (true) {
      case uploadFileError:
        return (
          <>
            <Typography.Title type="danger" level={4}>
              Import Failed!
            </Typography.Title>
            <Row>
              <Button
                style={{
                  marginRight: 20,
                }}
                onClick={() => {
                  dispatch(resetUploadFloorsheetFileStateValue());
                  setShowImportModal({
                    visible: false,
                    key: "",
                  });
                }}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                onClick={() => dispatch(resetUploadFloorsheetFileStateValue())}
              >
                Reupload File
              </Button>
            </Row>
          </>
        );

      case uploadFileSuccess:
        return (
          <>
            <Typography.Title type="success" level={4}>
              successfully imported
            </Typography.Title>
            <Typography.Text>
              {" "}
              Total Transactions- {uploadFileSuccessData.total_transaction}{" "}
            </Typography.Text>
          </>
        );

      default:
        return (
          <Spin spinning={uploadFileLoading}>
            <Row gutter={[16, 44]} justify={"center"}>
              <Col md={24}>
                <label>Select Date:</label>
                <DatePicker style={{ width: "100%" }} onChange={(date) => setDate(dayjs(date?.toString()).format("YYYY-MM-DD"))} disabledDate={(date) => disableFutureDates(date) } />
                {dateError &&

                  <Typography.Text type='danger' > {dateError}</Typography.Text>
                }
              </Col>
              <Col md={24}>
                <Card>
                  <Upload
                    customRequest={dummyRequest}
                    beforeUpload={(file: any) => {
                      if (!date) {
                        setDateError("Date is required")
                        return
                      }
                      const formData = new FormData();

                      formData.append("file", file);
                      formData.append("date", date);
                      dispatch(uploadFloorsheetFile({
                        formData
                      }));
                    }}
                    accept=".xlsx, application/vnd.ms-excel"
                    className="d-flex justify-center flex-direction-col align-items-center "
                  >
                    <Space direction="vertical" align="center" size={"large"}>
                      <DownloadIconDark />
                      <Typography.Text>Upload .xls or .xlsx file</Typography.Text>
                    </Space>
                  </Upload>
                </Card>
              </Col>

            </Row>
          </Spin>
        );
    }
  };

  return (
    <>
      <Dropdown trigger={["click"]} overlay={menuImport}>
        <Row align="middle" justify="space-between">
          <Typography.Text style={{ textTransform: "capitalize" }}>
            {"Import"}
          </Typography.Text>
          <ArrowDownBoldIcon />
        </Row>
      </Dropdown>
      <Modal
        visible={showImportModal.visible}
        width={"30vw"}
        onCancel={() => {
          dispatch(resetUploadFloorsheetFileStateValue());
          setShowImportModal({
            visible: false,
            key: "",
          });
        }}
      >
        <Space
          direction="vertical"
          align="center"
          size={"large"}
          style={{
            width: "100%",
          }}
        >
          {renderModalComp()}
        </Space>
      </Modal>
    </>
  );
};
