import { Reducer } from 'react';

import {
  CREATE_GROUP,
  CREATE_GROUP_FAILURE,
  CREATE_GROUP_SUCCESS,
  DELETE_GROUP,
  DELETE_GROUP_FAILURE,
  DELETE_GROUP_SUCCESS,
  FETCH_GROUP__DETAIL_LIST_FAILURE,
  FETCH_GROUP_DETAIL,
  FETCH_GROUP_DETAIL_FAILURE,
  FETCH_GROUP_DETAIL_LIST,
  FETCH_GROUP_DETAIL_LIST_SUCCESS,
  FETCH_GROUP_DETAIL_SUCCESS,
  FETCH_GROUP_LIST,
  FETCH_GROUP_LIST_FAILURE,
  FETCH_GROUP_LIST_SUCCESS,
  UPDATE_GROUP,
  UPDATE_GROUP_FAILURE,
  UPDATE_GROUP_SUCCESS,
} from './groupTypes';

const INIT_STATE = {
    groupListLoading: false,
    groupListData: [],
    groupListCount: 0,
    loading: false,
    groupDetailListLoading: false,
    groupDetailListData: [],
    groupDetailLoading: false,
    groupDetailData: {},
    success: false,

};

export const tradingGroup: Reducer<any, any> = (state = INIT_STATE, action) => {
    switch (action.type) {
        case FETCH_GROUP_LIST:
            return {
                ...state,
                groupLitLoading: true,
                groupListData: [],
                success: false,
                groupListCount: 0

            }
        case FETCH_GROUP_LIST_SUCCESS:
            return {
                ...state,
                groupLitLoading: false,
                groupListData: action.payload.results,
                groupListCount: action.payload.count
            }
        case FETCH_GROUP_LIST_FAILURE:
            return {
                ...state,
                groupLitLoading: false,
                groupListData: [],
                groupListCount: 0
            }

        case FETCH_GROUP_DETAIL_LIST:
            return {
                ...state,
                groupDetailListLoading: true,
                groupDetailListData: [],
                success: false
            }
        case FETCH_GROUP_DETAIL_LIST_SUCCESS:
            return {
                ...state,
                groupDetailListLoading: false,
                groupDetailListData: action.payload.results,
            }
        case FETCH_GROUP__DETAIL_LIST_FAILURE:
            return {
                ...state,
                groupDetailListLoading: false,
                groupDetailListData: [],
            }

        case FETCH_GROUP_DETAIL:
            return {
                ...state,
                groupDetailLoading: true,
                groupDetailData: {},
            }
        case FETCH_GROUP_DETAIL_SUCCESS:
            return {
                ...state,
                groupDetailLoading: false,
                groupDetailData: action.payload,
            }
        case FETCH_GROUP_DETAIL_FAILURE:

            return {
                ...state,
                groupDetailLoading: false,
                groupDetailData: {},
            }

        case CREATE_GROUP:
        case UPDATE_GROUP:
        case DELETE_GROUP:
            return {
                ...state,
                loading: true,
                success: false

            }
        case CREATE_GROUP_SUCCESS:
        case UPDATE_GROUP_SUCCESS:
        case DELETE_GROUP_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true
            }
        case CREATE_GROUP_FAILURE:
        case UPDATE_GROUP_FAILURE:
        case DELETE_GROUP_FAILURE:
            return {
                ...state,
                loading: false,
                success: false

            }
        default:
            return { ...state };

    }
}