export const UPLOAD_FLOORSHEET_FILE = "UPLOAD_FLOORSHEET_FILE";
export const UPLOAD_FLOORSHEET_FILE_SUCCESS = "UPLOAD_FLOORSHEET_FILE_SUCCESS";
export const UPLOAD_FLOORSHEET_FILE_FAILURE = "UPLOAD_FLOORSHEET_FILE_FAILURE";
export const RESET_UPLOAD_FLOORSHEET_VAL = "RESET_UPLOAD_FLOORSHEET_VAL"

export const FETCH_FLOORSHEET_LIST = "FETCH_FLOORSHEET_LIST"
export const FETCH_FLOORSHEET_LIST_SUCCESS = "FETCH_FLOORSHEET_LIST_SUCCESS"
export const FETCH_FLOORSHEET_LIST_FAILURE = "FETCH_FLOORSHEET_LIST_FAILURE"

export const DELETE_FLOORSHEET = "DELETE_FLOORSHEET"
export const DELETE_FLOORSHEET_SUCCESS = "DELETE_FLOORSHEET_SUCCESS"
export const DELETE_FLOORSHEET_FAILURE = "DELETE_FLOORSHEET_FAILURE"

export interface FetchFloorsheetListProps {
    limit?: number;
    offset?: number;
    username?: string

}