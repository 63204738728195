import React, {
  Dispatch,
  SetStateAction,
} from 'react';

import {
  Col,
  Row,
  Table,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';

import { FloorsheetTransactionListFilterProps } from '../FloorsheetTransaction';

interface FilterComponentProps {
  setFilterData: Dispatch<SetStateAction<FloorsheetTransactionListFilterProps>>;
  filterData: FloorsheetTransactionListFilterProps;
  page: number,
  setPage: Dispatch<SetStateAction<number>>
}

export const TableComponent = ({
  filterData,
  setFilterData,
  page,
  setPage
}: FilterComponentProps) => {

  const {
    floorsheetTransactionListLoading,
    floorsheetTransactionList,
    totalFloorsheetTransactionRecords
  } = useSelector((state: any) => state.tradingFloorsheetTransactionReducer)



  const floorsheetColumns = [
    {
      title: "S.N",
      dataIndex: "id",
      key: "id",
      render: (row: any, text: any, index: number) => {
        if (page && filterData.limit) {
          return (
            <Typography.Text>
              {(page - 1) * filterData.limit + index + 1}
            </Typography.Text>
          );
        }
      },
    },
    {
      title: "Contract No",
      dataIndex: "contract_id",
      key: "contract_id",
      render: (text: any, row: any, index: number) => (
        <Typography.Text>{text}</Typography.Text>
      ),
    },
    {
      title: "Client Code",
      dataIndex: "client_code",
      key: "client_code",
      render: (text: any, row: any, index: number) => (
        <Typography.Text>{text}</Typography.Text>
      ),
    },
    {
      title: "Trade Date",
      dataIndex: "floorsheet_date",
      key: "floorsheet_date",
      render: (text: any, row: any, index: number) => (
        <Typography.Text>{dayjs(text).format("YYYY-MM-DD")}</Typography.Text>
      ),
    },
    {
      title: "Symbol",
      dataIndex: "stock_symbol",
      key: "stock_symbol",
      render: (text: any, row: any, index: number) => (
        <Typography.Text>{text}</Typography.Text>
      ),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (text: any, row: any, index: number) => (
        <Typography.Text>{text}</Typography.Text>
      ),
    },
    {
      title: "Rate",
      dataIndex: "rate",
      key: "rate",
      render: (text: any, row: any, index: number) => (
        <Typography.Text>{text}</Typography.Text>
      ),
    },
    {
      title: "Total Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text: any, row: any, index: number) => (
        <Typography.Text>{text}</Typography.Text>
      ),
    },

  ];


  return (
    <>
      <Row
        style={{
          marginTop: 20,
        }}
      >
        <Col lg={24}>
          <Table
            rowKey="id"
            columns={floorsheetColumns}
            dataSource={floorsheetTransactionList}
            loading={floorsheetTransactionListLoading}
            pagination={{
              current: Number(page),
              pageSize: filterData.limit,
              total: parseInt(totalFloorsheetTransactionRecords),
              onChange: (page: number) => {
                setPage(page)
                setFilterData((prevState) => ({
                  ...prevState,
                  offset: (page - 1) * filterData.limit
                }));
              },
              showSizeChanger: true,
              onShowSizeChange: (current, size) => {
                setFilterData((prev) => ({
                  ...prev,
                  limit: size
                }))
              },
            }}
          />
        </Col>
      </Row>
    </>
  );
};
