import { Auth } from 'aws-amplify';
import axios from 'axios';
import config from 'Config/config';

import { FetchVisualizationDataProps } from './visualizationType';

axios.defaults.baseURL = `${config.dpApi}`;

const getToken = async () => {
    return (await Auth.currentSession()).getIdToken().getJwtToken();
};


export const fetchVisualizationDataApi = async (data: FetchVisualizationDataProps) => {
    return axios.get(`/trading/filterset`, {
        params: data,
        headers: {
            Authorization: `Bearer ${await getToken()}`,
        },
    });
}

