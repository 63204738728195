import React, {
  useEffect,
  useState,
} from 'react';

import {
  Card,
  Typography,
} from 'antd';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import {
  fetchFloorsheetTransactionList,
} from 'Redux/TradingRedux/FloorsheetTransaction/FloorsheetTransactionAction';

import { FilterComponent } from './Components/FilterComponent';
import { TableComponent } from './Components/TableComponent';

export interface FloorsheetTransactionListFilterProps {
  limit: number;
  date_range: string | null
  offset: number

}

export const FloorsheetTransaction = () => {
  const { id } = useParams<{ id: string }>()
  const dispatch = useDispatch()
  const [filterData, setFilterData] = useState<FloorsheetTransactionListFilterProps>({
    limit: 10,
    // page: 1,
    date_range: null,
    offset: 0

  });
  const [page, setPage] = useState<number>(1)

  useEffect(() => {
    if (id) {
      dispatch(fetchFloorsheetTransactionList({
        filterData,
        id
      }))
    } else {
      dispatch(fetchFloorsheetTransactionList({
        filterData,
      }))
    }
  }, [id, dispatch, filterData])

  return (
    <Card>
      <Typography.Title level={3}>
        Transactions
      </Typography.Title>
      <FilterComponent filterData={filterData} setFilterData={setFilterData} page={page} setPage={setPage} />
      <TableComponent filterData={filterData} setFilterData={setFilterData} page={page} setPage={setPage} />
    </Card>
  )
}
