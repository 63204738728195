import { Auth } from 'aws-amplify';
import axios from 'axios';
import config from 'Config/config';

import {
  AlertCreateProps,
  FetchAlertListProps,
} from './alertTypes';

axios.defaults.baseURL = `${config.dpApi}`;

const getToken = async () => {
    return (await Auth.currentSession()).getIdToken().getJwtToken();
};


export const fetchNepseListApi = async () => {
    return axios.get(`${config.nepseApi}/ticker-list`, {
        // params: data,
        headers: {
            Authorization: `Bearer ${await getToken()}`,
        },
    });
}

export const fetchNepseTickerDataApi = async (symbol: string) => {
    return axios.get(`${config.nepseApi}/ticker-live-data/${symbol}`, {
        // params: data,
        headers: {
            Authorization: `Bearer ${await getToken()}`,
        },
    });
}

export const fetchAlertListApi = async (data: FetchAlertListProps) => {
    return axios.get(`alerts/list`, {
        params: data,
        headers: {
            Authorization: `Bearer ${await getToken()}`,
        },
    });
}

export const fetchAlertDetailApi = async (id: string) => {
    return axios.get(`alerts/details/${id}`, {
        headers: {
            Authorization: `Bearer ${await getToken()}`,
        },
    });
}

export const deleteAlertApi = async (id: string) => {
    return axios.delete(`alerts/delete/${id}`, {
        headers: {
            Authorization: `Bearer ${await getToken()}`,
        },
    });
}

export const createAlertApi = async (data: AlertCreateProps) => {
    return axios.post(`alerts/create`, data, {
        headers: {
            Authorization: `Bearer ${await getToken()}`,
        },
    });
}

export const updateAlertApi = async ({ id, data }: { id: string, data: AlertCreateProps }) => {
    return axios.patch(`alerts/update/${id}`, data, {
        headers: {
            Authorization: `Bearer ${await getToken()}`,
        },
    });
}