import {
  FETCH_FLOORSHEET_TRANSACTION_LIST,
  FETCH_FLOORSHEET_TRANSACTION_LIST_FAILURE,
  FETCH_FLOORSHEET_TRANSACTION_LIST_SUCCESS,
} from './FloorsheetTransactionTypes';

export const fetchFloorsheetTransactionList = (data: any) => ({
    type: FETCH_FLOORSHEET_TRANSACTION_LIST,
    payload: data
})

export const fetchFloorsheetTransactionListSuccess = (data: any) => ({
    type: FETCH_FLOORSHEET_TRANSACTION_LIST_SUCCESS,
    payload: data
})

export const fetchFloorsheetTransactionListFailure = () => ({
    type: FETCH_FLOORSHEET_TRANSACTION_LIST_FAILURE
})